import { AptCommodityType, FilterCommodityType } from './../../../common/enums/apttus/apt-commodity-typeof-sale';
import { BoFilterByDate } from './bo-filter-by-date';
import { BoSearchByField } from './bo-search-by-field';
import { AptQuoteStatus } from '../../../common/enums/apttus/apt-quote-status';
import { AptSalesInadmissibilityProcess, AptSalesProcess } from '../../../common/enums/apttus/apt-sales-process';
import { BoCommonFilters } from './bo-common-filters';
import { AptOrderStatus } from '../../../common/enums/apttus/apt-order-status';

export class MonitoraggioConditions extends BoCommonFilters {
    status?: AptQuoteStatus[];
    statusOrder?: AptOrderStatus[];
    date?: BoFilterByDate;
    orderBy?: string;
    searchByField?: BoSearchByField;
    commodityFilter?: AptCommodityType;
    constructor() {
        super();
        this.date = new BoFilterByDate();
    }
}

export interface MonitoraggioFilters {
    conds: MonitoraggioConditions;
    operativeMode: AptSalesProcess;
    macroOperativeMode?: string;
    inamissibilityMode?: AptSalesInadmissibilityProcess;
    commodityType?: FilterCommodityType;
}
