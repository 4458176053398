/* tslint:disable:variable-name */
import { AField, AObject, ATable } from '@congacommerce/core';
import { AptAddressType } from '../../../../enums/apttus/apt-address-type';
import { AptProductFamily } from '../../../../enums/apttus/apt-product-family';
import { AptProductStatus } from '../../../../enums/apttus/apt-product-status';
import { AptProductType } from '../../../../enums/apttus/apt-product-type';
import { AptCommodityType } from '../../../../enums/apttus/apt-commodity-typeof-sale';
import { AptVatCode } from '../../../../enums/apttus/apt-vat-code';

@ATable({
    sobjectName: 'Product2',
    aqlName: null,
})
export class EglProductLight extends AObject {
    constructor() {
        super();
        this.ExpirationDate = null;
        this.Name = null;
        this.Family = null;
        this.ProductType = null;
        this.ProductCode = null;
        this.egl_shortdescription = null;
        this.egl_subtitle = null;
        this.egl_sellingpoint1_desc = null;
        this.egl_sellingpoint2_desc = null;
        this.egl_sellingpoint3_desc = null;
        this.egl_sellingpoint4_desc = null;
        this.egl_advantage1_title = null;
        this.egl_advantage1_desc = null;
        this.egl_advantage2_title = null;
        this.egl_advantage2_desc = null;
        this.egl_address_type = null;
    }

    @AField({ soql: 'Name' })
    Name: string;

    @AField({ soql: 'Family' })
    Family: AptProductFamily;

    @AField({ soql: 'APTSENI_Commodity' })
    APTSENI_Commodity: AptCommodityType;

    @AField({ soql: 'ExpirationDate' })
    ExpirationDate: Date;

    @AField({ soql: 'ProductType' })
    ProductType: AptProductType;

    @AField({ soql: 'ProductCode' })
    ProductCode: string;

    @AField({ soql: 'egl_unique_product_code' })
    egl_unique_product_code: string;

    @AField({ soql: 'egl_shortdescription' })
    egl_shortdescription: string;

    @AField({ soql: 'egl_subtitle' })
    egl_subtitle: string;

    @AField({ soql: 'egl_sellingpoint1_desc' })
    egl_sellingpoint1_desc: string;

    @AField({ soql: 'egl_sellingpoint2_desc' })
    egl_sellingpoint2_desc: string;

    @AField({ soql: 'egl_sellingpoint3_desc' })
    egl_sellingpoint3_desc: string;

    @AField({ soql: 'egl_sellingpoint4_desc' })
    egl_sellingpoint4_desc: string;

    @AField({ soql: 'egl_advantage1_title' })
    egl_advantage1_title: string;

    @AField({ soql: 'egl_advantage1_desc' })
    egl_advantage1_desc: string;

    @AField({ soql: 'egl_advantage2_title' })
    egl_advantage2_title: string;

    @AField({ soql: 'egl_advantage2_desc' })
    egl_advantage2_desc: string;

    @AField({ soql: 'egl_address_type' })
    egl_address_type: AptAddressType;

    @AField({ soql: 'egl_privacy_required' })
    egl_privacy_required: boolean;

    @AField({ soql: 'egl_is_expired' })
    egl_is_expired: boolean;

    @AField({ soql: 'Status' })
    Status: AptProductStatus;

    @AField({ soql: 'egl_brick_type' })
    egl_brick_type:
        | 'Extra Prodotto'
        | 'Spese Amministrative'
        | 'Base Lista'
        | 'Highlander'
        | 'Sconto Special'
        | 'Convenzione';

    @AField({ soql: 'egl_brick_subtype' })
    egl_brick_subtype: EglProductBrickSubTypeAggravi | EglProductBrickSubTypeSconti;

    @AField({ soql: 'egl_Family_OPS' })
    egl_Family_OPS: string;

    @AField({ soql: 'egl_is_power_for_outbuilding' })
    egl_is_power_for_outbuilding: boolean;

    @AField({ soql: 'egl_codice_iva' })
    egl_codice_iva: AptVatCode;

    @AField({ soql: 'egl_product_core' })
    egl_product_core: string;

    @AField({ soql: 'egl_billingitem_code' })
    egl_billingitem_code: string;
}

export type EglProductBrickSubTypeSconti =
    | 'Sconto % su corrispettivi'
    | 'Sconto % su commercializzazione e vendita FISSA'
    | 'Sconto % su commercializzazione e vendita VARIABILE'
    | 'Sconto €/consumo'
    | 'Sconto una tantum € (Altre Partite)'
    | 'Sconto €/mese (Materia Energia)'
    | 'Sconto €/mese (Altre partite)'
    | 'Sconto euro/bolletta (Altre Partite)'
    | 'Sconto % su corrispettivi con sconto su perdite'
    | 'Sconto % su Sbilanciamento con perdite'
    | 'Sconto €/consumo con sconto su perdite'
    | 'Sconto una tantum € (Materia Energia)'
    | 'Sconto €/consumo con PCS';

export type EglProductBrickSubTypeAggravi =
    | 'Aggravio euro/bolletta (Altre Partite)'
    | 'Aggravio euro/mese (Materia Energia)'
    | 'Aggravio euro/mese (Altre Partite)'
    | 'Aggravio euro/consumo'
    | 'Aggravio euro/una tantum (Materia Energia)'
    | 'Aggravio euro/consumo con perdite'
    | 'Aggravio euro/una tantum (Altre Partite)'
    | 'Aggravio euro/consumo con PCS';

export enum EglProductBrickType {
    ExtraProdotto = 'Extra Prodotto',
    SpeseAmministrative = 'Spese Amministrative',
    BaseLista = 'Base Lista',
    Highlander = 'Highlander',
    ScontoSpecial = 'Sconto Special',
    Convenzione = 'Convenzione',
}
