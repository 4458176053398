/* tslint:disable:no-console */
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectFlowType, selectPlicoCode } from '../../../store/selectors/order-entry.selectors';
import { Dictionary } from '../../interfaces/dictionary';
import {
    ApplicationInsights,
    ICustomProperties,
    ITraceTelemetry,
    SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { selectApplicationLocation } from '../../../store/selectors/app.selectors';
import { take } from 'rxjs/operators';
import { EglState } from '../../../store/reducers';
import { combineLatest } from 'rxjs';
import { selectAgentInfo, selectCurrentVirtualAgent } from '../../../store/selectors/user.selectors';
import { PrivateConfigurationService } from './private-configuration.service';
import { CartService } from '@congacommerce/ecommerce';
import { newGuid } from '../../functions/misc.functions';
import { isEmpty } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class AppInsightsService {
    private appInsights: ApplicationInsights;
    private disabled = false;
    private defaultProperties = {};

    constructor(private injector: Injector, private configSrv: PrivateConfigurationService) {
        this.configSrv.privateConfigLoaded.pipe(take(1)).subscribe(() => this.init());
    }

    init(): void {
        const conf = this.configSrv.config;
        this.disabled = conf.disabledAppInsight;
        if (!this.disabled) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: conf.appInsights.instrumentationKey,
                    enableAutoRouteTracking: true, // option to log all route changes,
                    enableRequestHeaderTracking: true,
                    enableResponseHeaderTracking: true,
                    autoTrackPageVisitTime: false, // tracking tempo di  permanenza nella pagina
                },
            });
            this.appInsights.loadAppInsights();
            // this.logMetric('app-configuration', loading, { platform });
            // this.logEvent('init session', { domainName });
            this.mapStaticProperties();
        }
    }

    private setNewUserContext(authenticatedUserId: string, accountId: string): void {
        console.log('[APPINS] authenticated user context:', authenticatedUserId, accountId);
        this.appInsights.clearAuthenticatedUserContext();
        this.appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, true);
    }

    logPageView(name: string, url: string, duration?: number): void {
        if (this.skipLog) return;
        // option to call manually
        this.appInsights.trackPageView({
            name,
            uri: url,
        });
    }

    logEvent(name: string, properties?: ICustomProperties): void {
        if (this.skipLog) return;

        this.appInsights.trackEvent({ name }, this.getCustomProperties(properties));
    }

    customLogEvent(evtName: string, evtKey: string, evtValue: string | Dictionary<any>): void {
        if (this.skipLog) return;

        this.appInsights.trackEvent(
            { name: evtName },
            {
                ...this.getCustomProperties({
                    [evtKey]: evtValue,
                }),
            }
        );
    }

    logMetric(name: string, average: number, properties?: ICustomProperties): void {
        const consoleMsg = (name || '').startsWith('Telemetry: ') ? name : `Telemetry: ${name}`;
        console.info(`[EGL] ${consoleMsg}: ${average} sec.`);

        if (this.skipLog) return;

        this.appInsights.trackMetric(
            { name, average: average ? parseFloat(average.toFixed(2)) : 0 },
            this.getCustomProperties(properties)
        );
    }

    logTrace(trace: ITraceTelemetry, properties?: any[]): void {
        if (this.skipLog) return;

        this.appInsights.trackTrace(trace, this.getCustomProperties(properties));
    }

    logException(exception: Error, severityLevel?: SeverityLevel): void {
        if (this.skipLog) return;

        this.appInsights.trackException({ exception, severityLevel });
    }

    /**
     * @description: Unisce l'oggetto staticProperties e properties se presente, se quest'ultimo manca ritorna solo staticProperties.
     * @return: ritorna l'oggetto properties unito (staticProperties e properties da argomento) o solamente l'oggetto staticProperties
     */
    private getCustomProperties(properties?: any[] | ICustomProperties): ICustomProperties {
        if (isEmpty(properties)) {
            return this.defaultProperties;
        } else {
            return {
                ...this.defaultProperties,
                logParams: Array.isArray(properties)
                    ? Object.keys(properties).length === 1
                        ? properties[0]
                        : { ...properties }
                    : properties,
            };
        }
    }

    /**
     * @description: inserisce informazioni utili ai log
     * @return: void
     */
    private mapStaticProperties(): void {
        combineLatest([
            this.store.select(selectApplicationLocation),
            this.store.select(selectAgentInfo),
            this.store.select(selectCurrentVirtualAgent),
            this.store.select(selectPlicoCode),
            this.store.select(selectFlowType),
            this.cartSrv.getMyCart(),
        ]).subscribe(([appLocation, agentInfo, virtualAgent, contractCode, flowType, cart]) => {
            const currentCart = cart?.Id || localStorage.getItem('local-cart');
            const loggedUser = agentInfo?.DomainName;
            this.defaultProperties = {
                appName: 'SALESUP',
                appLocation,
                location: location.href,
                flowType,
                virtualAgentCode: virtualAgent?.AgentCode || '',
                virtualAgentCoCode: virtualAgent?.CurrentCode || '',
                virtualAgency: virtualAgent?.VirtualAgency?.Code || '',
                loggedUser: loggedUser || '',
                contractCode: contractCode?.code || '',
                cartId: currentCart || '',
            };

            const storedUserContextId = this.storedUserContext?.loggedUser === loggedUser;
            if (!storedUserContextId && loggedUser) {
                const userContextId = `${newGuid()}`;
                this.setNewUserContext(userContextId, loggedUser);
                this.storedUserContext = {
                    userContextId: userContextId,
                    createdDate: new Date(),
                    loggedUser,
                };
            }
        });
    }

    private get skipLog(): boolean {
        return !this.appInsights || this.disabled;
    }

    private get storedUserContext(): TransactionIdStorage {
        return JSON.parse(localStorage.getItem(USER_CONTETX)) as TransactionIdStorage;
    }

    private set storedUserContext(value: TransactionIdStorage) {
        if (value) {
            localStorage.setItem(USER_CONTETX, JSON.stringify(value));
        } else {
            localStorage.removeItem(USER_CONTETX);
        }
    }

    //#region INJECTOR
    private get store(): Store<EglState> {
        return this.injector.get(Store);
    }
    private get cartSrv(): CartService {
        return this.injector.get(CartService);
    }
    //#endregion
}

const USER_CONTETX = 'appinsight.user.context';
interface TransactionIdStorage {
    userContextId: string;
    createdDate: Date;
    loggedUser: string;
}

// interface IStaticProperties {
//     appName: string;
//     appLocation: ApplicationLocationType;
//     virtualAgentCode: string;
//     virtualAgentCoCode: string;
//     virtualAgency: string;
//     loggedUser: string;
//     contractCode: string;
//     cartId: string;
//     flowType: FlowType;
// }
