import { D365Service } from './common/services/d365/d365.service';
import { LoggerService } from './common/services/shared/logger.service';
import { LoadingService } from './common/services/shared/loading.service';
import { combineLatest } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { RoutesPaths } from './common/config/routes-paths';
import { Injector } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PrivateConfigurationService } from './common/services/shared/private-configuration.service';
import { activationCartFromUrl, isDbarMode } from './common/functions/misc.functions';
import { TelemetryMetric, TelemetryMetricService } from './common/services/app/telemetry-metric.service';
import { LoginRedirectService } from './common/services/app/login-redirect.service';
import { UtilityService } from './common/services/shared/utility.service';

export function AppInitializer(
    d365Srv: D365Service,
    logger: LoggerService,
    injector: Injector,
    privateConfigSrv: PrivateConfigurationService,
    telemetrySrv: TelemetryMetricService,
    utilitySrv: UtilityService
) {
    return async (): Promise<boolean | void> => {
        try {
            localStorage.removeItem('account');
            const globalTelemetry = telemetrySrv.getIstance('first navigation end');
            const router = injector.get(Router);

            // Injecto il LoginRedirectService solo per inizializzarlo in modo che possa gestire il redirect verso l'applicazione Dynamics di partenza
            const loginRedirectService = injector.get(LoginRedirectService);

            const cartId = activationCartFromUrl();
            if (cartId) {
                localStorage.setItem('local-cart', cartId);
            }
            logger.info('AppInitializer URL', location.href);
            if (parent.window !== window) {
                console.log('location is', location);
                if (location.search?.indexOf('code=') !== -1) {
                    const regex = new RegExp(/code=.[^&]+/gm);
                    const authCode = location.search.match(regex)[0].split('=')[1];
                    localStorage.setItem('apim_authCode', authCode);
                }
                const configTelemetry = telemetrySrv.getIstance('load d365 configurations');
                if (localStorage.getItem('access_token') && location.hash.indexOf('access_token') !== -1) {
                    location.hash = '';
                }
                initializerLoader(router, globalTelemetry);
                return privateConfigSrv.privateConfigLoaded
                    .pipe(
                        take(1),
                        tap(() => logger.info('AppInitializer: OK loadConfigCompleted')),
                        mergeMap(() =>
                            combineLatest([
                                d365Srv
                                    .requestApplicationLocationAsync()
                                    .pipe(tap(() => logger.info('AppInitializer: OK requestApplicationLocationAsync'))),
                                d365Srv
                                    .getAgentInfoAsync()
                                    .pipe(tap(() => logger.info('AppInitializer: OK getAgentInfoAsync'))),
                            ])
                        ),
                        filter((res) => !!res[0] && !!res[1]),
                        map(([appLocation, agentInfo]) => ({
                            appLocation,
                            agentInfo,
                            config: privateConfigSrv.config,
                        })),
                        take(1),
                        tap((res) => {
                            configTelemetry.logElapsed();
                            if (!res.config.consoleLogs && !isDbarMode() && window) {
                                logger.info('AppInitializer: Console logs disabled');
                                Object.keys(window.console).forEach((e) => (window.console[e] = () => {}));
                            }
                        }),
                        mergeMap(({ config, agentInfo }) =>
                            utilitySrv.isMaintenanceMode$().pipe(
                                map((isMaintenanceMode) => {
                                    if (isMaintenanceMode) {
                                        // START -TODO
                                        // Questo redirect va rimosso non appena si rimuovono tutte le logiche di recupero carrello dall'app-component
                                        // Esiste una guard (offline-guard) che si occupa di controllare ad ogni navigazione se l'utente può accedere
                                        return router.navigate([RoutesPaths.Offline], {
                                            queryParams: { message: config?.offlineMessage },
                                        });
                                    }
                                    if (
                                        !agentInfo?.Agent?.Current ||
                                        !agentInfo?.UserConfiguration?.LastUsedCustomerSegment
                                    ) {
                                        return router.navigate([RoutesPaths.SettingsNoBack]);
                                    }
                                    return true;
                                })
                            )
                        )
                    )
                    .toPromise()
                    .catch((e) => {
                        logger.error(null, 'AppInitializer: Error loading configuration', e, false, true);
                    });
            } else {
                const urlHash = location.hash;
                if (urlHash.includes('#access_token')) {
                    LoadingService.update('Apertura App in corso...');
                    logger.warn('Oauth Token / urlHash: ' + urlHash);
                    logger.warn('urlHash: ' + urlHash.slice(1));

                    const crmApplicationUri = 'ms-dynamicsxrm://?' + urlHash.slice(1);
                    logger.warn('crmApplicationUri: ' + crmApplicationUri);
                    location.href = new URL(crmApplicationUri).toString();
                } else {
                    const message = 'Visualizzazione standalone non supportata';
                    setTimeout(() => {
                        logger.error(null, message, null, true);
                        document.getElementById(LoadingService.LOADER_CLASS.ROLLER).remove();
                        document.getElementById(LoadingService.LOADER_CLASS.MESSAGE).textContent = message;
                    }, 1000);
                }
                LoadingService.hide();
                return Promise.reject(null);
            }
        } catch (e) {
            logger.error(null, 'Error loading configuration', e, false, true);
            LoadingService.hide();
        }

        function initializerLoader(router: Router, telemetry: TelemetryMetric) {
            logger.info('loading configuration...');
            LoadingService.show('Caricamento in corso');

            router.events
                .pipe(
                    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                    take(1)
                )
                .subscribe(() => {
                    console.log('** FIRST NAVIGATION END **');
                    LoadingService.hide();
                    telemetry.logElapsed();
                });
        }
    };
}
