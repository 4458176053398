export enum BoActionFilter {
    CANCELLA = 'CANCELLA',
    CHECK_DOI = 'CHECKDOI',
    DA_VALIDARE_BO = 'DA_VALIDARE_BO',
    UPLOAD_MP3 = 'UPLOAD_MP3',
    DOWNLOAD_MP3 = 'DOWNLOAD_MP3',
    REPLACE_MP3 = 'REPLACE_MP3',
    GRAFO = 'GRAFO',
    DOI = 'DOI',
    RIFIUTA = 'RIFIUTA',
    PRE_CHECK = 'PRECHECK',
    STATO_SFA = 'STATO_SFA',
    FROM_DOI_TO_VO = 'FROM_DOI_TO_VO',
    COMPLETAMENTO_SENZA_FIRMA = 'COMPLETAMENTO_SENZA_FIRMA',
    CHECK_DOI_TO_CHECK_CALL = 'FROM_CHECKDOI_TO_CHECKCALL',
    CHECK_CALL_TO_CHECK_DOI = 'FROM_CHECKCALL_TO_CHECKDOI',
    CARICAMENTO_SEMIDIGITAL = 'CARICA_PLICO',
    SCARICAMENTO_SEMIDIGITAL = 'SCARICA_PLICO',
    AGGIORNA_QUOTE = 'AGGIORNA_QUOTE',
    DETTAGLIO_ORDINE = 'DETTAGLIO_ORDINE',
    REVOCA = 'REVOCA',
    CANCEL_ORDER = 'CANCEL_ORDER',
    RETRY_ORDER = 'RETRY_ORDER',
    LEARNING_DOI_SIGN = 'LEARNING_DOI_SIGN',
    LEARNING_JUMP_TO_ORDER = 'LEARNING_JUMP_TO_ORDER ',
    CANCELLA_OLI = 'DELETE_OLI',
}
