import { AptSalesProcess } from './../../../common/enums/apttus/apt-sales-process';
import { BoAgentFilter } from './bo-agent-filter';

export class MonitoraggioQuoteRequest {
    Filtri: MonitoraggioQuoteFiltri;
    Obfuscate: boolean;
    constructor(skip: number = 0, take: number = 0) {
        this.Filtri = new MonitoraggioQuoteFiltri(skip, take);
        this.Obfuscate = false;
    }
}

export class MonitoraggioQuoteFiltri {
    /**
     *
     */
    constructor(skip: number, take: number) {
        this.skip = skip;
        this.take = take;
    }
    skip: number;
    take: number;
    status: string[] = [];
    dateFilter: 'signDate' | 'createDate' | '' = 'createDate';
    dateFrom: string;
    dateTo: string;
    sortByField: string;
    sortByType: 'ASC' | 'DESC';
    generic: SearchQuoteFields;
    salesProcess: AptSalesProcess;
    agent: BoAgentFilter[];
    agencyBranch: string[];
}

export class SearchQuoteFields {
    key: string;
    value: string;
}
