import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getNumberOrNull } from '../../common/functions/remap.functions';
import { AptCommodityType } from '../../common/enums/apttus/apt-commodity-typeof-sale';
import {
    cleanObj,
    isActivePackageProduct,
    isNotDiscountItem,
    isVisibleNotTechProduct,
} from '../../common/functions/misc.functions';
import {
    containsProductGas,
    containsProductPower,
    isCommodityFamily,
    flowTypeUtil,
    containsProductSmartHome,
} from '../../common/functions/verifications.functions';
import { MacroFlowType } from '../models/flow-type';
import { OrderEntryState_v2, Product } from '../models/order-entry-state_v2';
import { TargetProductIndex } from '../models/utility.types';
import { UserState } from '../models/user-state';
import { D365CustomerSegment } from '../../common/enums/d365/d365-customer-segment';
import { AptProductType } from '../../common/enums/apttus/apt-product-type';

const v2OrderEntryState = createFeatureSelector<OrderEntryState_v2>('orderEntry');

export const isCommodityProduct =
    (commodityType?: AptCommodityType) =>
    ({ powerOrGas, family }: Pick<Product, 'powerOrGas' | 'family'>) =>
        isCommodityFamily(family) && ((!commodityType && !!powerOrGas) || commodityType === powerOrGas);

export const isAssetCommodityProduct =
    (commodityType?: AptCommodityType) =>
    ({
        podPdr,
        assetId,
        powerOrGas,
        sourceCustomer,
        family,
    }: Pick<Product, 'powerOrGas' | 'family' | 'podPdr' | 'assetId' | 'sourceCustomer'>): boolean =>
        !!podPdr &&
        (!!assetId || !!sourceCustomer?.assetId) &&
        isCommodityProduct(commodityType)({ powerOrGas, family });

export const isPendingAssetCommodityProduct = (commodityType?: AptCommodityType) => (product: Product) =>
    isAssetCommodityProduct(commodityType)(product) && !product.lineItemId;

export const isPowerProduct = (product: Product) =>
    product?.powerOrGas === AptCommodityType.Power || containsProductPower(product?.productType);
export const isGasProduct = (product: Product) =>
    product?.powerOrGas === AptCommodityType.Gas || containsProductGas(product?.productType);

export const isActiveVisibleMainProduct = (product: Product) =>
    isActivePackageProduct(product) && isNotDiscountItem(product);

export const isVisibleProduct = (product: Product) => isVisibleNotTechProduct(product) && isNotDiscountItem(product);

const PRODUCT_FILTER_MAP: {
    [key in TargetProductIndex]: (product: Product, index?: number) => boolean;
} = {
    [AptCommodityType.Power]: isPowerProduct,
    [AptCommodityType.Gas]: isGasProduct,
    ALL: (product) => true,
    SMARTHOME: (product) => containsProductSmartHome([product?.productType]),
};

export function* productFieldSelectorGenerator<T>({
    state,
    productSelector,
    productIdx,
}: {
    state: Pick<OrderEntryState_v2, 'products' | 'flowType'>;
    productSelector: (product: Product) => T;
    productIdx?: TargetProductIndex;
}): Generator<T> {
    const queryParamProductIdx = getNumberOrNull(new URLSearchParams(location.search).get('itemId'));

    const targetIndex = [
        // valore in input numerico o ALL
        getNumberOrNull(productIdx) || (<TargetProductIndex[]>['ALL', 'SMARTHOME']).includes(productIdx)
            ? productIdx
            : null,
        // Query Param
        typeof queryParamProductIdx === 'number' && queryParamProductIdx,
        // Valore in input POWER/GAS
        productIdx,
        // Default
        'ALL',
    ].find(
        (value) =>
            (typeof value === 'number' && value >= 0) ||
            Object.values(AptCommodityType).includes(value as AptCommodityType) ||
            ['ALL', 'SMARTHOME'].includes('' + value)
    );

    const productFilter =
        typeof targetIndex === 'number'
            ? (product: Product, idx: number) => idx === targetIndex
            : PRODUCT_FILTER_MAP[targetIndex];

    // Filtro la lista prodotti per avere solo quelli visibili
    const products = (state.products || []).filter(
        flowTypeUtil(state.flowType).inMacroFlowTypes(MacroFlowType.Cessazione)
            ? isVisibleProduct
            : isActiveVisibleMainProduct
    );

    for (let idx = 0; idx < state.products.length; idx++) {
        const product = products.find((p) => p.idx === idx);
        if (product && productFilter(product, idx)) {
            const foundProduct = productSelector(product);
            if (foundProduct) {
                yield foundProduct;
            }
        }
    }
}

export function productsFieldRetrieverGenerator(products: Product[]) {
    return <T>(productFieldSelector: (product: Product) => T): T => {
        const targetProduct = (products || []).find(productFieldSelector);
        return targetProduct && productFieldSelector(targetProduct);
    };
}

export function wrapperSelectProductByIndex<T>(productSelector: (product: Product) => T) {
    return (productIdx?: TargetProductIndex) =>
        createSelector(
            v2OrderEntryState,
            (state) =>
                productFieldSelectorGenerator({
                    state,
                    productSelector,
                    productIdx,
                }).next().value as T
        );
}

export const filterCommodityProducts = (product: Product) =>
    Object.values(AptCommodityType).includes(product?.powerOrGas) && isActiveVisibleMainProduct(product);

export const filterMaintenanceProducts = (product: Product) =>
    product?.productType === AptProductType.Manutenzione && isActiveVisibleMainProduct(product);

export function getContactNameFromUserState(state: UserState): string {
    const contact = cleanObj(state.contact || state.lead);
    if (contact) {
        return contact.egl_customersegmentcode === D365CustomerSegment.Microbusiness
            ? `${contact.name}  (${contact.egl_vatcode})`.trim()
            : `${contact.firstname}  ${contact.lastname}  (${contact.egl_taxcode})`.trim();
    }
    return null;
}
