import { BoActionFilter } from './../enums/shared/bo-action-filter';
import { AptPartnerships } from '../../modules/common/partnership/enums/apt-partnerships';
import { CostData, FornituraEsistente, ProductInfo, WinBackType } from '../../store/models/order-entry-state';
import {
    D365_OPERATION_MODE,
    FLOWTYPE_TO_SCRIPT_DOC_TYPE_MAP,
    INADMISSIBILITY_TO_INADMISSIBILITY_FILTER_TYPE,
    MACRO_FLOW_TYPES,
    MASTERSHIP_TO_D365MIGRATED_MAP,
    PROCESS_OPERATION_MAPPINGS,
} from '../map/flow-type-mapping.config';
import { AptCustomerType } from '../enums/apttus/apt-customer-type';
import { AptDoiConfirmModeType } from '../enums/apttus/apt-doi-confirm-mode-type';
import {
    AptSaleabilityOperationType,
    AptSalesInadmissibilityProcess,
    AptSalesProcess,
} from '../enums/apttus/apt-sales-process';
import { D365AgentProfileType } from '../enums/d365/d365-agent-profile-type';
import { D365AgentType } from '../enums/d365/d365-agent-type';
import { D365ConfirmationType } from '../enums/d365/d365-confirmation-type';
import { D365CustomerSegment } from '../enums/d365/d365-customer-segment';
import { D365OperationMode } from '../enums/d365/d365-operation-mode';
import { CONFIRMATION_TYPE_MAP } from '../map/confirmation-type-map';
import { containsProductGas, containsProductPower } from './verifications.functions';
import { FlowType, MacroFlowType } from '../../store/models/flow-type';
import { getCivicSuffix, sanitizeLogMessage } from './string-format.functions';
import { Params } from '@angular/router';
import { Product, ProductPriceInfo, TechnicalDetails } from '../../store/models/order-entry-state_v2';
import { D365AccountMigrated } from '../enums/d365/d365-account-migrated';
import { ProductPriceEntity } from '../../modules/cart-costs/services/product-costs.service';
import { AptCommodityType } from '../enums/apttus/apt-commodity-typeof-sale';
import { MastershipType } from '../../store/models/user-state';
import { cleanObj, parsePhoneNumber, sumPrices } from './misc.functions';
import { reverseCf } from './cf-piva.functions';
import * as moment from 'moment';
import { Contact as UserContact } from '../models/user/contact';
import { AptProductType } from '../enums/apttus/apt-product-type';
import {
    AtecoClassification6409,
    ATECO_CLASSIFICATION6409_MAP,
} from '../../modules/switch-in/technical-changes/enums/ateco_classification';
import { ProductTypeMap, ProductTypes } from '../../modules/common/models/product-address-visibility.types';
import { Questions } from '../services/shared/troubleshooting-values.service';
import { DestinationUse } from '../enums/shared/destination-use.enum';
import { DeepPartial } from '../interfaces/deep-partial';
import { AnagraficaData } from '../models/app/recupera-dati-salesup.response';
import { InadmissibilityFilter } from '../models/app/inadmissibility-filter';
import { AnyApttusProduct } from '../models/apttus/cart.types';
import { ScriptDocType } from '../enums/shared/script-doc-type';
import { AptVatCode } from '../enums/apttus/apt-vat-code';
import { SubProductType } from '../enums/shared/sub-product-type';

export const flowTypeToD365OperationMode = (flowType: FlowType): D365OperationMode => {
    return D365_OPERATION_MODE[flowType] || D365OperationMode.SwitchIn;
};

export const d365OperationModeToFlowType = (operationMode: D365OperationMode): FlowType => {
    const [flowType] = Object.entries(D365_OPERATION_MODE).find(([, d365]) => operationMode === d365);
    if (!flowType) {
        console.error(null, 'flowType', 'Tipologia non gestita', null, true);
    }
    return flowType as FlowType;
};

export const flowTypeToScriptDocType = (flowType: FlowType, defaultScript: ScriptDocType): ScriptDocType => {
    return FLOWTYPE_TO_SCRIPT_DOC_TYPE_MAP[flowType] || defaultScript;
};

export const getAgentD365toApt = (type: D365AgentType | D365AgentProfileType): string => {
    switch (type) {
        case D365AgentType.Agent:
            return 'Agente';
        case D365AgentType.Backoffice:
            return 'Backoffice';
        case D365AgentType.TeamLeader:
            return 'Team leader';

        default:
            return '';
    }
};

export const convertSegmentD365toApt = (segment: D365CustomerSegment): AptCustomerType => {
    if (segment === D365CustomerSegment.Microbusiness) {
        return AptCustomerType.Microbusiness;
    }
    return AptCustomerType.Residenziale;
};

export const convertSegmentAptToD365 = (segment: AptCustomerType): D365CustomerSegment => {
    if (segment === AptCustomerType.Microbusiness) {
        return D365CustomerSegment.Microbusiness;
    }
    return D365CustomerSegment.Residential;
};

export const aptConfermationTypeToD365 = (confirmType: AptDoiConfirmModeType): D365ConfirmationType => {
    const d365ConfirmType = CONFIRMATION_TYPE_MAP[confirmType];
    if (!d365ConfirmType) {
        console.error(null, 'ConfirmationType', 'Tipologia non gestita', null, true);
    }
    return d365ConfirmType;
};

export const d365ConfermationTypeToApt = (confirmType: D365ConfirmationType): AptDoiConfirmModeType => {
    const [aptConfirmType] = Object.entries(CONFIRMATION_TYPE_MAP).find(([, d365]) => confirmType === d365);
    if (!aptConfirmType) {
        console.error(null, 'ConfirmationType', 'Tipologia non gestita', null, true);
    }
    return aptConfirmType as AptDoiConfirmModeType;
};

export const aptSalesProcessToFlowType = (salesProcess: AptSalesProcess): FlowType =>
    salesProcessOrOperationTypeToFlowType(salesProcess);

export const flowTypeToAptSalesProcess = (salesProcess: FlowType): AptSalesProcess =>
    operationTypeOrFlowTypeToSalesProcess(salesProcess);
/**
 * @description Esegue una mappatura dei valori del PAV "egl_partnership__c" verso una label adatta alla GUI
 * @param partnership Il valore raw della partnership proveniente dal PAV
 * @returns Label adatta all'utilizzo sull'interfaccia grafica. Il valore è da parsare usando il TranslateService
 */
export const aptPartnershipToString = (partnership: AptPartnerships): string => {
    switch (partnership) {
        case AptPartnerships.NO_PARTNERSHIP:
            return 'PARTNERSHIP.VALUE_LABELS.NO_PARTNERSHIP';
        case AptPartnerships.FASTWEB:
            return 'PARTNERSHIP.VALUE_LABELS.FASTWEB';
        default:
            return null;
    }
};

export const salesProcessOrFlowTypeToOperationType = (
    salesProcessOrFlowType: AptSalesProcess | FlowType
): AptSaleabilityOperationType => {
    const opFlowSpRecord =
        salesProcessOrFlowType &&
        Object.entries(PROCESS_OPERATION_MAPPINGS).find(([, flowTypeSalesProcess]) =>
            Object.entries(flowTypeSalesProcess).some(([flowType, salesProcess]) =>
                [flowType].concat(salesProcess).includes(salesProcessOrFlowType)
            )
        );
    if (!opFlowSpRecord) {
        console.error(
            sanitizeLogMessage(`salesProcessOrFlowTypeToOperationType - no mapping found for ${salesProcessOrFlowType}`)
        );
    }
    const operationType: AptSaleabilityOperationType =
        ((opFlowSpRecord || [])[0] as AptSaleabilityOperationType) || null;

    return operationType;
};

export const operationTypeOrFlowTypeToSalesProcess = (
    operationTypeOrFlowType: AptSaleabilityOperationType | FlowType
): AptSalesProcess => {
    const salesProcess =
        (operationTypeOrFlowType &&
            Object.entries(PROCESS_OPERATION_MAPPINGS).reduce(
                (foundSalesProcess: AptSalesProcess, [operationType, flowTypeSalesProcess]) =>
                    foundSalesProcess ||
                    [].concat(
                        (Object.entries(flowTypeSalesProcess).find(
                            ([flowType]) =>
                                operationType === operationTypeOrFlowType || flowType === operationTypeOrFlowType
                        ) || [])[1]
                    )[0],
                null
            )) ||
        null;
    if (!salesProcess) {
        console.error(`operationTypeOrFlowTypeToSalesProcess - no mapping found for ${operationTypeOrFlowType}`);
    }
    return salesProcess;
};

export const salesProcessOrOperationTypeToFlowType = (
    salesProcessOrOperationType: AptSalesProcess | AptSaleabilityOperationType
): FlowType => {
    const fType =
        (salesProcessOrOperationType &&
            Object.entries(PROCESS_OPERATION_MAPPINGS).reduce(
                (foundFlowType: FlowType, [operationType, flowTypeSalesProcess]) =>
                    foundFlowType ||
                    ((Object.entries(flowTypeSalesProcess).find(
                        ([, salesProcess]) =>
                            operationType === salesProcessOrOperationType ||
                            [].concat(salesProcess).includes(salesProcessOrOperationType)
                    ) || [])[0] as FlowType),
                null
            )) ||
        null;

    if (!fType) {
        console.error(`salesProcessOrOperationTypeToFlowType - no mapping found for ${salesProcessOrOperationType}`);
    }
    return fType;
};

export const flowTypeToMacroFlowType = (flowType: FlowType): MacroFlowType =>
    (Object.entries(MACRO_FLOW_TYPES).find(([, flowTypes]) => [].concat(flowTypes).includes(flowType)) ||
        [])[0] as MacroFlowType;

export function productsToL10NType(products: Product[]): ProductTypes {
    const PRODUCT_TYPE_MAP: ProductTypeMap = Object.entries(AptProductType).reduce(
        (aggr, [key, value]) => ({
            ...aggr,
            [value.toUpperCase()]: key,
        }),
        {} as ProductTypeMap
    );

    const distinctTypes = new Set<ProductTypes>(
        (products || []).map((product) =>
            product?.subProductType === SubProductType.RCDanni
                ? 'INSURANCE_RC'
                : PRODUCT_TYPE_MAP[product?.productType?.toUpperCase()]
        )
    );
    return [
        [
            getAptProductTypeKeyByValue(AptProductType.TariffaCommodityLuce as unknown as ProductTypes),
            getAptProductTypeKeyByValue(AptProductType.ProdottoCommodityLuce as unknown as ProductTypes),
        ],
        [
            getAptProductTypeKeyByValue(AptProductType.TariffaCommodityGas as unknown as ProductTypes),
            getAptProductTypeKeyByValue(AptProductType.ProdottoCommodityGas as unknown as ProductTypes),
        ],
    ].every((commodityTypes) => commodityTypes?.some((commodityType) => distinctTypes?.has(commodityType)))
        ? 'DUAL'
        : distinctTypes.size === 1
        ? distinctTypes.values().next().value?.toUpperCase()
        : null;
}
export const getAptProductTypeKeyByValue = (value: ProductTypes): ProductTypes => {
    const indexOfS = Object.values(AptProductType).indexOf(value as unknown as AptProductType);
    return Object.keys(AptProductType)[indexOfS] as unknown as ProductTypes;
};

export interface VendorCodeMap {
    vendorCode: string;
    companyName: string;
    vendorId: string;
}

/**
 * @description Gas vendors map list
 * @param vendorCode Codice Distributore NETA - da inviare per il servizio che fornisce i costi
 * @param vendorId Codice Distributore LineaPDR - servizio dati fornitura (PDR/Matricola)
 * @param companyName Ragione sociale del fornitore
 */
const GAS_VENDOR_CODES_MAP: VendorCodeMap[] = [
    {
        vendorCode: 'G00088',
        companyName: 'ITALGAS RETI S.p.A.',
        vendorId: '00489490011',
    },
    {
        vendorCode: 'G01596',
        companyName: 'TOSCANA ENERGIA S.p.A.',
        vendorId: '05608890488',
    },
    {
        vendorCode: 'G00102',
        companyName: "METANO SANT'ANGELO LODIGIANO S.p.A.",
        vendorId: '00757920152',
    },
    {
        vendorCode: 'G01603',
        companyName: 'UMBRIA DISTRIBUZIONE GAS S.p.A.',
        vendorId: '01356930550',
    },
];

export const getProviderInfo = (codeIdName: string): VendorCodeMap =>
    GAS_VENDOR_CODES_MAP.find(({ vendorCode, vendorId, companyName }) =>
        [vendorCode, vendorId, companyName].some(
            (value) => value.toLowerCase() === (codeIdName || '').trim().toLowerCase()
        )
    );

export function getNumberOrNull(value: string | number | null | undefined): number | null {
    if (value === null || typeof value === 'undefined') {
        return null;
    }
    const parsedNumber = parseFloat(value + '');
    return isNaN(parsedNumber) ? null : parsedNumber;
}

export const COMMODITY_TYPE_PDF_MAP: {
    [key in AptCommodityType]: 'egl_POD__c' | 'egl_PDR__c';
} = {
    [AptCommodityType.Gas]: 'egl_PDR__c',
    [AptCommodityType.Power]: 'egl_POD__c',
};

export const v2ProductToLineItemAttributes = ({ podPdr, powerOrGas }: Product): Params => ({
    [COMMODITY_TYPE_PDF_MAP[powerOrGas]]: podPdr,
});
type ProductCommodityType = Partial<Pick<AnyApttusProduct, 'ProductType'> & Pick<Product, 'productType'>>;
const PRODUCT_COMMODITY_TYPE_MAPPER = [
    (product: ProductCommodityType): AptCommodityType =>
        containsProductGas(product?.ProductType || product?.productType) ? AptCommodityType.Gas : null,
    (product: ProductCommodityType): AptCommodityType =>
        containsProductPower(product?.ProductType || product?.productType) ? AptCommodityType.Power : null,
];

export function getProductCommodityType(apttusProduct: AnyApttusProduct): AptCommodityType;
export function getProductCommodityType(stateProduct: Product): AptCommodityType;
export function getProductCommodityType(product: AnyApttusProduct & Product): AptCommodityType {
    return PRODUCT_COMMODITY_TYPE_MAPPER.reduce(
        (target, prodTypeChecker) => target || prodTypeChecker(product),
        null as AptCommodityType
    );
}

export const mastershipTypeToD365AccountMigrated = (mastership: MastershipType): D365AccountMigrated =>
    MASTERSHIP_TO_D365MIGRATED_MAP[mastership] || null;

export const d365AccountMigratedToMastershipType = (d365Mastership: D365AccountMigrated): MastershipType =>
    ((Object.entries(MASTERSHIP_TO_D365MIGRATED_MAP).find(([key, value]) => value === d365Mastership) ||
        [])[0] as MastershipType) || null;

export const mastershipTypeToStrictD365AccountMigrated = (
    mastership: MastershipType
): Exclude<D365AccountMigrated, D365AccountMigrated.NOT_SET> =>
    (mastership !== MastershipType.New && mastershipTypeToD365AccountMigrated(mastership)) || null;

export const d365AccountMigratedToStrictMastershipType = (
    d365Mastership: D365AccountMigrated
): Exclude<MastershipType, MastershipType.New> => {
    const masterShipType: Exclude<MastershipType, MastershipType.New> =
        (d365Mastership !== D365AccountMigrated.NOT_SET &&
            (d365AccountMigratedToMastershipType(d365Mastership) as Exclude<MastershipType, MastershipType.New>)) ||
        null;
    if (!masterShipType) {
        throw Error(`Impossibile convertire ${d365Mastership} in un codice Mastership`);
    }

    return masterShipType;
};

export function productsPriceMapper(products: Product[], prices: ProductPriceEntity[]): Product[] {
    const priceMap = prices.reduce(
        (aggr, { lineItemId, ...price }) => ({
            ...aggr,
            [lineItemId]: price,
        }),
        {} as { [key: string]: ProductPriceInfo }
    );
    return products.map((product) => ({
        ...product,
        prices: Object.assign({}, product.prices || {}, priceMap[product.lineItemId] || {}),
    }));
}

export function productsPriceToRecordMapper(products: ProductInfo[], prices: ProductPriceEntity[]): CostData {
    const priceMap = prices.reduce(
        (aggr, { lineItemId, ...price }) => ({
            ...aggr,
            [lineItemId]: price,
        }),
        {} as { [key: string]: ProductPriceInfo }
    );
    const records = products
        .filter((product) => !!priceMap[product?.lineItemId])
        .map((product) => {
            const priceData = priceMap[product.lineItemId];
            return {
                id: product?.lineItemId,
                name: product?.nome,
                eniCosts: priceData?.ops,
                otherCosts: priceData?.sdr,
                total: sumPrices(priceData?.ops, priceData?.sdr),
            };
        });
    return {
        records,
        total: records.reduce((tot, { total }) => sumPrices(tot, total), 0),
    };
}

const WIN_BACK_GAS_POWER_MAP = {
    [AptCommodityType.Gas]: WinBackType.Gas,
    [AptCommodityType.Power]: WinBackType.Power,
};

export function winBackTypeToAptCommodityTypes(type: WinBackType): AptCommodityType[] {
    return type === WinBackType.None || !type
        ? null
        : type === WinBackType.Both
        ? Object.values(AptCommodityType)
        : [
              (Object.entries(WIN_BACK_GAS_POWER_MAP).find(([commType, wbType]) => wbType === type) ||
                  [])[0] as AptCommodityType,
          ].filter(Boolean);
}

export function aptCommodityTypesToWinBackType(types: AptCommodityType[]): WinBackType {
    const deDupeTypes = Array.from(new Set(types)).filter(Boolean);

    return !deDupeTypes.length
        ? WinBackType.None
        : deDupeTypes.length === 1
        ? WIN_BACK_GAS_POWER_MAP[deDupeTypes[0]]
        : WinBackType.Both;
}
export const boActionFilterToIcon = (boActionFilter: BoActionFilter): string => {
    switch (boActionFilter) {
        case BoActionFilter.CANCEL_ORDER:
        case BoActionFilter.REVOCA:
            return 'delete_1';
        case BoActionFilter.RETRY_ORDER:
            return 'refresh';
        default:
            return 'radio-button-outline-1';
    }
};

export function taxCodeToBirthDate(cf: string): Date {
    const reversedCf = reverseCf(cf);
    if (reversedCf?.birthday) {
        return moment(reversedCf.birthday).toDate();
    }
    return null;
}

export function getCivicAndSuffix(value: string | number): { civic: number; civicSuffix: string } {
    return {
        civic: getNumberOrNull(value),
        civicSuffix: getCivicSuffix((value || '') + '') || null,
    };
}

const AVAILABLE_COUNTRY_LIS: { matcher: RegExp; iso3166Alpha3: string }[] = [
    { matcher: /Ital(?:y|ia)/i, iso3166Alpha3: 'ITA' },
    { matcher: /San Marino/i, iso3166Alpha3: 'SNM' },
    { matcher: /Vaticano?/i, iso3166Alpha3: 'VAT' },
];

export function getCountryIso2166Alpha3(countryOrIsoCode: string) {
    return (
        AVAILABLE_COUNTRY_LIS.find(
            ({ matcher, iso3166Alpha3 }) =>
                iso3166Alpha3 === (countryOrIsoCode || '').trim().toUpperCase() || matcher.test(countryOrIsoCode)
        )?.iso3166Alpha3 || null
    );
}

export function normalizeContactPhones(contact: UserContact): UserContact {
    const mobile =
        !contact?.prefixMobilephone && contact?.mobilephone
            ? parsePhoneNumber(contact?.mobilephone)
            : {
                  prefix: contact?.prefixMobilephone,
                  phone: contact?.mobilephone,
              };
    const phone1 =
        !contact?.prefixTelephone1 && contact?.telephone1
            ? parsePhoneNumber(contact?.telephone1)
            : {
                  prefix: contact?.prefixTelephone1,
                  phone: contact?.telephone1,
              };
    const phone2 =
        !contact?.prefixTelephone2 && contact?.telephone2
            ? parsePhoneNumber(contact?.telephone2)
            : {
                  prefix: contact?.prefixTelephone2,
                  phone: contact?.telephone2,
              };

    return {
        ...(contact || ({} as UserContact)),
        prefixMobilephone: mobile.prefix,
        mobilephone: mobile.phone,
        prefixTelephone1: phone1.prefix,
        telephone1: phone1.phone,
        prefixTelephone2: phone2.prefix,
        telephone2: phone2.phone,
    };
}

function getClass6409NameCode(
    codeOrName: AtecoClassification6409 | number
): { name: AtecoClassification6409; code: number } | null {
    const [name, code] = (Object.entries(ATECO_CLASSIFICATION6409_MAP).find(
        ([className, classCode]) =>
            className.trim().toUpperCase() === ((codeOrName || '') + '').trim().toUpperCase() ||
            classCode === getNumberOrNull(codeOrName)
    ) || []) as [AtecoClassification6409, number];
    return name && code ? { name, code } : null;
}

export function getClass6409Code(codeOrName: AtecoClassification6409 | number): number | null {
    return getClass6409NameCode(codeOrName)?.code || null;
}

export function getClass6409Name(codeOrName: AtecoClassification6409 | number): AtecoClassification6409 | null {
    return getClass6409NameCode(codeOrName)?.name || null;
}

// NON ALTERARE L'ORDINE, CHE DEVE ESSERE: 'COTTURA', 'ACQUA CALDA', 'RISCALDAMENTO', 'ALTRI USI', 'ALTRI USI TECNOLOGICO'
const GAS_RESIDENTIAL_TYPE_OF_USE_ORDERED_LIST = [
    'COTTURA',
    'ACQUA CALDA',
    'RISCALDAMENTO',
    'ALTRI USI',
    'ALTRI USI TECNOLOGICO',
];

export function orderTypeOfUsage(typeOfUsage: null | undefined | string | string[]): string | null {
    const normalizedValue: string[] | null =
        typeof typeOfUsage === 'string' ? typeOfUsage.split('|') : Array.isArray(typeOfUsage) ? typeOfUsage : null;
    return normalizedValue?.length
        ? GAS_RESIDENTIAL_TYPE_OF_USE_ORDERED_LIST.filter((value) => normalizedValue.includes(value)).join('|')
        : null;
}

export function getDestinationUseFromQuestionsResult(questionsResult: number): DestinationUse {
    return (questionsResult & Questions.IS_BASEMENT) === Questions.IS_BASEMENT
        ? DestinationUse.Appurtenances
        : DestinationUse.Home;
}

export function getQuestionsResultFromDestinationUse(destinationUse: DestinationUse, questionsResult: number): number {
    return !!destinationUse && destinationUse === DestinationUse.Appurtenances
        ? questionsResult + Questions.IS_BASEMENT
        : questionsResult;
}

export function cleanCorrectors(
    technicalDetails: DeepPartial<TechnicalDetails>,
    deleteCorrectors: boolean
): DeepPartial<TechnicalDetails> {
    deleteCorrectors
        ? ['gasMeterAdjustmentSerialNumber', 'gasMeterAdjustmentDigit', 'gasMeterAdjustmentNumber'].forEach(
              (value) => (technicalDetails[value] = null)
          )
        : null;
    return technicalDetails;
}

export function customerDataToContact(data: AnagraficaData): UserContact {
    const startActivation = (data.fornitureAttive || [])
        .filter((f: FornituraEsistente) => f.stato === 'ATTIVO')
        .map((f: FornituraEsistente) => {
            const date = moment(f.datiInizio).utcOffset(0, true);
            return date.isValid() ? date : undefined;
        })
        .filter((d) => !!d)
        .sort((a, b) => a.diff(b))
        .map((d) => d.toDate())[0];
    const { cap, prov, comune, toponomastica, via, civico } = data?.indirizzo || {};
    const birthDate = moment(data.dataNascita, 'MM/DD/YYYY');
    return cleanObj({
        egl_taxcode: data.cf,
        address1_postalcode: cap,
        address1_stateorprovince: prov,
        address1_city: comune,
        emailaddress1: data.email,
        mobilephone: data.telefonoPrimario,
        telephone2: data.cellulareCliente,
        egl_customercode: data.csn,
        isFormerCustomer: data.accountStatus === 'NON ATTIVO',
        isCustomer: data.accountStatus === 'ATTIVO' && !!startActivation,
        startActivation,
        firstname: data.nomeContatto,
        lastname: data.cognomeContatto,
        gender: data.genere,
        nationality: data.nazionalita,
        birthPlace: data.comuneNascita,
        birtDate: birthDate.isValid() ? birthDate.toDate() : null,
        name: data.nomeContatto && data.cognomeContatto && `${data.nomeContatto} ${data.cognomeContatto}`,
        address1_line1: data.indirizzo && `${toponomastica || ''} ${via || ''} ${civico || ''}`.trim(),
        egl_vatcode: data.piva,
        egl_vulnerabilitytype_socialbonus: !!data?.egl_vulnerabilitytype_socialbonus,
        egl_vulnerabilitytype_saemapre: !!data?.egl_vulnerabilitytype_saemapre,
        egl_vulnerabilitytype_over75: !!data?.egl_vulnerabilitytype_over75,
        egl_vulnerabilitytype_disabled: !!data?.egl_vulnerabilitytype_disabled,
    }) as UserContact;
}

export const inadmissibilitySalesProcessToInadmissibilityFilter = (
    inadmissibilityFilter: AptSalesInadmissibilityProcess
): InadmissibilityFilter => INADMISSIBILITY_TO_INADMISSIBILITY_FILTER_TYPE[inadmissibilityFilter] || null;

export const salesProcessToBulkActionName = (salesProcess: AptSalesProcess): string => {
    switch (salesProcess) {
        case AptSalesProcess.ScontoStandalone:
            return 'ATTIVAZIONE SCONTI';
        case AptSalesProcess.DisattivazioneScontoStandalone:
            return 'DISATTIVAZIONE SCONTI';
        default:
            return salesProcess;
    }
};

export const APT_VAT_CODE_MAP: {
    [key in AptVatCode]: number;
} = {
    [AptVatCode.Iva10]: 1.1,
    [AptVatCode.Iva22]: 1.22,
};

export const castStringIntoBoolean = (value: string | boolean) => {
    return (<string>value)?.toLowerCase()?.trim() === 'true';
};
