import { Injectable, Injector } from '@angular/core';
import { Cart, CartService } from '@congacommerce/ecommerce';
import { EMPTY, Observable, of, Subscriber } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap, timeout } from 'rxjs/operators';
import { retryWithDelay, mapOrEmpty } from '../../../../common/functions/observable-operators';
import { EglCartPriceResponse } from '../../../../common/services/apttus/utility/egl-cart.service';
import { LoggerService } from '../../../../common/services/shared/logger.service';
import { LoadingService } from '../../../../common/services/shared/loading.service';
import { EglCartExtended } from '../../../../common/models/apttus/tables/cart/egl-cart-extended';

@Injectable({ providedIn: 'root' })
export class CartPricingService {
    // coda FIFO - subscriber
    private pendingPricingCalls: Subscriber<any>[] = [];

    /**
     * @description Pricing retry max tentaives
     */
    public readonly PRICE_MAX_TENTATIVE = 5;
    /**
     * @description time in milliseconds
     */
    public readonly MAX_TIMEOUT = 20000;
    /**
     * @description time in milliseconds
     */
    public readonly RETRY_DELAY = this.MAX_TIMEOUT * 0.5;

    public pushPendingPricingCall(subscriber: Subscriber<any>) {
        this.pendingPricingCalls.push(subscriber);
    }

    public popPendingPricingCall(): Subscriber<any> {
        return this.pendingPricingCalls.pop();
    }

    public hasPendingPricingCalls(): boolean {
        return !!this.pendingPricingCalls.length;
    }

    constructor(private injector: Injector, private logger: LoggerService, private cartService: CartService) {}

    public priceCart(pricingMode?: string, lineNumbers?: number[]): Observable<EglCartExtended> {
        return of(this.cartService.priceCart(pricingMode, lineNumbers)).pipe(
            mergeMap(() => this.cartService['priceResponseEvt']),
            tap((priceResponse: EglCartPriceResponse) => {
                if (priceResponse.status === 'error') {
                    this.logger.error(null, 'priceCart error', priceResponse);
                    throw new Error('Price falied');
                }
                this.logger.info('priceCart response', priceResponse);
            }),
            map(({ response }) => response)
        );
    }

    public getPricedCart(): Observable<Cart> {
        const cartSrv: CartService = this.injector.get(CartService);

        // intercetto le variazioni del carrello
        return cartSrv.getMyCart().pipe(
            // Tenativi massimi di retry del pricing. Casi in cui l'api non va in errore, ma il lineitem rimane in Error.
            take(this.PRICE_MAX_TENTATIVE + 1),
            // ignoro gli eventi di carrello privi di cart.Id, in assenza di cartId, e con price pending
            filter((cart) => !cartSrv.isPricePending(cart)),
            // sollevo eccezione se non arriva un evento valido entro il tempo massimo
            timeout(this.MAX_TIMEOUT),
            // intercetto l'errore del timeout
            catchError((err) =>
                this.priceCartWithRetry().pipe(
                    // nel caso pricing vada in success, non emetto l'evento poichè verrà rieseguita la getMyCart
                    mergeMap(() => EMPTY)
                )
            ),
            filter((cart) => !cartSrv.isPricePending(cart)),
            mapOrEmpty(
                (cart) => cart,
                () => {
                    throw new Error('[PRICE] maximum repricing attempts. Price in Pending or Error.');
                }
            ),
            take(1),
            LoadingService.loaderOperator('Calcolo prezzo in corso')
        );
    }

    /**
     * Eseguo la chiamata al priceCart per il massimo numero di tentativi impostati
     * @returns EglCartExtended
     */
    public priceCartWithRetry(): Observable<EglCartExtended> {
        return this.priceCart().pipe(
            LoadingService.loaderOperator('Calcolo prezzo in corso'),
            // riprovo in caso d'errore
            retryWithDelay(this.PRICE_MAX_TENTATIVE - 1, this.RETRY_DELAY)
        );
    }
}
