import { D365CustomerSegment } from '../../../../common/enums/d365/d365-customer-segment';
import { AptCommodityType } from '../../../../common/enums/apttus/apt-commodity-typeof-sale';
import { AptProductFamily } from '../../../../common/enums/apttus/apt-product-family';
import { ProductAddressTypesVisibilityCfg, ProductAddressType } from '../../models/product-address-visibility.types';
import { AptProductType } from '../../../../common/enums/apttus/apt-product-type';
import { SubProductType } from '../../../../common/enums/shared/sub-product-type';

type ProductAddressesVisibility = {
    [key in ProductAddressType | 'DEFAULT']?: Partial<ProductAddressTypesVisibilityCfg>;
};

type SegmentProductAddressesVisibility = ProductAddressesVisibility & {
    [key in D365CustomerSegment]?: ProductAddressesVisibility;
};

export const PRODUCT_FAMILY_ADDRESS_MAP: SegmentProductAddressesVisibility = {
    // Configurazioni specifiche per Microbusiness
    [D365CustomerSegment.Microbusiness]: {
        DEFAULT: {
            SEDE_LEGALE: true,
        },
    },
    // Configurazioni specifiche per Residenziale
    [D365CustomerSegment.Residential]: {
        [AptCommodityType.Power]: {
            RESIDENZA: 'questionOnly',
        },
        [AptProductFamily.Assicurazione]: {
            RESIDENZA: true,
        },
        INSURANCE_RC: {
            RESIDENZA: false,
        },
        [SubProductType.RCDanni]: {
            RESIDENZA: false,
            DOMICILIO: true,
            ABITAZIONE_ABITUALE: true,
            ABITAZIONE_DI_PROPRIETA: true,
        },
        [SubProductType.Implant]: {
            RESIDENZA: true,
            DOMICILIO: true,
            ABITAZIONE_ABITUALE: true,
            ABITAZIONE_DI_PROPRIETA: true,
        },
        [SubProductType.Appliance]: {
            RESIDENZA: true,
            DOMICILIO: true,
            ABITAZIONE_ABITUALE: true,
            ABITAZIONE_DI_PROPRIETA: true,
        },
        DEFAULT: {
            SEDE_LEGALE: false,
        },
    },
    DEFAULT: {
        FATTURAZIONE: true,
    },
    [AptCommodityType.Power]: {
        FORNITURA: true,
        SPEDIZIONE: false,
    },
    POWER_APPURTENANCES: {
        FORNITURA: true,
        SPEDIZIONE: false,
    },
    [AptCommodityType.Gas]: {
        FORNITURA: true,
        SPEDIZIONE: false,
    },
    [AptProductFamily.BeniFisici]: {
        FORNITURA: false,
        SPEDIZIONE: true,
    },
    [AptProductType.SmartHome]: {
        FORNITURA: false,
        SPEDIZIONE: true,
    },
    // Manutenzione
    [AptProductType.Manutenzione]: {
        FORNITURA: true,
        SPEDIZIONE: false,
    },
    [AptProductFamily.VariazioneTecnica]: {
        FORNITURA: true,
        SPEDIZIONE: false,
    },
    [AptProductFamily.Assicurazione]: {
        FORNITURA: true,
        SPEDIZIONE: false,
        FATTURAZIONE: false,
    },
    INSURANCE_RC: {
        FORNITURA: true,
        SPEDIZIONE: false,
        FATTURAZIONE: false,
    },
    [SubProductType.RCDanni]: {
        FORNITURA: true,
        SPEDIZIONE: false,
        FATTURAZIONE: false,
        DOMICILIO: true,
        ABITAZIONE_ABITUALE: true,
        ABITAZIONE_DI_PROPRIETA: true,
    },
    [SubProductType.Implant]: {
        FORNITURA: true,
        SPEDIZIONE: false,
        FATTURAZIONE: false,
        DOMICILIO: true,
        ABITAZIONE_ABITUALE: true,
        ABITAZIONE_DI_PROPRIETA: true,
    },
    [SubProductType.Appliance]: {
        FORNITURA: true,
        SPEDIZIONE: false,
        FATTURAZIONE: false,
        DOMICILIO: true,
        ABITAZIONE_ABITUALE: true,
        ABITAZIONE_DI_PROPRIETA: true,
    },
};
