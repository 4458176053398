import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard, GuestGuard } from '@congacommerce/ecommerce';
import { OrderEntryOutletComponent } from './routing-outlet/order-entry-outlet.component';
import { RoutesPaths } from './common/config/routes-paths';
import { TarantulaResolver } from './modules/data-loader/resolvers/tarantula.resolver';
import { FullscreenOutletComponent } from './routing-outlet/fullscreen-outlet.component';
import { AssetLoaderResolver } from './modules/data-loader/resolvers/asset-loader.resolver';
import { SalesForceLoginGuard } from './common/guards/salesforce-login.guard';
import { OfflineComponent } from './errors/offline/offline.component';
import { MaintenanceGuard } from './common/guards/offline.guard';
import { CartFlowTypeResolver } from './modules/flow-type/resolvers/cart-flow-type.resolver';

const routes: Routes = [
    { path: RoutesPaths.Offline, component: OfflineComponent },
    {
        path: 'u',
        loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
        canActivate: [GuestGuard],
    },
    {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [SalesForceLoginGuard, MaintenanceGuard],
        data: {
            redirectUrl: RoutesPaths.ULogin,
        },
        children: [
            {
                path: RoutesPaths.WorkabilityMask,
                component: FullscreenOutletComponent,
                loadChildren: () => import('./modules/workability/workability.module').then((m) => m.WorkabilityModule),
            },
            {
                path: RoutesPaths.BackOffice,
                component: FullscreenOutletComponent,
                loadChildren: () => import('./modules/back-office/back-office.module').then((m) => m.BackOfficeModule),
            },
            {
                path: 'bulk-order',
                component: FullscreenOutletComponent,
                loadChildren: () => import('./modules/bulk-order/bulk-order.module').then((m) => m.BulkOrderModule),
            },
            {
                path: 'asset',
                component: FullscreenOutletComponent,
                loadChildren: () => import('./modules/asset-product/asset.module').then((m) => m.AssetModule),
            },
            {
                path: 'load-asset/operation/:operationMode/asset/:assetId',
                resolve: { initComplete: AssetLoaderResolver },
                children: [],
            },

            {
                path: '',
                component: OrderEntryOutletComponent,
                resolve: [TarantulaResolver],
                runGuardsAndResolvers: 'always',
                children: [
                    {
                        path: '',
                        redirectTo: RoutesPaths.Dashboard,
                        pathMatch: 'full',
                    },
                    {
                        path: '',
                        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
                    },
                    {
                        path: '',
                        loadChildren: () => import('./errors/errors.module').then((m) => m.ErrorsModule),
                    },
                    {
                        path: RoutesPaths.LeadRedirect,
                        loadChildren: () =>
                            import('./modules/switch-in/lead-redirect/lead-redirect.module').then(
                                (m) => m.LeadRedirectModule
                            ),
                    },
                    {
                        path: RoutesPaths.Dashboard,
                        loadChildren: () =>
                            import('./modules/switch-in/dashboard/dashboard.module').then((m) => m.DashboardModule),
                    },
                    {
                        path: RoutesPaths.AllProducts,
                        runGuardsAndResolvers: 'always',
                        loadChildren: () =>
                            import('./modules/switch-in/products/products.module').then((m) => m.ProductsModule),
                    },
                    {
                        path: RoutesPaths.Search,
                        loadChildren: () =>
                            import('./modules/switch-in/products/products.module').then((m) => m.ProductsModule),
                        data: { title: 'Search' },
                    },
                    {
                        path: RoutesPaths.Carts,
                        resolve: [CartFlowTypeResolver],
                        loadChildren: () => import('./modules/common/carts/carts.module').then((m) => m.CartsModule),
                    },

                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/activation/troubleshooting/troubleshooting-attivazione.module').then(
                                (m) => m.TroubleshootingAttivazioneModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                './modules/switch-in/order-entry/steps/01-dati-anagrafici/dati-anagrafici.module'
                            ).then((m) => m.DatiAnagraficiModule),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/switch-in/order-entry/steps/02-dati-prodotto/dati-prodotto.module').then(
                                (m) => m.DatiProdottoModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/switch-in/technical-changes/technical-changes.module').then(
                                (m) => m.TechnicalChangesModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/activation/dati-prodotto-attivazione.module').then(
                                (m) => m.DatiProdottoAttivazioneModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/activation/components/tipologia-immobile/tipologia-immobile.module').then(
                                (m) => m.TipologiaImmobileModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/activation/activation-appointment/presa-appuntamento.module').then(
                                (m) => m.PresaAppuntamentoModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/common/order-entry/steps/03-dati-pagamento/dati-pagamento.module').then(
                                (m) => m.DatiPagamentoModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                './modules/common/order-entry/steps/04-acquisizione-documento/acquisizione-documento.module'
                            ).then((m) => m.AcquisizioneDocumentoModule),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/common/order-entry/steps/06-firma/firma.module').then(
                                (m) => m.FirmaModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                './modules/common/order-entry/steps/07-verifica-contatto/verifica-contatto.module'
                            ).then((m) => m.VerificaContattoModule),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/common/order-entry/steps/08-thank-you-page/thank-you-page.module').then(
                                (m) => m.ThankYouPageModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/common/order-entry/steps/06-firma/privacy/privacy.module').then(
                                (m) => m.PrivacyModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/change-product/delibera-amministrativo/cp-delibera-admin.module').then(
                                (m) => m.CpDeliberaAdminModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/common/partnership/partnership.module').then((m) => m.PartnershipModule),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/change-product/commerciale/products/cp-comm-products.module').then(
                                (m) => m.CpCommProductsModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/termination/termination.module').then((m) => m.TerminationModule),
                    },
                    {
                        path: '',
                        loadChildren: () => import('./modules/status/status.module').then((m) => m.StatusModule),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/domiciliation/domiciliation.module').then((m) => m.DomiciliationModule),
                    },
                    {
                        path: '',
                        loadChildren: () => import('./modules/morosity/morosity.module').then((m) => m.MorosityModule),
                    },
                    {
                        path: '',
                        loadChildren: () => import('./modules/transfer/transfer.module').then((m) => m.TransferModule),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/aggregation/aggregation.module').then((m) => m.AggregationModule),
                    },
                    /*  {
                        path: '',
                        loadChildren: () =>
                            import('./modules/common/summary/summary.module').then((m) => m.SummaryModule),
                    }, */
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/cart-costs/cart-costs.module').then((m) => m.CartCostsModule),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/change-supplytype/change-supplytype.module').then(
                                (m) => m.ChangeSupplytypeModule
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./modules/common/order-entry/steps/appointment/appointment-routing.module').then(
                                (m) => m.AppointmentRoutingModule
                            ),
                    },
                    {
                        path: '**', // Wildcard route for a 404 page
                        redirectTo: RoutesPaths.Dashboard,
                    },
                ],
            },
        ],
    },
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // useHash: environment.hashRouting,
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy',
            enableTracing: localStorage.getItem('enableAdvancedTrace') === 'true',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
