import { Pipe, PipeTransform } from '@angular/core';
import { AptProductType } from '../enums/apttus/apt-product-type';
import { QuoteDetailItem } from '../../modules/back-office/models/quotedetailresponse-response';

@Pipe({
    name: 'filterTechProducts',
    pure: false,
})
export class FilterTechProdutsPipe implements PipeTransform {
    transform(items: QuoteDetailItem[]): any {
        if (!items?.length) return items;
        return items.filter(
            (item) => ![AptProductType.FornituraGas, AptProductType.FornituraLuce].includes(item?.productType)
        );
    }
}
