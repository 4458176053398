import CodiceFiscale from 'codice-fiscale-js';
import * as moment from 'moment';
import { Gender } from '../models/user/contact';

/**
 * Ricalcola il checkDigit a partire da una PIVA di input.
 * Restituisce True/False se il checkDigit calcolato risultata
 * uguale a quello dell'PIVA di input
 * @param pIva
 */
export const isPivaValid = (pIva: string): boolean => {
    if (!pIva || pIva.length !== 11) {
        return false;
    }

    const validi = '0123456789';
    for (let i = 0; i < 11; i++) {
        if (validi.indexOf(pIva.charAt(i)) === -1) {
            return false;
        }
    }

    let s = 0;
    for (let i = 0; i <= 9; i += 2) {
        s += pIva.charCodeAt(i) - '0'.charCodeAt(0);
    }

    for (let i = 1; i <= 9; i += 2) {
        let c = 2 * (pIva.charCodeAt(i) - '0'.charCodeAt(0));
        if (c > 9) {
            c = c - 9;
        }
        s += c;
    }
    if ((10 - (s % 10)) % 10 !== pIva.charCodeAt(10) - '0'.charCodeAt(0)) {
        return false;
    }
    return true;
};

/**
 * Verifica di COERENZA tra dati inseriti e CF ed età consentita
 * @param params valori di validazione
 * @param fullCheck se TRUE check sull'interno CF, se FALSE solo i primi 6 caratteri
 * @return isValid: true se cf valido o { isValid: false; reason: ReasonDescription}
 */
export const isCfValid = (params: TaxCodeData, fullCheck: boolean): boolean => {
    let isValid = false;

    if (reverseCf(params.cfCode)) {
        // verifico che il reverse sia OK
        const isItalianCf = !isNoItalianCf(params.cfCode);
        const cfCalculated = calculateCF(params, fullCheck);
        if (cfCalculated) {
            if (isItalianCf && fullCheck) {
                isValid = params.cfCode.toUpperCase() === cfCalculated;
            } else {
                isValid = params.cfCode.slice(0, 6).toUpperCase() === cfCalculated.slice(0, 6);
            }
        }
    }
    return isValid;
};

/**
 * Restituisce True se il CF contine la lettera Z nell'undicesima posizione
 * @param codFisc CF da verificare
 */
export const isNoItalianCf = (codFisc: string): boolean => {
    if (codFisc && codFisc.length > 11) {
        return codFisc.charAt(11).toUpperCase() === 'Z';
    }
    return false;
};

/**
 * @description Calculates the age of user from fiscal code or given birth date. Moment takes in consideration days and months passed.
 * @param fiscalCode Codice fiscale
 * @param birthDate DD-MM-YYYY
 * @output The age in years
 */
export const calculateAge = (fiscalCode?: string, birthDate?: string): number => {
    if (!fiscalCode && !birthDate) return null;
    const today = moment();
    const birthday = fiscalCode && reverseCf(fiscalCode) ? moment(reverseCf(fiscalCode)?.birthday) : moment(birthDate);
    const age = today.diff(birthday, 'years');
    return age;
};

/**
 * @description Checks if the person's age is between the minimum and max age allowed.
 * @param age Age in number.
 * @param taxCode Codice fiscale
 * @param birthDate DD-MM-YYYY
 * @param minAge Minimum age allowed. If not defined, min age is 18.
 * @param maxAge Max age allowed. If not defined, max age is 120.
 * @returns True or false, either if its valid or not.
 */
export const isAgeValid = ({
    age,
    taxCode,
    birthDate,
    minAge = 18,
    maxAge = 120,
}: {
    age?: number;
    taxCode?: string;
    birthDate?: string;
    minAge?: number;
    maxAge?: number;
}): boolean => {
    const userAge = age ? age : calculateAge(taxCode || birthDate);
    return userAge >= minAge && userAge <= maxAge;
};

/**
 * Dato un CF di input restituisce un oggetto contenente i valori calcolati attraverso
 * il calcolo inverso del CF. Resituisce Null in caso di CF errato.
 * @param codFisc CF di input
 */

export const reverseCf = (codFisc: string): IOutputCodiceFiscaleObj => {
    try {
        return (((codFisc || '').trim().length === 16 && CodiceFiscale.computeInverse(codFisc.trim().toUpperCase())) ||
            null) as IOutputCodiceFiscaleObj;
    } catch (error) {
        // in caso di eccezione la libreria del reverse non è riuscita ad
        // effettuare il reverse del CF
        return null;
    }
};

const calculateCF = (inp: TaxCodeData, fullCheck: boolean): string => {
    try {
        const isItalianCf = !isNoItalianCf(inp.cfCode);
        if (inp.firstname && inp.lastname) {
            let birtDatas = {
                birtDay: 12,
                birtMonth: 7,
                birtYear: 1957,
                birthPlace: 'Napoli',
                gender: <Gender>'M',
            };

            if (isItalianCf && fullCheck) {
                const date = inp.birtDate;
                birtDatas = {
                    birtDay: date.getDate(),
                    birtMonth: date.getMonth() + 1,
                    birtYear: date.getFullYear(),
                    birthPlace: inp.birthPlace,
                    gender: inp.gender,
                };
            }

            const cfPayload: IInputCodiceFiscaleObj = {
                name: inp.firstname,
                surname: inp.lastname,
                gender: birtDatas.gender,
                day: birtDatas.birtDay,
                month: birtDatas.birtMonth,
                year: birtDatas.birtYear,
                birthplace: birtDatas.birthPlace,
            };

            if (isItalianCf && fullCheck) {
                cfPayload.birthplaceProvincia = inp.birthProvince;
            }

            return CodiceFiscale.compute(cfPayload);
        }
        return null;
    } catch (e) {
        return null;
    }
};
export interface TaxCodeData {
    cfCode: string;
    firstname: string;
    lastname: string;
    birtDate?: Date;
    birthPlace?: string;
    birthProvince?: string;
    gender?: Gender;
}

export interface IInputCodiceFiscaleObj {
    name: string;
    surname: string;
    gender: Gender;
    day: number;
    month: number;
    year: number;
    birthplace: string;
    birthplaceProvincia?: string; // Optional
}

interface IOutputCodiceFiscaleObj {
    name: string;
    surname: string;
    gender: Gender;
    day: number;
    month: number;
    year: number;
    birthplace: string; // in caso di estero restituisce la Nazione
    birthplaceProvincia: string; // in caso di estero restituisce EE
    cf?: string;
    birthday?: string;
}
