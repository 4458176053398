import { Injectable, Injector } from '@angular/core';
import { PrivateConfigurationService } from '../../../../../../common/services/shared/private-configuration.service';
import { BaseD365Service } from '../../../../../../common/services/d365/base-d365.service';
import { LoggerService } from '../../../../../../common/services/shared/logger.service';
import { Store } from '@ngrx/store';
import { EglState } from '../../../../../../store/reducers';
import { D365PostMessage } from '../../../../../../common/models/app/d365-postmessage';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Characteristic, ProductCharacteristic, ProductCharacteristicResponse } from '../models/product-characteristic';
import { Ese, EseResponse } from '../models/ese';
import {
    BookableResource,
    BookableResourceResponse,
    BookableResourceBookingResponse,
} from '../models/bookable-resource';
import { FieldServiceFamilyCode } from '../../../../../../common/enums/shared/field-service-family-code.enum';
import { statusCodeEnum } from '../../../../../../common/enums/shared/status-code.enum';
import { ProcessName } from '../../../../../../common/enums/shared/process-name-enum';
import { LoadingService } from '../../../../../../common/services/shared/loading.service';

@Injectable({ providedIn: 'root' })
export class ESEProvider extends BaseD365Service {
    constructor(
        logger: LoggerService,
        store: Store<EglState>,
        configSrv: PrivateConfigurationService,
        injector: Injector
    ) {
        super(logger, store, configSrv, injector);
    }

    getProductCharacteristics(sku: string[]): Observable<ProductCharacteristic[]> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { SKUList: JSON.stringify(sku) },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_product_retrievecharacteristics',
                    parameterTypes: {
                        SKUList: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<ProductCharacteristicResponse>(message).pipe(
            tap((res: ProductCharacteristicResponse) => {
                if (res?.Result !== 'OK') {
                    throw new Error(res?.ErrorMessage || this.genericApiError);
                }
            }),
            map((res: ProductCharacteristicResponse) => JSON.parse(res.ProductSkills) as ProductCharacteristic[]),
            catchError((error: Error) => {
                this.logger.error('Recupero caratteristiche manutenzione', error?.message, error);
                return of([]);
            })
        );
    }

    getEse(zipCode: string, city: string, skills: Characteristic[], processName: ProcessName): Observable<Ese> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    PostalCode: zipCode,
                    City: city,
                    Skills: JSON.stringify((skills || []).map((sk) => sk.Id)),
                    ProcessName: processName,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_agencybranch_getbyskillsaddress',
                    parameterTypes: {
                        PostalCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        City: { typeName: 'Edm.String', structuralProperty: 1 },
                        Skills: { typeName: 'Edm.String', structuralProperty: 1 },
                        ProcessName: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<EseResponse>(message).pipe(
            tap((res: EseResponse) => {
                if (res?.Result !== 'OK') {
                    throw new Error(res?.ErrorMessage || this.genericApiError);
                }
                if (!JSON.parse(res.AgencyBranches).length) {
                    throw new Error(
                        `Nessun ESE configurato per il CAP ${zipCode} e le skills ${(skills || [])
                            .map((sk) => sk.Name)
                            .join(',')}`
                    );
                }
            }),
            map((res: EseResponse) => JSON.parse(res.AgencyBranches)[0]),
            LoadingService.loaderOperator('Recupero ESE')
        );
    }

    /**
     * Returns the BookableResource available for technical appointment.
     *
     * @param FromDate: Date - Start date from which to search. TimeZone UTC
     * @param ToDate: Date - Start date from which to search. TimeZone UTC
     * @param Duration: number - Duration of the required slot
     * @param CharacteristicConstraint: Characteristic[] - list of id skills,
     * @param AgencyBranchID: string - ESE id,
     * @param PostalCode: string,
     * @param FieldServiceFamilyCode: FieldServiceFamilyCode - Process family id
     * @returns An observeble of BookableResource[]
     *
     */
    getBookableResources(
        FromDate: Date,
        ToDate: Date,
        Duration: number,
        CharacteristicConstraints: Characteristic[],
        AgencyBranchID: string,
        PostalCode: string,
        FieldServiceFamilyCode: FieldServiceFamilyCode
    ): Observable<BookableResource[]> {
        const request = {
            FromDate,
            ToDate,
            Duration,
            CharacteristicConstraint: CharacteristicConstraints?.map((sk) => sk?.Id),
            AgencyBranchID,
            PostalCode,
            FieldServiceFamilyCode,
        };
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { JsonData: JSON.stringify(request) },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_bookableresource_searchavailability',
                    parameterTypes: {
                        JsonData: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<BookableResourceResponse>(message).pipe(
            tap((res: BookableResourceResponse) => {
                if (res?.Result !== 'OK') {
                    throw new Error(res?.ErrorMessage || this.genericApiError);
                }
            }),
            map((res: BookableResourceResponse) => JSON.parse(res.AvailableSlots) || [])
        );
    }

    /**
     * The custom API has the function of creating and editing the Bookable resource.
     * @param FieldServiceFamilyCode: FieldServiceFamilyCode - Process family id
     * @param StartTime: Date - Start date of booking. TimeZone UTC
     * @param EndTime: Date - End date of booking. TimeZone UTC
     * @param BookableResourceId: string - Id of selected bookable resource(output of getBookableResources method)
     * @param WorkOrderNumber: string - work order of booking,
     * @param BookableResourceBookingId: string - booking id,
     * @param StatusCode: string - status of booking,
     * @returns Return the id of booking selected as an observeble of string.
     *
     */
    setBookableResources(
        FieldServiceFamilyCode: string,
        StartTime?: Date,
        EndTime?: Date,
        BookableResourceId?: string,
        WorkOrderNumber?: string,
        BookableResourceBookingId?: string,
        StatusCode?: statusCodeEnum
    ): Observable<string> {
        const request = {
            FieldServiceFamilyCode,
            StartTime,
            EndTime,
            BookableResourceId,
            WorkOrderNumber,
            BookableResourceBookingId,
            StatusCode,
        };
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { JsonData: JSON.stringify(request) },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_bookableresourcebooking_upsert',
                    parameterTypes: {
                        JsonData: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<BookableResourceBookingResponse>(message).pipe(
            tap((res: BookableResourceBookingResponse) => {
                if (res?.Result !== 'OK') {
                    throw new Error(res?.ErrorMessage || this.genericApiError);
                }
            }),
            map((res: BookableResourceBookingResponse) => res?.BookingId || '')
        );
    }
}
