import { catchError, concatMap, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import {
    setAgencyBranchForMonitoring,
    setAgentBranch,
    setNumeroPlico,
    setPowerConsumption,
    setQuoteStateModel,
} from '../../../../store/actions/order-entry.actions';
import { FlowType, MacroFlowType } from '../../../../store/models/flow-type';
import {
    v2SelectCurrentProduct,
    v2SelectFirma,
    v2SelectFlowType,
    v2SelectVisibleProducts,
} from '../../../../store/selectors/order-entry-v2.selectors';
import { selectFlowType } from '../../../../store/selectors/order-entry.selectors';
import {
    selectCartSegment,
    selectContactLead,
    selectCurrentVirtualAgent,
} from '../../../../store/selectors/user.selectors';
import { D365SignatureType } from '../../../enums/d365/d365-signature-type';
import { flowTypeToD365OperationMode, salesProcessOrFlowTypeToOperationType } from '../../../functions/remap.functions';
import { CreditCheckProvider, ScoreCardCheckTypes } from '../../../providers/credit-check.provider';
import { CartToQuoteService } from '../../apttus/cart-to-quote/cart-to-quote.service';
import { D365Service } from '../../d365/d365.service';
import { UtilityService } from '../utility.service';
import { RailwayFn, RailwayFnObs } from './dragon-router.type';
import { combineLatest, of } from 'rxjs';
import { EglAddProductToCartService } from '../../apttus/utility/egl-add-product-to-cart.service';
import { CommonProvider } from '../../../providers/common-provider';
import { AptQuoteStatus } from '../../../enums/apttus/apt-quote-status';
import { AptQuoteSubStatus } from '../../../enums/apttus/apt-quote-sub-status';
import { CartService, Contact, LineItemService } from '@congacommerce/ecommerce';
import { EglCartItemLight } from '../../../models/apttus/tables/cart/egl-cart-item-light';
import { EglItemGroup } from '../../../functions/transformation.functions';
import { OrderEntryProvider } from '../../../../modules/common/order-entry/providers/order-entry-provider';
import { HistorizedAgencyBranch } from '../../../models/user/historized-agency-branch';
import {
    containsProductMaintenance,
    flowTypeUtil,
    isProductAppurtenance,
} from '../../../functions/verifications.functions';
import { AptCommodityType } from '../../../enums/apttus/apt-commodity-typeof-sale';
import { PowerConsumption } from '../../../../store/models/order-entry-state';
import { D365CustomerSegment } from '../../../enums/d365/d365-customer-segment';
import {
    setV2EffectiveDate,
    setV2ProductFilled,
    setV2RemoteReadingStatus,
    setV2TechnicalDetailsGas,
} from '../../../../store/actions/order-entry-v2.actions';
import { skipAppurtenances } from './dragon-router-skipping-rules';
import { AptPaymentInstrument } from '../../../enums/apttus/apt-payment-instrument';
import { GasConsumptionUse } from '../../../enums/shared/type-of-usage.type';
import { DEFAULT_GAS_POTENTIAL } from '../../../../modules/common/default.values';
import { GasTechnicalDetails } from '../../../../store/models/order-entry-state_v2';
import { AptSaleabilityOperationType } from '../../../enums/apttus/apt-sales-process';
import { PrivateConfigurationService } from '../private-configuration.service';
import { setContact, setLead } from '../../../../store/actions/user.actions';

export const dragonIsCustomerRegistered: RailwayFnObs = ({ injector }) =>
    injector
        .get(CommonProvider)
        .isCustomerRegistered()
        .pipe(map(() => null));

export const dragonSaveQuoteV2: RailwayFnObs = ({ injector }) =>
    injector
        .get(EglAddProductToCartService)
        .eglAddMultiProductToCart()
        .pipe(
            mergeMap(() => injector.get(CartToQuoteService).saveQuoteV2()),
            map((quote) => ({
                qId: quote.Id,
                qName: quote.Name,
                qAccountId: quote.accountId,
            }))
        );

export const dragonSaveQuoteLavoriVerificheV2: RailwayFnObs = ({ injector, params: { workOrderId, gtwDossierCode } }) =>
    injector
        .get(CartToQuoteService)
        .saveQuoteV2()
        .pipe(
            map((quote) => ({
                qId: quote.Id,
                qName: quote.Name,
                workOrderId,
                gtwDossierCode,
            }))
        );

export const dragonSaveQuotePreventivo: RailwayFnObs = ({
    injector,
    params: { cartToQuotetype, workOrderId, gtwDossierCode },
    eglState,
}) =>
    dragonSaveQuoteV2({ injector, params: { cartToQuotetype }, eglState }).pipe(
        mergeMap(({ qId, qName }) =>
            injector
                .get(D365Service)
                .saveQuoteIdInd365(workOrderId, qId, qName, gtwDossierCode)
                .pipe(
                    map(() => ({
                        qId,
                        qName,
                        workOrderId,
                        gtwDossierCode,
                    }))
                )
        )
    );

export const dragonGetContractCodeNoSign: RailwayFnObs = ({ injector, eglState }) =>
    combineLatest([eglState.select(selectCurrentVirtualAgent), eglState.select(selectFlowType)]).pipe(
        mergeMap(([virtualAgent, flowType]) =>
            injector
                .get(D365Service)
                .getCodicePlico$(
                    virtualAgent.VirtualAgency.Channel.Name,
                    D365SignatureType.NessunaFirma,
                    flowTypeToD365OperationMode(flowType)
                )
                .pipe(
                    tap((codicePlico) =>
                        eglState.dispatch(
                            setNumeroPlico({
                                payload: {
                                    code: codicePlico?.ContractCode,
                                    channelName: virtualAgent.VirtualAgency?.Channel?.Name,
                                    signatureType: D365SignatureType.NessunaFirma,
                                },
                            })
                        )
                    )
                )
        ),
        map(() => null)
    );

export const dragonGetContractCodeNoSignAndAgencyBranch: RailwayFnObs = ({ injector, eglState }) =>
    combineLatest([
        dragonGetContractCodeNoSign({ injector, eglState }),
        eglState.select(selectCurrentVirtualAgent).pipe(
            take(1),
            tap((currentVirtualAgent) => {
                eglState.dispatch(setAgencyBranchForMonitoring({ a: currentVirtualAgent?.CurrentAgencyBranch?.Name }));
            }),
            mergeMap((currentVirtualAgent) =>
                injector.get(D365Service).getAgencyBranch(currentVirtualAgent, new Date().toISOString())
            ),
            tap(({ Result }) => {
                if (Result !== 'OK') {
                    of(null);
                }
            }),
            map(({ AgencyBranch }) => JSON.parse(AgencyBranch) as HistorizedAgencyBranch),
            tap((agencyBranch) => {
                eglState.dispatch(setAgentBranch({ h: agencyBranch }));
            }),
            catchError((err: Error) => {
                return of(null);
            }),
            tap(() => {}),
            map(() => null)
        ),
    ]);

export const setQuoteStatus: RailwayFn = ({ injector, params: { quoteStatus, quoteSubStatus }, eglState }) => {
    eglState.dispatch(
        setQuoteStateModel({
            s: {
                ...(quoteStatus && { status: quoteStatus }),
                ...(quoteSubStatus && { subStatus: quoteSubStatus }),
            },
        })
    );
    return null;
};

export const dragonSetQuoteStatus: (quoteStatus: AptQuoteStatus, quoteSubStatus?: AptQuoteSubStatus) => RailwayFn =
    (quoteStatus, quoteSubStatus) =>
    ({ params, ...args }) =>
        setQuoteStatus({ ...args, params: { quoteStatus, quoteSubStatus, ...(params || {}) } });

export const dragonSetQuoteStatusSubstatus: RailwayFn = ({ injector, eglState }) =>
    combineLatest([
        eglState.select(v2SelectFlowType),
        eglState.select(v2SelectFirma),
        eglState.select(v2SelectVisibleProducts()),
    ]).pipe(
        map(([flowType, signature, products]) => {
            const PAYMENT_TOOL_STATEMODEL_MAP: {
                [key in AptPaymentInstrument | 'DEFAULT']?: () => {
                    status: AptQuoteStatus;
                    subStatus: AptQuoteSubStatus;
                } | null;
            } = {
                [AptPaymentInstrument.CartaCredito]: () => {
                    return !products[0].paymentInfo?.paymentTool?.id
                        ? {
                              status: AptQuoteStatus.Sospeso,
                              subStatus: AptQuoteSubStatus.ConfermaCartaCredito,
                          }
                        : null;
                },
                DEFAULT: () => null,
            };

            const SIGNATURE_STATEMODEL_MAP: {
                [key in D365SignatureType]?: {
                    status: AptQuoteStatus;
                    subStatus: AptQuoteSubStatus | null;
                };
            } = {
                [D365SignatureType.Doi]: {
                    status: AptQuoteStatus.InAttesaFirma,
                    subStatus: AptQuoteSubStatus.FirmaDoi,
                },
                [D365SignatureType.DoiLight]: {
                    status: AptQuoteStatus.InAttesaFirma,
                    subStatus: AptQuoteSubStatus.FirmaDoi,
                },
                [D365SignatureType.Grafometrica]: {
                    status: AptQuoteStatus.InAttesaFirma,
                    subStatus: AptQuoteSubStatus.FirmaGrafometrica,
                },
                [D365SignatureType.PaperPrintedBySystem]: {
                    status: AptQuoteStatus.InAttesaFirma,
                    subStatus: AptQuoteSubStatus.FirmaSemidigital,
                },
                [D365SignatureType.Cartacea]: {
                    status: AptQuoteStatus.Firmato,
                    subStatus: null,
                },
                [D365SignatureType.VocalOrder]: {
                    status: AptQuoteStatus.Firmato,
                    subStatus: null,
                },
                [D365SignatureType.NessunaFirma]: {
                    status: AptQuoteStatus.Firmato,
                    subStatus: null,
                },
            };

            const DOMICILIATION_STATEMODEL_MAP: {
                [key in FlowType]?: {
                    status: AptQuoteStatus;
                };
            } = {
                [FlowType.DomiciliazioneAttivazione]: { status: AptQuoteStatus.Firmato },
                [FlowType.DomiciliazioneModifica]: { status: AptQuoteStatus.Firmato },
                [FlowType.DomiciliazioneRevoca]: { status: AptQuoteStatus.Firmato },
            };

            const quoteStateModel =
                (
                    PAYMENT_TOOL_STATEMODEL_MAP[products[0]?.paymentInfo?.paymentInstrument] ||
                    PAYMENT_TOOL_STATEMODEL_MAP.DEFAULT
                )() ||
                SIGNATURE_STATEMODEL_MAP[signature?.d365SignatureType] ||
                DOMICILIATION_STATEMODEL_MAP[flowType];
            eglState.dispatch(setQuoteStateModel({ s: quoteStateModel }));

            return null;
        })
    );

export const scoreCard: RailwayFn = ({ injector }) =>
    injector.get(CreditCheckProvider).orderEntryScoreCard([ScoreCardCheckTypes.ScoreCard]);

export const PROCESS_STATE_ENRICHER: {
    [key in MacroFlowType]?: RailwayFn;
} = {
    [MacroFlowType.VolturaSwitchIn]: ({ injector, params, eglState }) => {
        eglState.dispatch(
            setV2EffectiveDate({
                effectiveDate: injector.get(UtilityService).addMomentBusinessDays(new Date(), 2).toDate(),
                immediateEffect: true,
            })
        );
        return params;
    },
};

export const multiSupplyProductFinalizer: RailwayFn = ({ eglState, params }) => {
    eglState.dispatch(setV2ProductFilled({ productIdx: params?.itemId, isFilled: true }));
    return {
        ...(params || {}),
        itemId: null,
    };
};

export const multiSupplySingleProductInitializer: RailwayFn = ({ eglState, params }) =>
    eglState.select(v2SelectVisibleProducts('ALL')).pipe(
        map((products) => products?.length === 1),
        map((singleProduct) =>
            singleProduct
                ? {
                      ...(params || {}),
                      itemId: 0,
                  }
                : params
        )
    );

export const maintenanceAppointmentCycleInitializer: RailwayFn = ({ eglState, params }) =>
    eglState.select(v2SelectVisibleProducts('ALL')).pipe(
        take(1),
        map((products) => products.filter((p) => containsProductMaintenance(p.productType)).map((p) => p.idx)[0]),
        map((itemId) => ({
            itemId: itemId || 0,
        }))
    );

export const dispatchDefaultConsumptionForAppurtenancesCommodity: RailwayFnObs = ({ injector, eglState, params }) =>
    combineLatest([
        eglState.select(selectFlowType),
        eglState.select(v2SelectCurrentProduct(params?.itemId)),
        eglState.select(selectCartSegment),
        skipAppurtenances({ eglState, params }),
    ]).pipe(
        map(([flowType, product, cartSegment]) => {
            if (
                !product ||
                cartSegment === D365CustomerSegment.Microbusiness ||
                !flowTypeUtil(flowType).inMacroFlowTypes(
                    MacroFlowType.SwitchIn,
                    MacroFlowType.Attivazione,
                    MacroFlowType.AttivazioneComplessa,
                    MacroFlowType.Voltura,
                    MacroFlowType.VolturaSwitchIn
                )
            ) {
                return of(null);
            }

            if (isProductAppurtenance(product) && cartSegment === D365CustomerSegment.Residential) {
                const powerConsumption = new PowerConsumption(1200, 1200);
                eglState.dispatch(setPowerConsumption({ c: powerConsumption }));
            }
        })
    );

export const productConfigStepInitializer: RailwayFn = ({ params, injector }) => {
    return injector
        .get(CartService)
        .getMyCart()
        .pipe(
            map((cart) => LineItemService.groupItems(cart.LineItems)?.[0] as unknown),
            map((groupped: EglItemGroup<EglCartItemLight>) =>
                groupped
                    ? {
                          cartItem: groupped.MainLine?.Id,
                          id: groupped.MainLine?.Product?.Id,
                      }
                    : params
            )
        );
};

export const remoteMeterCheck: RailwayFn = ({ eglState, injector }) => {
    return eglState.select(v2SelectCurrentProduct()).pipe(
        take(1),
        mergeMap((product) =>
            product?.powerOrGas === AptCommodityType.Gas
                ? injector
                      .get(OrderEntryProvider)
                      .getRemoteMeterCheck(product?.podPdr)
                      .pipe(
                          tap((readingStatus) => eglState.dispatch(setV2RemoteReadingStatus({ status: readingStatus })))
                      )
                : of(null)
        ),
        map(() => true)
    );
};

export const dispatchDefaultPotentialityForActivationGasCommodity: RailwayFnObs = ({ injector, eglState, params }) =>
    combineLatest([
        eglState.select(selectFlowType),
        eglState.select(v2SelectCurrentProduct(params?.itemId)),
        eglState.select(selectCartSegment),
    ]).pipe(
        map(([flowType, product, cartSegment]) => {
            const canGetDefaultPotential = injector
                .get(PrivateConfigurationService)
                .get('callActivationGasPotencyDefault', false);

            if (!canGetDefaultPotential) {
                return of(null);
            }

            const isActivation =
                salesProcessOrFlowTypeToOperationType(flowType) === AptSaleabilityOperationType.Attivazione;

            if (
                !product ||
                cartSegment !== D365CustomerSegment.Residential ||
                product?.powerOrGas !== AptCommodityType.Gas ||
                !isActivation
            ) {
                return of(null);
            }

            var technicalDetails = product?.technicalDetails;
            var typeOfUsage = (technicalDetails?.typeOfUsage as GasConsumptionUse[]) || [];

            if (technicalDetails?.gasPotentialFromCheckPdr) {
                return of(null);
            }

            if (!typeOfUsage || typeOfUsage?.length === 0) {
                return of(null);
            }

            return injector
                .get(OrderEntryProvider)
                .checkActivationGasDefaultPotential(product.lineItemId, typeOfUsage.join('|'))
                .pipe(tap((res) => res))
                .pipe(take(1))
                .subscribe((res) => {
                    var techicalDetailsToDispatch = { ...technicalDetails } as GasTechnicalDetails;

                    if (techicalDetailsToDispatch?.gasPotentialFromCheckPdr) {
                        return of(null);
                    }

                    if (res) {
                        if (!res.ErrorMessage) {
                            techicalDetailsToDispatch.gasPotential = res?.PotentialConsumption || null;
                        }
                    }

                    techicalDetailsToDispatch.gasPotential =
                        techicalDetailsToDispatch.gasPotential || DEFAULT_GAS_POTENTIAL;

                    eglState.dispatch(
                        setV2TechnicalDetailsGas({
                            technicalDetails: techicalDetailsToDispatch,
                        })
                    );
                });
        })
    );

export const CheckPortalRegistrationEmail: RailwayFn = ({ eglState, injector }) => {
    return eglState.select(selectContactLead).pipe(
        mergeMap((contactLead) => {
            if (!contactLead.contact && !contactLead.lead) {
                return of(null);
            }

            let lead = contactLead.lead;
            let contact = contactLead.contact;

            const checkUniqueRegistrationEmailOrderEntry = injector
                .get(PrivateConfigurationService)
                .get('checkUniqueRegistrationEmailOrderEntry', false);

            if (!checkUniqueRegistrationEmailOrderEntry) {
                return of(null);
            }

            const taxOrVatCodeToCheck =
                contact.egl_vatcode || contact.egl_taxcode || lead.egl_vatcode || lead.egl_taxcode;

            return injector
                .get(CommonProvider)
                .checkUniqueRegistrationEmail(taxOrVatCodeToCheck)
                .pipe(
                    mergeMap((email) => {
                        const retrievedEmail = email;

                        if (lead) {
                            lead.emailaddress1 = retrievedEmail || lead.emailaddress1;
                            lead.isEmailFromPortal = !!retrievedEmail;
                            eglState.dispatch(setLead({ l: lead }));
                        }

                        if (contact) {
                            contact.emailaddress1 = retrievedEmail || contact.emailaddress1;
                            contact.isEmailFromPortal = !!retrievedEmail;
                            eglState.dispatch(setContact({ c: contact }));
                        }

                        return of(null);
                    })
                );
        })
    );
};

export const CheckPortalRegistrationEmail2: RailwayFn = ({ eglState, injector }) =>
    eglState.select(selectContactLead).pipe(
        map((contactLead) => {
            if (!contactLead.contact && !contactLead.lead) {
                return of(null);
            }

            let lead = contactLead.lead;
            let contact = contactLead.contact;

            const checkUniqueRegistrationEmailOrderEntry = injector
                .get(PrivateConfigurationService)
                .get('checkUniqueRegistrationEmailOrderEntry', false);

            if (!checkUniqueRegistrationEmailOrderEntry) {
                return of(null);
            }

            const taxOrVatCodeToCheck =
                contact.egl_vatcode || contact.egl_taxcode || lead.egl_vatcode || lead.egl_taxcode;

            injector
                .get(CommonProvider)
                .checkUniqueRegistrationEmail(taxOrVatCodeToCheck)
                .subscribe((email) => {
                    const retrievedEmail = email;

                    if (lead) {
                        lead.emailaddress1 = retrievedEmail || lead.emailaddress1;
                        lead.isEmailFromPortal = !!retrievedEmail;
                        eglState.dispatch(setLead({ l: lead }));
                    }

                    if (contact) {
                        contact.emailaddress1 = retrievedEmail || contact.emailaddress1;
                        contact.isEmailFromPortal = !!retrievedEmail;
                        eglState.dispatch(setContact({ c: contact }));
                    }

                    return of(null);
                });
        })
    );
