export enum D365OperationMode {
    SwitchIn = 100000000,
    CambioProdotto = 100000001,
    CambioProdottoDelibera = 100000002,
    CambioProdottoRetroattivo = 100000003,
    CambioProdottoRetroattivoExNovo = 100000010,
    CambioProdottoRetroattivoAssetCessato = 100000011,
    Domiciliazione = 100000004,
    Cessazione = 100000005,
    CessazioneAmministrativa = 100000020,
    SwitchInConVoltura = 100000006,
    VolturaCambioFornitoreVip = 100000200,
    VolturaCambioFornitoreRecupero = 100000201,
    SwitchInAmmVip = 100000007,
    SwitchInAmmRecupero = 100000008,
    SwitchInAmmRipensamento = 100000009,
    Attivazione = 100000100,
    ProvisioningManuale = 100000101,
    AttivazioneAmmVIP = 100000102,
    AttivazioneAmmRecupero = 100000103,
    // ATTIVAZIONE COMPLESSA
    AttivazioneA01 = 100000106, // ATTIVAZIONE CON ISTANZA
    AttivazioneN02 = 100000107, // ALLACCIO CON POSA E ATTIVAZIONE
    AttivazioneA40 = 100000104, // ATTIVAZIONE CON ACCERTAMENTO
    AttivazionePN1 = 100000105, // PREVENTIVO E ATTIVAZIONE

    // ATTIVAZIONE COMPLESSA AMMINISTRATIVA
    AttivazioneAmmA01 = 100000110, // ATTIVAZIONE CON ISTANZA AMM
    AttivazioneAmmN02 = 100000111, // ALLACCIO CON POSA E ATTIVAZIONE AMM
    AttivazioneAmmA40 = 100000108, // ATTIVAZIONE CON ACCERTAMENTO AMM
    AttivazioneAmmPN1 = 100000109, // PREVENTIVO E ATTIVAZIONE AMM
    // ATTIVAZIONE COMPLESSA VIP
    AttivazioneVipA01 = 100000114, // ATTIVAZIONE CON ISTANZA VIP
    AttivazioneVipN02 = 100000115, // ALLACCIO CON POSA E ATTIVAZIONE VIP
    AttivazioneVipA40 = 100000112, // ATTIVAZIONE CON ACCERTAMENTO VIP
    AttivazioneVipPN1 = 100000113, // PREVENTIVO E ATTIVAZIONE VIP

    //SCONTI
    ScontoStandalone = 100000900,

    // VOLTURA
    Voltura = 100000300,
    VolturaAmmVip = 100000301,
    VolturaAmmSiProvisioning = 100000302,
    VolturaAmmNoProvisioning = 100000303,
    VolturaAmmIncorporazioneSocietaria = 100000304,
    // VT DROP3
    VariazioneAteco = 100000021,
    VariazioneTipologiaFornituraGas = 100000022,
    VariazioneIndirizzo = 100000023,
    AllineaTipologiaUtilizzoDistributore = 100000024,
    CambioTipologiaFornitura = 100000901,
    Aggrega = 100000400,
    Disaggrega = 100000401,

    // EXTRACOMMODITY
    Extracommodity = 100001100,
    CessazioneExtracommodity = 100001101,
}
