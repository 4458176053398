import { AptCommodityType } from './../enums/apttus/apt-commodity-typeof-sale';
import * as moment from 'moment';
import { TitolareConto } from '../../modules/common/order-entry/models/form-bonifico';
import { AptPartnerships } from '../../modules/common/partnership/enums/apt-partnerships';
import { Indirizzi, Indirizzo } from '../../modules/switch-in/order-entry/models/indirizzi';
import {
    CostRecord,
    FornituraEsistente,
    OrderEntryState,
    CommodityEstateCardinality,
    ProductInfo,
    TermContacts,
    TermCosts,
    DatiAnagraficiMB,
} from '../../store/models/order-entry-state';
import {
    Address,
    BusinessDetails,
    CompanyIdentity,
    MeterAppointment,
    OrderEntryState_v2,
    PaymentInfo,
    Product,
    ProductConfigurations,
    ProductPriceInfo,
    TechnicalDetails,
} from '../../store/models/order-entry-state_v2';
import { AptAddressType } from '../enums/apttus/apt-address-type';
import { AptBillType } from '../enums/apttus/apt-bill-type';
import { AptPowerVoltage } from '../enums/apttus/apt-power-voltage';
import { AptProductStatus } from '../enums/apttus/apt-product-status';
import { AptProductFamily } from '../enums/apttus/apt-product-family';
import { GasMeterClass } from '../enums/shared/gas-meter-class';
import { DeepPartial } from '../interfaces/deep-partial';
import { cleanObj, getAvailablePower, parseToDate } from './misc.functions';
import { getCivicAndSuffix, getNumberOrNull, winBackTypeToAptCommodityTypes } from './remap.functions';
import { aptProductTypeToAptCommodityType, getValidDestinationUse } from './transformation.functions';
import { AptPaymentType } from '../enums/apttus/apt-payment-type';
import { indirizzo2Address } from './address.functions';
import { userStateSelector } from '../../store/selectors/user.selectors';
import { D365CustomerSegment } from '../enums/d365/d365-customer-segment';
import { isEmpty, merge } from 'lodash';
import { PaymentAsset } from '../models/app/payment-tools.response';
import { AptPaymentInstrument } from '../enums/apttus/apt-payment-instrument';
import { isAddress, isAddressEmpty, isCommodityFamily, isIndirizzo, isValidIndirizzo } from './verifications.functions';

export function orderEntryV1ToV2(dataV1: OrderEntryState): OrderEntryState_v2 {
    const termAppointment = dataV1?.termination?.termAppointment;
    const actiAppointment = dataV1?.activation?.actiAppointment;
    const products: Product[] = domiciliationToProducts(dataV1) || (orderEntryV1ToV2Products(dataV1) as Product[]);
    const { companyAddress, ...anagraficaMb } = dataV1?.anagraficaMb || ({} as DatiAnagraficiMB);

    const userState = userStateSelector.projector();
    const isMicrobusiness = userState?.cartSegment === D365CustomerSegment.Microbusiness;

    const stateV2: OrderEntryState_v2 = {
        version: 2,
        flowType: dataV1?.flowType,
        products,
        cartId: dataV1?.cartInfo?.cartId,
        dagCode: dataV1?.cartInfo?.ownerDagCode,
        appointment: {
            timeslot: termAppointment?.timeSlot || actiAppointment?.timeSlot,
            firstName: termAppointment?.altContactFName || actiAppointment?.altContactFName,
            lastName: termAppointment?.altContactLName || actiAppointment?.altContactLName,
            prefix: termAppointment?.altContactPrefix || actiAppointment?.altContactPrefix,
            phone: termAppointment?.altContactPhone || actiAppointment?.altContactPhone,
            interphone: {
                available: termAppointment?.interphoneAvailable || actiAppointment?.interphoneAvailable,
                notes: termAppointment?.interphoneNotes || actiAppointment?.interphoneNotes,
            },
        },
        contact: {
            mainAddress:
                dataV1?.indirizzi?.stessoIndirizzoResidenza || isValidIndirizzo(dataV1?.indirizzi?.indirizzoResidenza)
                    ? indirizzo2Address(
                          dataV1?.indirizzi?.stessoIndirizzoResidenza && !isMicrobusiness
                              ? dataV1?.indirizzi?.indirizzoFornitura
                              : isMicrobusiness
                              ? companyAddress
                              : dataV1?.indirizzi?.indirizzoResidenza
                      )
                    : null,
            privacyTrattDatiPers: dataV1?.privacyTrattDatiPers,
            phoneContactStatus: contactToStatus((dataV1?.termination?.termContacts || [])[0]),
            mailContactStatus: contactToStatus((dataV1?.termination?.termContacts || [])[1]),
            emailConfirmed: false,
        },
        fotoDocumenti: dataV1?.fotoDocumenti
            ? {
                  ...dataV1?.fotoDocumenti,
                  rilasciatoIl: parseToDate(dataV1?.fotoDocumenti?.rilasciatoIl),
              }
            : null,
        firma: dataV1?.firma,
        mp3Info: dataV1?.mp3Info,
        uploadPdfPlicoInfo: dataV1?.uploadPdfPlicoInfo,
        numeroPlico: dataV1?.numeroPlico,
        tipoEsecuzione: {
            dataAnticipataCommodity: dataV1?.tipoEsecuzione?.dataAnticipataCommodity,
            dataStandardCommodity: dataV1?.tipoEsecuzione?.dataStandardCommodity,
            isRataFissaCP: dataV1?.tipoEsecuzione?.isRataFissaCP,
            isRataFissaDatesEquals: dataV1?.tipoEsecuzione?.isRataFissaDatesEquals,
        },
        fornitureEsistenti: dataV1?.fornitureAttive,
        quoteStateModel: dataV1?.quoteStateModel,
        verificaContatto: dataV1?.verificaContatto,
        agencyBranchForMonitoring: dataV1?.agencyBranchForMonitoring,
        branchAgenziaAgente: dataV1?.branchAgenziaAgente,
        plicoUrl: dataV1?.plicoUrl,
        anagraficaMb, // da spostare in user state
        ocrData: dataV1?.ocrData,
        fatturazioneElettronica: dataV1?.fatturazioneElettronica,
        invalidCf: dataV1?.invalidCf,
        creditCheckStatus: dataV1?.creditCheckStatus,
        deferredSaleEnabled: dataV1?.deferredSaleEnabled,
        responsabilitaCreditCheckModal: dataV1?.responsabilitaCreditCheckModal,
        selfCertification: dataV1?.selfCertification,
        incident: dataV1?.incident,
        ivassAnswers: dataV1?.ivassAnswers,
        insuranceDummyProductId: dataV1.insuranceDummyProductId,

        linkedCommodity: {
            cartId: dataV1?.commodityCartId,
            quoteId: dataV1?.quoteId,
            enabled: dataV1?.combinedSale,
        },
    };
    console.log('[STATE] orderEntryV1ToV2', stateV2);
    return stateV2;
}

function contactToStatus(contact: TermContacts): 'Confirmed' | 'Updated' {
    return contact?.confirmContact ? 'Confirmed' : contact?.changedContact ? 'Updated' : null;
}

function fornituraEsistente2Product(commodity: FornituraEsistente): DeepPartial<Product> {
    const istatCode = (commodity?.codiceIstat || '').padStart(6, ' ');
    const provinceIstatCode = (istatCode || '').substring(0, 3).trim();
    const cityIstatCode = (istatCode || '').substring(3).trim();

    return cleanObj<DeepPartial<Product>>({
        assetIntegrationId: commodity?.assetIntegrationId,
        powerOrGas: commodity?.powerOrGas,
        podPdr: commodity?.podPdr,
        pdf: commodity?.pdf,
        startDate: moment(commodity?.datiInizio).utcOffset(0, true).toDate(),
        isAssetActive: /^\s*(?:attiv[ao]|active)\s*$/i.test(commodity?.stato),
        businessDetails: {
            ateco: commodity?.atecoCode,
        },
        configurations: {
            homeTaxRelief: commodity?.isResidente
                ? CommodityEstateCardinality.First
                : CommodityEstateCardinality.Second,
        },
        deliveryAddress: {
            toponym: commodity?.toponimo,
            province: commodity?.provincia,
            cap: commodity?.cap,
            ...getCivicAndSuffix(commodity?.civico),
            street: commodity?.via,
            municipality: commodity?.citta,
            region: commodity?.region,
            istatCodeProv: provinceIstatCode ? provinceIstatCode.padStart(3, '0') : null,
            istatCodeMunicipality: cityIstatCode ? cityIstatCode.padStart(3, '0') : null,
            fullAddress: commodity?.indirizzoCompleto,
            certified: false,
        },
    });
}

const FAMILY_ADDRESS_TYPE_MAP = {
    [AptAddressType.Fornitura]: [
        AptProductFamily.Commodity,
        AptProductFamily.CommodityLegacy,
        AptProductFamily.ServizioTecnico,
        AptProductFamily.VariazioneTecnica,
        AptProductFamily.Assicurazione,
        AptProductFamily.Options,
    ],
    [AptAddressType.Spedizione]: [AptProductFamily.BeniFisici],
};

function familyToAddressType(family: AptProductFamily): AptAddressType {
    return (Object.entries(FAMILY_ADDRESS_TYPE_MAP).find(([, families]) => families.includes(family)) ||
        [])[0] as AptAddressType;
}

export function productInfo2Product(product: ProductInfo): DeepPartial<Product> {
    const BILL_TYPE_MAP = {
        'Bolletta digitale': AptBillType.Cartacea,
        'Bolletta cartacea': AptBillType.Digitale,
    };

    return cleanObj<DeepPartial<Product>>({
        productId: product?.id,
        codeOrSku: product?.code || product?.sku || product?.uniqueProductCode,
        name: product?.nome,
        productType: product?.productType,
        family: product?.family,
        addressType: familyToAddressType(product?.family),
        isCombinedSale: product?.configuration?.isCombinedSaleInsurance,
        supplyCode: product?.supplyCode,
        configurations: {
            greenOption: (product?.optionsConfig || []).some((config) => /Opzione\s+verde\s*:\s*s[ìi]/i.test(config)),
            invoiceShippingMethod:
                product?.configuration?.billType ||
                BILL_TYPE_MAP[(product?.optionsConfig || []).find((opCfg) => BILL_TYPE_MAP[opCfg])],
            relatedOrderNumber: product?.configuration?.orderNumber,
            partnership: cleanObj({
                selected: ((product?.partnership || '').trim().toUpperCase() as AptPartnerships) || null,
            }),
            installmentDuration: product?.configuration?.installmentDuration,
            billCharge: product?.configuration?.billCharge,
            vulnerabilityOver75: product?.configuration?.vulnerabilityOver75,
            vulnerabilitySocialBonus: product?.configuration?.vulnerabilitySocialBonus,
            vulnerabilitySaeMapre: product?.configuration?.vulnerabilitySaeMapre,
            vulnerabilityDisabled: product?.configuration?.vulnerabilityDisabled,
        },
        paymentInfo: {
            paymentInstrument: product?.configuration?.paymentInstrument,
            paymentFrequency: product?.configuration?.paymentFrequency,
            paymentType: Object.values(AptPaymentType).includes(product?.configuration?.paymentType)
                ? product?.configuration?.paymentType
                : null,
        },
        powerOrGas: product?.powerOrGas || aptProductTypeToAptCommodityType(product?.productType),
        lineItemId: product?.lineItemId,
        lineItemStatus: product?.status,
        discountsPromo: product?.discountsPromo,
        privacyRequired: product?.privacyRequired,
        podPdr: product?.podPdr,
        deliveryAddress: product?.supplyAddress
            ? {
                  fullAddress: product?.supplyAddress,
                  certified: false,
              }
            : undefined,
        productStatus: product?.isAtNew ? AptProductStatus.Temporary : null,
    });
}

export function indirizziToProductAddresses(
    indirizzi: Indirizzi,
    sedeLegale: Indirizzo | Address,
    addressType?: AptAddressType
): {
    // companyAddress: Address;
    supplyAddress: Address;
    shipmentAddress: Address;
    deliveryAddress: Address;
    communicationAddress: Address;
    mainAddress: Address | false;
} {
    const companyAddress = isIndirizzo(sedeLegale)
        ? indirizzo2Address(sedeLegale)
        : isAddress(sedeLegale)
        ? sedeLegale
        : null;

    const supplyAddress =
        indirizzi?.isSedeLegale && !!companyAddress
            ? companyAddress
            : indirizzi?.indirizzoFornitura?.via
            ? indirizzo2Address(indirizzi?.indirizzoFornitura)
            : null;
    const shipmentAddress =
        !indirizzi?.stessoIndirizzoSpedizione && indirizzi?.indirizzoSpedizione?.via
            ? indirizzo2Address(indirizzi?.indirizzoSpedizione)
            : null;
    const deliveryAddress =
        addressType === AptAddressType.Spedizione ? shipmentAddress || supplyAddress : supplyAddress || shipmentAddress;
    const communicationAddress = !indirizzi?.stessoIndirizzoComunicazioni
        ? indirizzi?.indirizzoComunicazioni?.via
            ? indirizzo2Address(indirizzi?.indirizzoComunicazioni)
            : null
        : deliveryAddress;

    const residentialAddress = indirizzi?.stessoIndirizzoResidenza
        ? deliveryAddress
        : indirizzo2Address(indirizzi?.indirizzoResidenza);

    const mainAddress =
        companyAddress ||
        (isAddressEmpty(residentialAddress) && indirizzi?.stessoIndirizzoResidenza === false
            ? false
            : residentialAddress);

    return {
        supplyAddress,
        shipmentAddress,
        deliveryAddress,
        communicationAddress,
        mainAddress,
    };
}

export function termCostsToProductPriceMap(termCosts: TermCosts[]): { [lineItemId: string]: ProductPriceInfo } {
    return (termCosts || []).reduce(
        (aggr, { idAsset, priceOPS, priceSDR }) => ({
            ...aggr,
            [idAsset]: {
                ops: getNumberOrNull(priceOPS),
                sdr: getNumberOrNull(priceSDR),
            },
        }),
        {} as { [lineItemId: string]: ProductPriceInfo }
    );
}
export function costRecordToProductPriceMap(costRecords: CostRecord[]): { [lineItemId: string]: ProductPriceInfo } {
    return (costRecords || []).reduce(
        (aggr, { id, eniCosts, otherCosts }) => ({
            ...aggr,
            [id]: {
                ops: eniCosts,
                sdr: otherCosts,
            },
        }),
        {} as { [lineItemId: string]: ProductPriceInfo }
    );
}

function domiciliationToProducts(orderEntry: OrderEntryState): Product[] {
    const { domiciliationStandAlone } = orderEntry;
    return !domiciliationStandAlone
        ? null
        : (isEmpty(domiciliationStandAlone?.assets) ? [{ id: 'fake' }] : domiciliationStandAlone?.assets).map(
              (asset: PaymentAsset, idx: number) => {
                  return {
                      assetId: asset?.id,
                      idx,
                      name: asset?.productName,
                      productType: asset?.productType,
                      deliveryAddress: {
                          fullAddress: asset?.indirizzo,
                      },
                      podPdr: asset?.pod || asset?.pdr,
                      powerOrGas: asset?.pod ? AptCommodityType.Power : AptCommodityType.Gas,
                      paymentInfo: {
                          paymentTool: {
                              id: domiciliationStandAlone?.id,
                              oldId: domiciliationStandAlone?.oldId,
                              deactivateOldPaymentTool: domiciliationStandAlone?.deactivateOldPaymentTool,
                              billingPreferenceCode: domiciliationStandAlone?.billingPreferenceId,
                              caseID: domiciliationStandAlone?.caseID,
                          },
                      },
                      configurations: {
                          invoiceShippingMethod: orderEntry.deliveryChannel,
                      },
                      lineItemStatus: asset?.status,
                  } as Product;
              }
          );
}

function orderEntryV1ToV2Products(dataV1: OrderEntryState): DeepPartial<Product>[] {
    // [ProductInfo] Crea una mappa chiave-valore utilizzando lineItemId/podPdr/sku/code => prodotto
    const prodMap: { [key: string]: DeepPartial<Product> } = (dataV1?.infoProdotti || []).reduce(
        (aggr, product) => ({
            ...aggr,
            [product?.id || product?.podPdr || product?.sku || product?.code]: cleanObj(
                // Converto ProductInfo in Product
                productInfo2Product(product)
            ),
        }),
        {} as { [key: string]: Product }
    );

    // [FornituraEsistente] Crea una mappa chiave-valore utilizzando podPdr => prodotto
    const selActCommMap: { [key: string]: DeepPartial<Product> } = (
        dataV1?.selectFornitureAttiveSelezionate || []
    ).reduce(
        (aggr, product) => ({
            ...aggr,
            // Converto FornituraEsistente in Product
            [product?.podPdr]: cleanObj(fornituraEsistente2Product(product)),
        }),
        {} as { [key: string]: Product }
    );

    // [PaymentAsset] Crea una mappa chiave-valore utilizzando podPdr => prodotto
    const domiciliationMap: { [key: string]: DeepPartial<Product> } = (
        dataV1?.domiciliationStandAlone?.assets || []
    ).reduce(
        (aggr, asset) => ({
            ...aggr,
            // Converto PaymentAsset in Product
            [asset?.pod || asset?.pdr]: cleanObj({
                assetId: asset?.id,
                name: asset?.productName,
                deliveryAddress: {
                    fullAddress: asset?.indirizzo,
                },
                podPdr: asset?.pod || asset?.pdr,
                powerOrGas: asset?.pod ? AptCommodityType.Power : asset?.pdr ? AptCommodityType.Gas : null,
                productType: asset?.productType,
            }),
        }),
        {} as { [key: string]: Product }
    );

    // [TermCost/CostRecord] Crea una mappa chiave-valore utilizzando lineItemId => ProductPriceInfo
    const costMap: {
        [lineItemId: string]: ProductPriceInfo;
    } = {
        // Converto TermCost in ProductPriceInfo
        ...termCostsToProductPriceMap(dataV1?.termination?.termCosts),
        // Converto CostRecord in ProductPriceInfo
        ...costRecordToProductPriceMap(dataV1?.costs?.records),
    };

    // [Meter] Crea una mappa chiave-valore utilizzando lineItemId => MeterAppointment
    const appointmentMap: { [key: string]: DeepPartial<MeterAppointment> } = []
        .concat(dataV1.termination?.termAppointment?.meters || [])
        .concat(dataV1.activation?.actiAppointment?.meters || [])
        .reduce(
            (aggr, { lineItemId, isAvailable, location, notes }) => ({
                ...aggr,
                // Converto Meter in MeterAppointment
                [lineItemId]: {
                    location,
                    available: isAvailable,
                    notes,
                },
            }),
            {}
        );

    const configurationDetails = cleanObj<DeepPartial<ProductConfigurations>>({
        propertyType: dataV1?.activation?.propertyTypeSelected,
        propertyOwnership: dataV1?.realEstateOwnership,
        partnership: dataV1?.partnership,
        destinationUse: getValidDestinationUse(dataV1?.destinationUse),
    });

    const productList: DeepPartial<Product>[] = Array.from(
        new Set([...Object.keys(selActCommMap), ...Object.keys(prodMap), ...Object.keys(domiciliationMap)])
    )
        .filter((podPdrSkuCode) => !!podPdrSkuCode)
        .map((podPdrSkuCode) =>
            merge(
                {},
                selActCommMap[podPdrSkuCode] || {},
                prodMap[podPdrSkuCode] || {},
                domiciliationMap[podPdrSkuCode] || {}
            )
        );

    if (dataV1?.pod) {
        let powerProduct = productList.find(({ powerOrGas }) => powerOrGas === AptCommodityType.Power);
        if (!powerProduct) {
            powerProduct = {};
            productList.push(powerProduct);
        }
        // if not in productInfo
        powerProduct.podPdr = dataV1?.pod;
        powerProduct.powerOrGas = AptCommodityType.Power;
        powerProduct.supplyCode = dataV1?.supplyCode;
        powerProduct.pdf = dataV1?.pdf;
    }
    if (dataV1?.pdr) {
        let gasProduct = productList.find(({ powerOrGas }) => powerOrGas === AptCommodityType.Gas);
        if (!gasProduct) {
            gasProduct = {};
            productList.push(gasProduct);
        }
        // if not in productInfo
        gasProduct.podPdr = dataV1?.pdr;
        gasProduct.powerOrGas = AptCommodityType.Gas;
        gasProduct.supplyCode = dataV1?.supplyCode;
        gasProduct.pdf = dataV1?.pdf;
    }

    return productList.map((product) => {
        const { deliveryAddress, communicationAddress } = indirizziToProductAddresses(
            dataV1?.indirizzi,
            dataV1?.anagraficaMb?.piva && dataV1?.anagraficaMb?.companyAddress,
            product?.addressType
        );

        return merge(
            product,
            cleanObj<DeepPartial<Product>>({
                partNumber: dataV1?.partNumber,
                prices: costMap[product?.lineItemId],
                appointment: appointmentMap[product?.lineItemId],
                assetIntegrationId: dataV1?.assetIntegrationId,
                configurations: configurationDetails,
                paymentInfo: stateV1ToPaymentInfo(dataV1),

                isWinBack:
                    product?.isWinBack ||
                    (dataV1.selectFornitureAttiveSelezionate || []).some(({ podPdr }) => podPdr === product?.podPdr),

                technicalDetails: orderEntryV1ToTechnicalDetails(dataV1)[product?.powerOrGas] || {},
                businessDetails: atecoMbToBusinessDetails(dataV1)[product?.powerOrGas] || {},
                deliveryAddress,
                communicationAddress,
            }),
            orderEntryV1ToEffect(dataV1, product?.family),
            dataV1RootToProduct(dataV1)[product?.powerOrGas] || {}
        );
    });
}

function stateV1ToPaymentInfo(dataV1: OrderEntryState): DeepPartial<PaymentInfo> {
    const paymentInstrument = dataV1?.datiPagamento?.tipoPagamento;
    return {
        paymentInstrument,
        existingPaymentTool: dataV1?.datiPagamento?.existingPaymentTool,
        numeroDomiciliazione: dataV1?.numeroDomiciliazione,

        paymentTool:
            paymentInstrument === AptPaymentInstrument.AddebitoCC || dataV1?.datiPagamento?.formBonifico?.iban
                ? {
                      id: dataV1.datiPagamento?.id,
                      oldId: dataV1.datiPagamento?.oldId,
                      iban: dataV1?.datiPagamento?.formBonifico?.iban,
                      billingPreferenceCode: dataV1?.domiciliationStandAlone?.billingPreferenceId,
                      bankAccountOwner: dataV1?.datiPagamento?.formBonifico?.intestatarioConto,
                      membership: dataV1?.datiPagamento?.formBonifico?.settoreAppartenenza,
                      holder: titolareContoToCompanyOrPersonalIdentity(
                          dataV1?.datiPagamento?.formBonifico?.titolareConto
                      ),
                      sepaSubscriber: {
                          fiscalCode: dataV1?.datiPagamento?.formBonifico?.sottoscrittoreSepa?.cf,
                          firstName: dataV1?.datiPagamento?.formBonifico?.sottoscrittoreSepa?.nome,
                          lastName: dataV1?.datiPagamento?.formBonifico?.sottoscrittoreSepa?.cognome,
                      },
                  }
                : paymentInstrument === AptPaymentInstrument.CartaCredito
                ? {
                      id: dataV1.datiPagamento?.id,
                      oldId: dataV1.datiPagamento?.oldId,
                      billingPreferenceCode: dataV1?.domiciliationStandAlone?.billingPreferenceId,
                      creditCardExpirationDate: dataV1.datiPagamento?.creditCardExpirationDate,
                      deactivateOldPaymentTool: dataV1.datiPagamento?.deactivateOldPaymentTool,
                  }
                : {},
    };
}

export function orderEntryV1ToTechnicalDetails(dataV1: DeepPartial<OrderEntryState>): {
    [key in AptCommodityType]: DeepPartial<TechnicalDetails>;
} {
    const switchOutDateGas = dataV1?.selectFornitureAttiveSelezionate?.find(
        (fornitura) => fornitura?.powerOrGas === AptCommodityType.Gas
    )?.dataPresuntoSwitchOut;
    const switchOutDatePower = dataV1?.selectFornitureAttiveSelezionate?.find(
        (fornitura) => fornitura?.powerOrGas === AptCommodityType.Power
    )?.dataPresuntoSwitchOut;
    const pwrInstantaneousPower = getNumberOrNull(
        dataV1?.activation?.podActivationInfo?.ultimaPotDisp || dataV1?.requiredPower
    );
    const pwrAvailablePower = getAvailablePower(pwrInstantaneousPower) || null;
    const neededPwrInstantaneousPower =
        getNumberOrNull(dataV1?.administrativeTechnicalData?.power?.potenzaImpegnata) ||
        getNumberOrNull(dataV1?.activation?.podActivationInfo?.nuovaPotSel) ||
        pwrInstantaneousPower;
    const neededPwrAvailablePower = Math.max(
        getNumberOrNull(dataV1?.administrativeTechnicalData?.power?.potenzaDisponibile) || pwrAvailablePower, // Potenza disponibile,
        // doppio check, non dovrebbe mai servire
        getAvailablePower(pwrInstantaneousPower)
    );
    return merge({
        [AptCommodityType.Gas]: cleanObj({
            typeOfUsage: dataV1?.gasConsumption?.typeOfUse ? dataV1?.gasConsumption?.typeOfUse.split('|') : null,
            switchOutDate: moment(switchOutDateGas).utcOffset(0, true).toDate() || null,
            consumption: dataV1?.gasConsumption?.consumption,
            meterSerialNumber:
                dataV1?.activation?.pdrActivationInfo?.meterNumber ||
                dataV1?.administrativeTechnicalData?.gas?.matricolaMisuratore, // meterNumber - matricolaCorrettore - egl_serial_number
            gasMeterScope: dataV1?.administrativeTechnicalData?.gas?.tipologiaPdr,
            gasMeterDigits: dataV1?.administrativeTechnicalData?.gas?.cifreMisuratore,
            meterCounter:
                dataV1?.administrativeTechnicalData?.gas?.letturaMisuratore ??
                (dataV1?.gasConsumption?.selfReading?.meterCounterValidity
                    ? dataV1?.gasConsumption?.selfReading?.meterCounter
                    : null),
            gasMeterClass:
                (dataV1?.activation?.pdrActivationInfo?.meterClass as GasMeterClass) ||
                dataV1?.administrativeTechnicalData?.gas?.classeMisuratore ||
                GasMeterClass.G4, // classeMisuratore
            gasMeterEmbeddedCluster: dataV1?.administrativeTechnicalData?.gas?.gruppoMisuratoreIntegrato,
            gasMeterC2AdjustmentFactor: dataV1?.administrativeTechnicalData?.gas?.coefficienteCorrettivoC,
            gasMeterAdjustmentSerialNumber: dataV1?.administrativeTechnicalData?.gas?.matricolaCorrettore,
            gasMeterAdjustmentDigit: dataV1?.administrativeTechnicalData?.gas?.cifreCorrettore,
            gasMeterAdjustmentNumber:
                dataV1?.administrativeTechnicalData?.gas?.letturaCorrettore ??
                dataV1?.gasConsumption?.selfReading?.meterAdjustmentCounter,
            vendorCode: dataV1?.activation?.pdrActivationInfo?.vendorCode,
            gasPotential: getNumberOrNull(dataV1?.activation?.pdrActivationInfo?.potentiality),
            gasPotentialFromCheckPdr: !!dataV1?.activation?.pdrActivationInfo?.potentialityFromCheckPdr,
            needed: {
                meterCounter: dataV1?.gasConsumption?.selfReading?.meterCounter,
            },
        }),
        [AptCommodityType.Power]: cleanObj({
            typeOfUsage: dataV1?.powerConsumption?.typeOfUse ? dataV1?.powerConsumption?.typeOfUse.split('|') : null,
            consumption: dataV1?.powerConsumption?.value,
            switchOutDate: moment(switchOutDatePower).utcOffset(0, true).toDate() || null,
            //meterCounter: null,
            pwrInstantaneousPower,
            pwrAvailablePower,
            pwrVoltage:
                (dataV1?.activation?.podActivationInfo?.tensione as AptPowerVoltage) ||
                dataV1?.administrativeTechnicalData?.power?.tensione ||
                (dataV1?.voltage as AptPowerVoltage),
            needed: {
                pwrInstantaneousPower: neededPwrInstantaneousPower,
                pwrAvailablePower: neededPwrAvailablePower,
                //meterCounter: null,
            },
            pwrDeclaredConsumption: dataV1?.powerConsumption?.declared,
        }),
    });
}

function dataV1RootToProduct(dataV1: OrderEntryState): {
    [key in AptCommodityType]: DeepPartial<Product>;
} {
    return {
        [AptCommodityType.Gas]: {
            podPdr: dataV1?.pdr,
        },
        [AptCommodityType.Power]: {
            podPdr: dataV1?.pod,
        },
    };
}

function atecoMbToBusinessDetails(dataV1: OrderEntryState): {
    [key in AptCommodityType]: DeepPartial<BusinessDetails>;
} {
    const commonDetails = {
        activity: dataV1?.atecoMb?.attivita,
        sector: dataV1?.atecoMb?.settore,
        validated: !!dataV1?.atecoMb?.validated,
        atecoDescription: dataV1?.atecoMb?.denominazione,
    };
    return {
        [AptCommodityType.Gas]: {
            ...commonDetails,
            ateco: dataV1?.atecoMb?.codeAtecoGas,
        },
        [AptCommodityType.Power]: {
            ...commonDetails,
            ateco: dataV1?.atecoMb?.codeAtecoPower,
        },
    };
}

function titolareContoToCompanyOrPersonalIdentity(titolare: TitolareConto): CompanyIdentity {
    return {
        companyName: titolare?.ragioneSociale,
        vatCode: titolare?.piva,
        fiscalCode: titolare?.cfAzienda || titolare?.cf,
        firstName: titolare?.nome,
        lastName: titolare?.cognome,
        fullName: titolare?.fullName,
    };
}

export function orderEntryV1ToEffect(
    dataV1: DeepPartial<{
        termination: { termDate: string | Date };
        activation: { actDate: string | Date; immediateEffect: boolean };
        tipoEsecuzione: {
            dataAnticipataCommodity: string | Date;
            dataStimataCommodity: string | Date;
            dataStandardCommodity: string | Date;
            dataStimataManutenzione: string | Date;
            passaggioRapidoCommodity: boolean;
            passaggioRapidoManutenzione: boolean;
        };
    }>,
    productFamily?: AptProductFamily
): {
    effectiveDate: Date;
    immediateEffect: boolean;
} {
    return {
        effectiveDate:
            parseToDate(dataV1?.termination?.termDate) ||
            parseToDate(dataV1?.activation?.actDate) ||
            (isCommodityFamily(productFamily) &&
                parseToDate(
                    dataV1?.tipoEsecuzione?.dataStimataCommodity ||
                        dataV1?.tipoEsecuzione?.dataStandardCommodity ||
                        dataV1?.tipoEsecuzione?.dataAnticipataCommodity
                )) ||
            (productFamily === AptProductFamily.ServizioTecnico &&
                parseToDate(
                    dataV1?.tipoEsecuzione?.dataStimataManutenzione || dataV1?.tipoEsecuzione?.dataStandardCommodity
                )) ||
            null,
        immediateEffect:
            !!dataV1?.activation?.immediateEffect ||
            (isCommodityFamily(productFamily) && !!dataV1?.tipoEsecuzione?.passaggioRapidoCommodity) ||
            (productFamily === AptProductFamily.ServizioTecnico &&
                !!dataV1?.tipoEsecuzione?.passaggioRapidoManutenzione),
    };
}
