import {
    AfterContentInit,
    Attribute,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { SegoeMDL2Font } from '../../../enums/shared/segoe-mdl2-font';
export type TemplateType = 'horizontal' | 'vertical' | 'complex';
@Component({
    selector: 'egl-selectable-pane',
    templateUrl: './egl-selectable-pane.component.html',
    styleUrls: ['./egl-selectable-pane.component.scss'],
})
export class EglSelectablePaneComponent implements OnInit, AfterContentInit {
    readonly icons = SegoeMDL2Font;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() text: string;
    @Input() icon?: string;
    @Input() iconWidth?: number;
    @Input() iconHeight?: number;
    @Input() layout: string;
    @Input() haveSubtitle: boolean;
    @Input() infoList?: ISelectablePaneInfoItem[];
    @Input() layoutColorBlue?: boolean;
    @Input() titleOnly?: boolean;
    /**
     * @deprecated utilizzare l'input
     */
    @Input() data?: SelectablePane;
    @Input() tag: string;
    /**
     * @deprecated utilizzare il disabled sull'input
     */
    @Input() isDisabled: boolean;
    @Input() bulletListPane?: string[];
    /**
     * @deprecated utilizzare NgModel/FormControl dell'input
     */
    @Output() onSelectedItem = new EventEmitter<string>();

    @ContentChild(NgControl) private childCtrl!: NgControl;
    @ContentChild(NgControl, { read: ElementRef, static: false }) public childElement: ElementRef<HTMLInputElement>;

    public get selected(): boolean {
        return this.childElement?.nativeElement?.checked || this.data?.selected;
    }

    public get disabled(): boolean {
        return this.childCtrl?.disabled || this.isDisabled;
    }
    constructor(@Attribute('templateType') public templateType: TemplateType) {
        this.templateType = templateType || 'vertical' || 'complex';
    }
    ngOnInit() {}
    ngAfterContentInit(): void {
        if (!this.data) {
            this.data = Object.defineProperties(
                {
                    id: this.childElement?.nativeElement?.attributes?.getNamedItem('name'),
                    value: this.childElement?.nativeElement?.value,
                } as unknown as SelectablePane,
                {
                    selected: {
                        get: (): boolean => this.childElement?.nativeElement?.checked,
                    },
                }
            );
        }
    }
    /**
     * @description Gestisce l'evento di selezione del "SelectablePane" eseguendo l'emit dell'id dell'oggetto data
     * @returns void
     */
    onSelected(): void {
        this.onSelectedItem.emit(this.data.id);
    }
}

/**
 * @deprecated utilizzare formControl, formControlName o ngModel sull'input
 */
export class SelectablePane {
    id: string;
    value: any;
    selected: boolean;
    disabled?: boolean;
}

export interface ISelectablePaneInfoItem {
    title: string;
    value: string;
}
