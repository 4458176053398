import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { of, Observable } from 'rxjs';
import { TemplateType } from '../../../modules/cart-costs/components/cart-costs/cart-costs.component';
import { orderBy } from 'lodash';

@Component({
    selector: 'egl-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
    @Input() dataTable: TableModel;
    @Input() check?: boolean = false;
    @Input() hover?: boolean = false;
    @Input() templateType: TemplateType = 'horizontal';
    @Input() checkType: CheckType = 'radio';
    @Output() onCellClick: EventEmitter<CellClickedData> = new EventEmitter<CellClickedData>();
    @Input() isTableLoading = false;

    currentSortField = '';
    ascending: boolean;

    ddSelectionDataOpen: boolean;

    constructor() {}

    ngOnInit(): void {}

    get validColumns() {
        return (this.dataTable?.columns || []).filter((column) => !column?.hidden);
    }

    get allColumns() {
        return (this.dataTable?.columns || []).filter((column) => !column?.hiddenInFilter);
    }

    onSorting(column: TableModelColumn) {
        if (this.currentSortField != column.field) {
            this.ascending = false;
            this.currentSortField = column.field;
        } else {
            this.ascending = !this.ascending;
        }
        this.dataTable.options = orderBy(this.dataTable.options, [column.field], [this.ascending ? 'asc' : 'desc']);
    }

    onCellClicked(option: any, column: TableModelColumn) {
        this.onCellClick.emit({ option, column });
    }

    onCheckClicked(option: any, isTableItemChecked: boolean) {
        option.isTableItemChecked = isTableItemChecked;
    }

    isclickable(column: TableModelColumn, item: any): Observable<boolean> {
        if (typeof column.clickable == 'boolean') return of(column.clickable as boolean);
        if (typeof column.clickable == 'function') {
            return (column.clickable as any)(item);
        }
        return of(false);
    }

    toggleFilter(column: TableModelColumn): void {
        column.hidden = !column.hidden;
    }

    get columnsLength(): number {
        if (this.check) {
            return this.validColumns.length + 1;
        }
        return this.validColumns.length;
    }

    /**
     * @description: apre la dropdown di selezione tab
     * @return: void
     */
    onOpenSelection(): void {
        this.ddSelectionDataOpen = !this.ddSelectionDataOpen;
    }

    get checkedItems(): any[] {
        const checkedItems = this.dataTable.options.filter((item) => item.isTableItemChecked);
        checkedItems.forEach((item) => delete item.isTableItemChecked);
        return checkedItems;
    }
}

export interface TableModel {
    title?: string;
    columns: Array<TableModelColumn>; // Configurazione colonne con etichetta e campo da mappare dalle options
    options: any[]; // Array di oggetti con i campi, ogni elemento dell'array è una riga della tabella
    footerLabel?: string;
    footerValue?: string;
    noDataLabel?: string;
    /**
     TRUE se si desidera visualizzare il valore di default (---) nelle celle con valore nullo o vuoto
     */
    showDefaultValue?: boolean;
    filterByFields?: boolean;
    exportableToCsv?: boolean;
    csvDefaultName?: string;
    tableSort?: TableSortBy;
}

export interface TableModelColumn {
    title: string;
    field: string;
    tooltip?: string;
    sortable?: boolean;
    clickable?: boolean | ((item) => Observable<boolean>);
    truncateCharacters?: number;
    hidden?: boolean;
    hideForExport?: boolean;
    hiddenInFilter?: boolean;
    /**
     * Se non valorizzato, allora valore di default => string
     */
    type?: 'string' | 'icon';
}

export interface CellClickedData<T = any> {
    option: T;
    column: TableModelColumn;
}

export class TableSortBy {
    currentSortField: string = '';
    ascending: boolean;
}

export type CheckType = 'radio' | 'checkbox';
