import { EventEmitter, Injectable } from '@angular/core';
import { from, fromEvent, interval, Observable, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AppConfig } from '../../models/app/app-config';
import { D365PostMessage } from '../../models/app/d365-postmessage';
import { environment } from '../../../../environments/environment';
import { getRandomHash, writeImportantErrorLog, writeImportantInfoLog } from '../../functions/misc.functions';
import { get as _get, isEmpty as _isEmpty } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class PrivateConfigurationService {
    private readonly CACHE_DURATION = 30 * 60000; // 30 min
    private configuration: AppConfig;
    configLoaded = false;
    privateConfigLoaded = new EventEmitter<AppConfig>();

    constructor() {
        if (_isEmpty(this.configuration)) {
            interval(this.CACHE_DURATION)
                .pipe(
                    startWith(0),
                    switchMap(() => this.getSalesUpConfig())
                )
                .subscribe((config) => {
                    this.configuration = config;
                    this.configLoaded = true;
                    this.privateConfigLoaded.emit(this.configuration);
                    console.debug(`Private Configuration Loaded at ${new Date().toLocaleTimeString()}`);
                });
        }
    }

    get config(): AppConfig {
        if (_isEmpty(this.configuration)) {
            writeImportantErrorLog('Configuration was not ready. Data could be wrong!');
        }
        return this.configuration;
    }

    get<T>(key: string[] | string, defaultValue?: any): T {
        return _get(this.configuration, key, defaultValue);
    }

    private getSalesUpConfig(): Observable<AppConfig> {
        if (environment.configOverride?.privateConfiguration) {
            return this.getOverridePrivateConfig();
        } else {
            return this.getD365PrivateConfig(<D365PostMessage>{
                type: 'executeaction',
                content: {
                    request: {},
                    requestmetadata: {
                        boundParameter: null,
                        operationType: 0,
                        operationName: 'egl_privateconfiguration_getsalesupconfiguration',
                        parameterTypes: {},
                    },
                },
            });
        }
    }

    private getD365PrivateConfig(message: D365PostMessage): Observable<AppConfig> {
        const type = getRandomHash() + new Date().getTime() + (Math.random() * 10 ** 8).toString(36);
        parent.window.postMessage({ ...message, content: { ...message.content, type } }, '*');

        return fromEvent(window, 'message').pipe(
            filter((evt: MessageEvent) => evt.data.type === type),
            map((evt: MessageEvent) => evt.data || {}),
            map(({ content }) => (content ? <AppConfig>JSON.parse(content.SalesUpConfig) : null))
        );
    }

    /**
     * Recupera la configurazione dell'app dal file (indicato in environment.configOverride.privateConfiguration)
     */
    private getOverridePrivateConfig(): Observable<AppConfig> {
        return from(fetch(environment.configOverride.privateConfiguration)).pipe(
            map((response) => response.json() as unknown as AppConfig),
            tap((localConfig) => writeImportantInfoLog('LOADED LOCAL CONFIGURATION', localConfig)),
            catchError((error) => {
                console.error(`File '${error.url}' not found`);
                return of(null);
            })
        );
    }
}
