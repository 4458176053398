import { ProductPriceInfo } from '../../store/models/order-entry-state_v2';
import { AptVatCode } from '../enums/apttus/apt-vat-code';
import { EglCartItemLight } from '../models/apttus/tables/cart/egl-cart-item-light';
import { APT_VAT_CODE_MAP } from './remap.functions';
import { EglItemGroup } from './transformation.functions';

export function calculateNdsPrices(item: EglItemGroup<EglCartItemLight>): Partial<ProductPriceInfo> {
    // Il productCore è l'id del prodotto Bene che recepisce gli sconti e gli aggravi NON cross
    const productCore = item.MainLine.Product.egl_product_core;
    // Recupero i mattoncini 'Bene' che compongono la struttura del prodotto
    const goodBricks = item.Options.filter(
        (opt) => opt.ProductOption?.egl_product_recordtype === 'Mattoncino NDS Bene'
    );
    // Recupero i mattoncini di sconto (XSCR) e aggravio (XACR) che non hanno come target il productCore (mattoncino bene) ma bensì tutto il pacchetto
    const crossBricks = item.Options.filter((opt) =>
        ['XSCR', 'XACR'].includes(opt.Option.egl_billingitem_code?.substring(0, 4))
    );
    // Calcolo i prezzi dei mattoncini 'Bene' andando ad applicare su di loro sconti e aggravi e scorporando l'iva in base al "codice iva"
    let goodBricksPrices = goodBricks.reduce(
        (aggr, curr) => {
            // Identifico se il mattoncino corrente è il prodotto core (nel caso, applicherò su di lui gli sconti e gli aggravi)
            const isProductCore = curr.Option.Id === productCore;
            // Prezzo IVATO del mattoncino 'bene'
            let goodBrickPrice = curr.ListPrice;
            if (isProductCore) {
                // Recupero i mattoncini di sconto (XS) e aggravio (XA) che hanno come target il prodotto core. Li ordino in modo che prima vengano eseguiti quelli puntuali (Euro) e poi i percentuali
                const goodDiscountsAggravations = item.Options.filter(
                    (opt) =>
                        !crossBricks.map((m) => m.Id).includes(opt.Id) &&
                        ['XS', 'XA'].includes(opt.Option.egl_billingitem_code?.substring(0, 2))
                ).sort(({ PriceUom }) => BRICK_UOM_SORT_MAP[PriceUom] || BRICK_UOM_SORT_MAP['DEFAULT']);

                // Applico gli sconti e gli aggravi sui prezzi IVATI
                goodBrickPrice = goodDiscountsAggravations.reduce(
                    (calculatedPrice, { Option, PriceUom, ListPrice, egl_percentage_value }) => {
                        const brickType = Option.egl_billingitem_code?.substring(0, 2);
                        if (PriceUom === 'Percentuale') {
                            calculatedPrice =
                                calculatedPrice +
                                (PERCENTAGE_AMOUNT_MAP[brickType] || PERCENTAGE_AMOUNT_MAP['DEFAULT'])(
                                    calculatedPrice,
                                    +egl_percentage_value
                                );
                        } else {
                            calculatedPrice =
                                calculatedPrice +
                                (FIXED_AMOUNT_MAP[brickType] || FIXED_AMOUNT_MAP['DEFAULT'])(ListPrice);
                        }
                        return calculatedPrice;
                    },
                    goodBrickPrice
                );
            }
            // Restituisco il prezzo IVATO e non IVATO del mattoncino Bene, dopo aver applicato sconti e aggravi
            return {
                netPrice: aggr.netPrice + goodBrickPrice,
                noVatPrice: aggr.noVatPrice + calculateNoVatPrice(goodBrickPrice, curr.Option.egl_codice_iva),
            };
        },
        { netPrice: 0, noVatPrice: 0 }
    );
    if (goodBricksPrices.netPrice < 0) {
        goodBricksPrices = {
            netPrice: 0,
            noVatPrice: 0,
        };
    }
    // Applico infine gli sconti (XSCR) e aggravi (XACR) cross che hanno come target l'intero pacchetto. Sono solamente in valore percentuale. Quelli puntuali non sono supportati a causa della mancanza del codice IVA
    const { netPrice, noVatPrice } = (crossBricks || [])
        .filter((li) => li.PriceUom === 'Percentuale')
        .reduce(({ netPrice, noVatPrice }, { Option, egl_percentage_value }) => {
            const brickType = Option.egl_billingitem_code?.substring(0, 2);
            const percentageCalcFunction = PERCENTAGE_AMOUNT_MAP[brickType] || PERCENTAGE_AMOUNT_MAP['DEFAULT'];
            return {
                netPrice: netPrice + percentageCalcFunction(netPrice, +egl_percentage_value),
                noVatPrice: noVatPrice + percentageCalcFunction(noVatPrice, +egl_percentage_value),
            };
        }, goodBricksPrices);
    return { netPrice: netPrice.toString(), noVatPrice: noVatPrice.toString() };
}

const FIXED_AMOUNT_MAP: { [key in 'XS' | 'XA' | 'DEFAULT']: (amount: number) => number } = {
    XS: (amount) => -Math.abs(amount),
    XA: (amount) => +Math.abs(amount),
    DEFAULT: () => 0,
};

const PERCENTAGE_AMOUNT_MAP: { [key in 'XS' | 'XA' | 'DEFAULT']: (amount: number, percentageValue: number) => number } =
    {
        XS: (amount, percentageValue) => -((amount || 0) * ((percentageValue || 0) / 100)),
        XA: (amount, percentageValue) => +((amount || 0) * ((percentageValue || 0) / 100)),
        DEFAULT: () => 0,
    };

const BRICK_UOM_SORT_MAP: { [key in 'Percentuale' | 'Euro' | 'DEFAULT']: number } = {
    Percentuale: 1,
    Euro: -1,
    DEFAULT: -1,
};

export function calculateNoVatPrice(price: number, aptVatCode: AptVatCode): number {
    const taxRate = APT_VAT_CODE_MAP[aptVatCode] || 0;
    if (!taxRate || !price) {
        return price;
    }
    return price / taxRate;
}
