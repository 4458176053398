/* tslint:disable:variable-name */
import { AField, AObject, ATable } from '@congacommerce/core';

@ATable({
    sobjectName: 'Apttus_Config2__AccountLocation__c',
    aqlName: null,
})
export class EglAccountLocationLight extends AObject {
    constructor() {
        super();
    }

    @AField({ soql: 'Name' })
    Name: string;

    @AField({ soql: 'City' })
    City: string;

    @AField({ soql: 'Region' })
    Region: string;

    @AField({ soql: 'State' })
    State: string;

    @AField({ soql: 'Street' })
    Street: string;

    @AField({ soql: 'PostalCode' })
    PostalCode: string;

    @AField({ soql: 'egl_billingaccount_address_line2' })
    egl_billingaccount_address_line2: string;

    @AField({ soql: 'egl_billingaccount_address_prefix' })
    egl_billingaccount_address_prefix: string;

    @AField({ soql: 'egl_billingaccount_paymentmethod' })
    egl_billingaccount_paymentmethod: string;

    @AField({ soql: 'egl_billingaccount_iban' })
    egl_billingaccount_iban: string;

    @AField({ soql: 'egl_billingaccount_fullname' })
    egl_billingaccount_fullname: string;

    @AField({ soql: 'egl_billingaccount_firstname' })
    egl_billingaccount_firstname: string;

    @AField({ soql: 'egl_billingaccount_lastname' })
    egl_billingaccount_lastname: string;

    @AField({ soql: 'egl_billingaccount_fiscalcode' })
    egl_billingaccount_fiscalcode: string;

    @AField({ soql: 'egl_bankaccount_holder_fiscalcode' })
    egl_bankaccount_holder_fiscalcode: string;

    @AField({ soql: 'egl_code' })
    egl_code: string;

    @AField({ soql: 'egl_bankaccount_holder_taxcode' })
    egl_bankaccount_holder_taxcode: string;

    @AField({ soql: 'egl_bankaccount_holder_company_name' })
    egl_bankaccount_holder_company_name: string;

    @AField({ soql: 'egl_bankaccount_holder_firstname' })
    egl_bankaccount_holder_firstname: string;

    @AField({ soql: 'egl_bankaccount_holder_lastname' })
    egl_bankaccount_holder_lastname: string;

    @AField({ soql: 'egl_subscriber_sepa_firstname' })
    egl_subscriber_sepa_firstname: string;

    @AField({ soql: 'egl_subscriber_sepa_lastname' })
    egl_subscriber_sepa_lastname: string;

    @AField({ soql: 'egl_subscriber_sepa_fiscalcode' })
    egl_subscriber_sepa_fiscalcode: string;
}
