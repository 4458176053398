import { D365AccountMigrated } from '../../enums/d365/d365-account-migrated';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';
import { D365ContactType } from '../d365/d365-asset.interface';

export class Contact {
    contactid: string;
    firstname: string;
    lastname: string;
    isValidated: boolean;
    /** @description Codice Fiscale */
    egl_taxcode: string;
    /** @description Ragione Sociale */
    name?: string;
    emailaddress1?: string;
    /** @description Via */
    address1_line1?: string;
    /** @description Città */
    address1_city?: string;
    /** @description Regione */
    address1_region?: string;
    /** @description CAP */
    address1_postalcode?: string;
    /** @description Provincia */
    address1_stateorprovince?: string;
    /** @description Telefono */
    telephone1?: string;
    /** @description Cellulare */
    mobilephone?: string;
    /** @description Codice cliente */
    preferredcontactmethodcode?: number;
    /** @description Partita Iva */
    egl_vatcode?: string;
    egl_customercode?: string;
    birtDate?: Date;
    birthPlace?: string;
    nationality?: string;
    gender?: Gender;
    birthProvince?: string;
    birthCountry?: string;
    accountid?: string;
    egl_customeraccountcode?: string;
    egl_customersegmentcode?: D365CustomerSegment;
    telephone2?: string;
    isCustomer?: boolean;
    startActivation?: Date;
    isFormerCustomer?: boolean;
    prefixMobilephone?: string;
    prefixTelephone1?: string;
    prefixTelephone2?: string;
    preferredContact?: string;
    EglMigration?: D365AccountMigrated;
    billingAccountId?: string;
    egl_portalregistration?: boolean;
    contactRole?: D365ContactType;
    egl_vulnerabilitytype_socialbonus?: boolean;
    egl_vulnerabilitytype_saemapre?: boolean;
    egl_vulnerabilitytype_over75?: boolean;
    egl_vulnerabilitytype_disabled?: boolean;
    infoPopupShown?: boolean;

    isEmailFromPortal?: boolean;

    constructor() {
        this.isFormerCustomer = false;
        this.isCustomer = false;
        this.isEmailFromPortal = false;
    }
}

export type Gender = 'F' | 'M';
