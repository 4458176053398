import { AvaConfiguration } from './ava-configurations';
import versionInfo from '../../version-info.json';

export const environment: AvaConfiguration = {
    appVersion: `${versionInfo.version}.pre-prod`,
    buildNumber: versionInfo.buildNumber,
    production: false,
    defaultImageSrc: './assets/images/default.png',
    defaultCountry: 'IT',
    defaultLanguage: 'it',
    enableErrorLogging: false,
    enableErrorReporting: false,
    enableMultiCurrency: false,
    enableQueryLogs: true,
    enablePerformanceLogs: true,
    defaultCurrency: 'EUR',
    bufferTime: 10,
    maxBufferSize: 5,
    disableBuffer: false,
    subqueryLimit: 10,
    disableCache: false,
    encryptResponse: false,
    cartRetryLimit: 3,
    productIdentifier: 'Id',
    type: 'Salesforce',
    debounceTime: 1000,
    proxy: 'https://apttus-proxy.herokuapp.com',
    packageNamespace: 'Apttus_WebStore',
    useIndexedDB: true,
    expandDepth: 8,
    skipPricing: true,
    skipRules: false,
    pricingMode: 'turbo',
    aboPricingMode: 'default',
    storefront: 'EGL',
    organizationId: '00D9E00000034Lf', // learning
    endpoint: 'https://egl-apttus--learning1.sandbox.my.site.com', // learning
    auth: {
        clientId: '9b7d0c0d-6403-4055-86a0-8c47bbb2597b',
        tenantId: 'c16e514b-893e-4a01-9a30-b8fef514a650',
        redirectUri: 'https://app01l1awwe1782.azurewebsites.net',
    },
};
