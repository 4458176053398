import { FlowType } from '../../store/models/flow-type';
import { AptSalesProcess } from '../enums/apttus/apt-sales-process';
import { AptSignatureMode } from '../enums/apttus/apt-signature-mode';
import { DocTypeOpenText } from '../enums/shared/doc-types-open-text';

type DocumentTypeConfigMap = {
    [key in FlowType]?: DocTypeOpenText;
} & {
    DEFAULT: DocTypeOpenText;
};
export const DOCUMENT_TYPE_CONFIG: DocumentTypeConfigMap = {
    [FlowType.DomiciliazioneAttivazioneInserimentoPaymentTool]: DocTypeOpenText.ModuloSepa,
    [FlowType.DomiciliazioneModificaInserimentoPaymentTool]: DocTypeOpenText.ModuloSepa,
    DEFAULT: DocTypeOpenText.Plico,
};

export const SKIP_DOCUMENT_GENERATION = [
    AptSignatureMode.Cartaceo,
    AptSignatureMode.Doi,
    AptSignatureMode.DoiSenzaVocalOrderLight,
    AptSignatureMode.NessunaFirma,
    null,
    undefined,
];
