import { Injectable, EventEmitter } from '@angular/core';
import { EglInputFieldComponent } from '../../../components/apttus/egl-input-field/egl-input-field.component';
import { CartItem, LineItemService, Product } from '@congacommerce/ecommerce';
import { combineLatest, Observable } from 'rxjs';
import { isVariazioneTecnicaWithVoltageNeeded } from '../../../functions/verifications.functions';
import { Store } from '@ngrx/store';
import { EglState } from '../../../../store/reducers';
import { map, mergeMap, take } from 'rxjs/operators';
import { selectDestinationUse, selectSalesProcess } from '../../../../store/selectors/order-entry.selectors';
import { CommonProvider } from '../../../providers/common-provider';
import { FlowType, MacroFlowType } from '../../../../store/models/flow-type';
import { cleanObj, isNotDiscountItem, isActiveNotTechProduct } from '../../../functions/misc.functions';
import { convertSegmentD365toApt, v2ProductToLineItemAttributes } from '../../../functions/remap.functions';
import {
    v2SelectPendingAssetCommodities,
    v2SelectMastership,
} from '../../../../store/selectors/order-entry-v2.selectors';
import { TYPE_OF_USE_APTTUS_FIELD } from '../../../enums/shared/destination-use.enum';
import { Params } from '@angular/router';
import { MastershipType } from '../../../../store/models/user-state';
import { AptSalesProcess } from '../../../enums/apttus/apt-sales-process';
import { selectCartSegment } from '../../../../store/selectors/user.selectors';
import { TranslateService } from '@ngx-translate/core';
import { EglProductAttributeValue } from '../../../models/apttus/tables/cart/egl-cart-item-extended';

interface FieldValidationError {
    message: string;
    field?: string;
    value?: string | number | Date;
}

@Injectable({ providedIn: 'root' })
export class EglApttusFieldValidationProvider {
    inputFields: EglInputFieldComponent[] = [];
    loadedRules = new EventEmitter<boolean>();

    constructor(
        private store: Store<EglState>,
        private commonPrv: CommonProvider,
        private translateSrv: TranslateService
    ) {}

    addInputFields(field: EglInputFieldComponent): void {
        this.inputFields.push(field);
    }

    clear(): void {
        this.inputFields = [];
    }

    clearField(id: string): void {
        const removedElements = this.inputFields.filter((x) => x.inputId !== id);
        this.inputFields = removedElements;
    }

    /**
     * Return option error strings. If array empty no error occurs.
     * @param product
     * @returns
     */
    optionGroupsErrors(product: Product): string[] {
        const optionErrors = product?.OptionGroups?.map((optionGroup) => optionGroup?.errors);
        const errrorMap = optionErrors?.reduce((agg, curr, idx) => {
            const errorlList = curr.map((error) => <string>this.translateSrv.instant(error.message, error.parameter));
            return {
                ...agg,
                [curr?.[0]?.reference?.value || `${idx}_`]: errorlList,
            };
        }, {} as { [key in string]?: string[] });

        return Object.values(errrorMap || {}).flat();
    }

    /**
     * Return attribute error strings. If array empty no error occurs.
     * @param product
     * @returns
     */
    attributeFieldsErrors(
        cartItemList: Array<CartItem>,
        flowType: FlowType,
        forCartItemsOnly?: boolean
    ): FieldValidationError[] | null {
        cartItemList = cartItemList || [];
        const firstCartItem = cartItemList[0];
        const errors = [];

        if (!this.inputFields || this.inputFields.length === 0) {
            // no fields to validate
            return [];
        }

        if (
            forCartItemsOnly &&
            isVariazioneTecnicaWithVoltageNeeded(flowType) &&
            firstCartItem &&
            !firstCartItem.AttributeValue['egl_voltage__c']
        ) {
            errors.push({
                message: 'Attenzione. Non è possibile continuare, sul prodotto non è valorizzata la tensione.',
            });
        }
        if (
            ((forCartItemsOnly && flowType === FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza) ||
                flowType === FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenzaAmministrativa) &&
            firstCartItem &&
            firstCartItem.AttributeValue['egl_engaged_power_required__c'] &&
            firstCartItem.AttributeValue['egl_Required_Power__c'] &&
            Number(firstCartItem.AttributeValue['egl_Required_Power__c']) >=
                Number(firstCartItem.AttributeValue['egl_engaged_power_required__c'])
        ) {
            errors.push({
                message:
                    'Attenzione. Si prega di inserire una potenza contrattuale richiesta superiore alla potenza contrattuale.',
            });
        }

        if (
            ((forCartItemsOnly && flowType === FlowType.VariazioneTecnicaLavoriDiminuzionePotenza) ||
                flowType === FlowType.VariazioneTecnicaLavoriDiminuzionePotenzaAmministrativa) &&
            firstCartItem &&
            firstCartItem.AttributeValue['egl_engaged_power_required__c'] &&
            firstCartItem.AttributeValue['egl_Required_Power__c'] &&
            Number(firstCartItem.AttributeValue['egl_Required_Power__c']) <=
                Number(firstCartItem.AttributeValue['egl_engaged_power_required__c'])
        ) {
            errors.push({
                message:
                    'Attenzione. Si prega di inserire una potenza contrattuale richiesta inferiore alla potenza contrattuale.',
            });
        }

        if (
            ((forCartItemsOnly && flowType === FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasi) ||
                flowType === FlowType.VariazioneTecnicaLavoriPreventivoVariazioneFasiAmministrativa) &&
            firstCartItem &&
            firstCartItem.AttributeValue['egl_num_of_phases_available__c'] &&
            firstCartItem.AttributeValue['egl_num_of_phases_required__c'] &&
            firstCartItem.AttributeValue['egl_num_of_phases_required__c'] ===
                firstCartItem.AttributeValue['egl_num_of_phases_available__c']
        ) {
            errors.push({
                message: 'Attenzione. Inserire un valore N. Fasi Richiesto differente da N. Fasi Disponibile',
            });
        }

        if (
            ((forCartItemsOnly && flowType === FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensione) ||
                flowType === FlowType.VariazioneTecnicaLavoriPreventivoVariazioneTensioneAmministrativa) &&
            firstCartItem &&
            firstCartItem.AttributeValue['egl_voltage__c'] &&
            firstCartItem.AttributeValue['egl_voltage_required__c'] &&
            firstCartItem.AttributeValue['egl_voltage_required__c'] === firstCartItem.AttributeValue['egl_voltage__c']
        ) {
            errors.push({
                message: 'Attenzione. Inserire un valore Tensione Richiesta differente da Tensione',
            });
        }

        const invalidDates = this.inputFields.find(
            (field) => typeof field.value === 'string' && field.value?.toLowerCase() === 'invalid date'
        );

        if (invalidDates) {
            errors.push({
                message: 'Attenzione. Si prega di inserire una data valida.',
                field: invalidDates.field,
                value: invalidDates.value,
            });
        }

        const primaryCartLineItems = this.clearCartLineItems(cartItemList);
        const requiredFields = this.inputFields.filter((field) => field.required && !field.disabled && !field.hide);
        for (let i = 0, j = requiredFields.length; i < j; ++i) {
            const input = requiredFields[i] as EglInputFieldComponent;
            if (!input.value) {
                errors.push({
                    message: 'Attenzione. Compila tutti i campi obbligatori.',
                    field: input.field,
                    value: input.value,
                });
            }

            const isSelf = (primaryCartLineItems || []).find(
                (cartItem) => cartItem?.Product?.Id === input?.product?.Id
            );
            if (isSelf && primaryCartLineItems?.length === 1) {
                continue;
            }
        }
        return errors;
    }

    /**
     * @description Controlli sulla modalità di spedizione e metodo di pagamento. I controlli vengono saltati per le extracommodity all'aggiunta carrello per essere riproposti alla creazione dell'ordine.
     * @param cartItemList I prodotti nel carrello
     * @param isEditingProduct Flag che indica se si sta nella modalità di modifica del prodotto
     * @returns Array degli errori restituiti
     */
    public paymentAndShippingFieldsErrors(
        cartItemList: Array<CartItem>,
        isEditingProduct: boolean
    ): FieldValidationError[] {
        const requiredInputs = this.inputFields.filter((field) => field.required && !field.disabled && !field.hide);
        const onlyVisibleItems = this.clearCartLineItems(cartItemList);
        const firstCartItem = onlyVisibleItems[0];
        const errors: FieldValidationError[] = [];

        // Skip validation if there is one item in cart and that item is being edited
        if (onlyVisibleItems.length === 1 && isEditingProduct) return [];

        // Create a mapping to check if payment and shipping methods match with the configuration when the first cart item was added
        // This allows to have a congruent configuration between all cart items when they are added and when they are edited
        const paymentShippingMatch: { [key in keyof EglProductAttributeValue]?: boolean } = requiredInputs
            .filter((input) => ['egl_Invoice_Shipping_Method__c', 'egl_Payment_Instrument__c'].includes(input.field))
            .reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur.field]: cur.value === firstCartItem.AttributeValue[cur.field],
                }),
                {}
            );

        // Check for shipping method compatibility error
        if (
            paymentShippingMatch.hasOwnProperty('egl_Invoice_Shipping_Method__c') &&
            !paymentShippingMatch.egl_Invoice_Shipping_Method__c
        ) {
            errors.push({
                message: 'Modalità di spedizione non compatibile con gli altri prodotti.',
                field: 'egl_Invoice_Shipping_Method__c',
                value: null,
            });
        }
        // Check for payment instrument compatibility error
        if (
            paymentShippingMatch.hasOwnProperty('egl_Payment_Instrument__c') &&
            !paymentShippingMatch.egl_Payment_Instrument__c
        ) {
            errors.push({
                message: 'Metodo di pagamento diverso rispetto a quello già selezionato.',
                field: 'egl_Payment_Instrument__c',
                value: null,
            });
        }
        return errors;
    }

    checkItems(req: {
        salesProcess?: AptSalesProcess;
        cartId?: string;
        productId?: string;
        configuration?: Params;
    }): Observable<void> {
        return combineLatest([
            this.store.select(v2SelectMastership),
            this.store.select(selectSalesProcess),
            this.store.select(selectDestinationUse),
            this.store.select(selectCartSegment).pipe(map((d365Segment) => convertSegmentD365toApt(d365Segment))),
            this.store.select(v2SelectPendingAssetCommodities()).pipe(
                map((products) =>
                    products.reduce(
                        (aggr, product) => ({
                            ...aggr,
                            ...v2ProductToLineItemAttributes(product),
                        }),
                        {} as Params
                    )
                )
            ),
        ]).pipe(
            take(1),
            map(
                ([
                    { sourceCustomerMastership, customerMastership },
                    salesProcess,
                    destinationUse,
                    customerSegment,
                    assetCommoditiesParams,
                ]) => ({
                    salesProcess,
                    configuration: {
                        // Definisco i campi di default da inviare in base a quello che ho nello state
                        ...assetCommoditiesParams,
                        customerMastership,
                        sourceCustomerMastership,
                        customerSegment,
                        [TYPE_OF_USE_APTTUS_FIELD]: destinationUse,
                        // Sovrascrivo i campi di default con quelli in ingresso,
                        ...cleanObj(req?.configuration || {}),
                    },
                })
            ),
            map(({ salesProcess, configuration }) => ({
                operationType: req?.salesProcess || salesProcess,
                productConfigurationId: req?.cartId,
                productId: req?.productId,
                // Ricostruisco la struttura come attesa dal servizio: array di oggetti con field e value
                configuration: Object.entries(configuration)
                    .filter(([, value]) => !!value || ['boolean', 'number'].includes(typeof value))
                    .reduce((aggr, [field, value]) => [...aggr, { field, value }], []),
            })),
            mergeMap((enrichedRequest) => this.commonPrv.checkItems(enrichedRequest))
        );
    }

    checkItemsAnticipated(
        flowType: AptSalesProcess,
        customerMastership: MastershipType,
        sourceCustomerMastership: MastershipType
    ): Observable<void> {
        return this.commonPrv.checkItems({
            operationType: flowType,
            configuration: [
                sourceCustomerMastership && { field: 'sourceCustomerMastership', value: sourceCustomerMastership },
                customerMastership && { field: 'customerMastership', value: customerMastership },
            ].filter(Boolean),
        });
    }

    /**
     * @description Escludo dalla validazione i lineitem dei prodotti tecnici (tipo Fornitura Power) e gli sconti standalone che non sono modificabili
     * @param cartItemList
     * @returns Array di cart item filtrato
     */
    public clearCartLineItems(cartItemList: CartItem[]): CartItem[] {
        return LineItemService.groupItems(cartItemList.filter(isActiveNotTechProduct).filter(isNotDiscountItem))
            .map((group) => group.PrimaryLines as CartItem[])
            .flat();
    }

    hasEmptyRequiredField(): boolean {
        return !!this.inputFields.find(
            (field) => field.required && !field.disabled && !field.hide && [null, undefined].includes(field.value)
        );
    }
}
