import { Expose, Type } from 'class-transformer';
import { CartItem, ProductAttributeValue } from '@congacommerce/ecommerce';
import { EglProductExtended } from '../product/egl-product-extended';
import { AptCommodityType } from '../../../../enums/apttus/apt-commodity-typeof-sale';
import { EglAccountLocationLight } from '../user/egl-account-location-light';
import { EglAddress } from '../address/egl-address';
import { EglProductOptionComponent } from '../product/egl-product-option-component';

export class EglCartItemExtended extends CartItem {
    constructor() {
        super();
    }

    @Expose({ name: 'Apttus_Config2__ProductId__r' })
    @Type(() => EglProductExtended)
    Product: EglProductExtended;

    AttributeValue: EglProductAttributeValue;

    @Expose({ name: 'Apttus_Config2__LocationId__r' })
    @Type(() => EglAccountLocationLight)
    Location: EglAccountLocationLight;

    @Expose({ name: 'egl_service_address_id__r' })
    @Type(() => EglAddress)
    egl_service_address: EglAddress;

    @Expose({ name: 'Apttus_Config2__ProductOptionId__r' })
    @Type(() => EglProductOptionComponent)
    ProductOption: EglProductOptionComponent;
}

export class EglProductAttributeValue extends ProductAttributeValue {
    constructor() {
        super();
    }

    egl_Address_Of_Residence__c: string;
    egl_commodity_supplytype__c: AptCommodityType;
    egl_POD__c: string;
    egl_PDR__c: string;
    egl_Invoice_Shipping_Method__c: string;
    egl_Payment_Instrument__c: string;
}
