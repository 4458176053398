import { createAction, props } from '@ngrx/store';
import { appUserString as c } from './action-types';
import { Contact } from '../../common/models/user/contact';
import { Lead } from '../../common/models/user/lead';
import { AgencyBranchForMonitoring, AgentInfo } from '../../common/models/user/agent';
import { D365CustomerSegment } from '../../common/enums/d365/d365-customer-segment';
import { MastershipType, UserState } from '../models/user-state';
import { CustomerInfo } from '../../common/models/user/customer';
import { DeepPartial } from '../../common/interfaces/deep-partial';
import { D365AccountContactData } from '../../common/functions/transformation.functions';

export const setUserState = createAction(c.SET_USER_STATE, props<{ s: UserState }>());
export const setContact = createAction(c.SET_CONTACT, props<{ c: Contact; fcrm?: boolean }>());
export const setContactEmail = createAction(c.SET_CONTACT_EMAIL, props<{ email: string }>());
export const setLead = createAction(c.SET_LEAD, props<{ l: Lead; fcrm?: boolean }>());
export const setAgentInfo = createAction(c.SET_AGENT_INFO, props<{ a: AgentInfo }>());
export const setCustomerInfo = createAction(c.SET_CUSTOMER_INFO, props<{ c: CustomerInfo }>());

/**
 *  Resetta le solo le seguenti info all'interno dello user.state
 *    contact: null,
 *    lead: null,
 *    fromCrm: false,
 *    customerInfo: null,
 */
export const resetUserState = createAction(c.RESET_USER_STATE);
export const setLastUsedCustomerSegment = createAction(
    c.SET_LAST_USED_CUSTOMER_SEGMENT,
    props<{ s: D365CustomerSegment }>()
);
export const setCartSegment = createAction(c.SET_CART_SEGMENT, props<{ payload: D365CustomerSegment }>());
export const setVirtualAgentAgencyCurrentBranch = createAction(
    c.SET_VIRTUAL_AGENT_CURRENT_AGENCY_BRANCH,
    props<{ payload: AgencyBranchForMonitoring }>()
);
export const setCustomerMastership = createAction(
    c.SET_CUSTOMER_MASTERSHIP,
    props<{ customerMastership: MastershipType }>()
);

export const setCustomerAndContact = createAction(
    c.SET_CUSTOMER_AND_CONTACT,
    props<{ customer: DeepPartial<D365AccountContactData> }>()
);
