<!-- NOTE: modalità utilizzo
    <egl-selectable-pane
        [title]="'Telefono primario'"
        [icon]="'phone'"
        [iconWidth]="35"
        [iconHeight]="35"
        class="egl-contact__pane"
    >
        <input type="radio" id="fav-contact-tel" name="fav-contact" />
        <label for="fav-contact-tel"></label>
     </egl-selectable-pane>
-->
<div
    class="egl-selectable-pane"
    (click)="onSelected()"
    [ngClass]="[
        selected ? 'egl-selectable-pane--selected' : '',
        disabled ? 'egl-selectable-pane--disabled' : '',
        layoutColorBlue ? 'egl-selectable-pane--blue' : '',
        'egl-selectable-pane--' + templateType
    ]"
>
    <div class="egl-selectable-pane__tag" [hidden]="!tag">
        {{ tag }}
    </div>
    <div
        class="egl-selectable-pane__content"
        [ngClass]="titleOnly && templateType === 'horizontal' ? 'egl-selectable-pane__content--title-only' : ''"
    >
        <egl-icon
            [path]="icon"
            *ngIf="icon"
            [width]="iconWidth"
            [height]="iconHeight"
            [iconColor]="disabled ? 'egl-icon--light-grey-disabled' : 'egl-icon--grey'"
            class="egl-selectable-pane__icon"
        ></egl-icon>
        <p class="egl-selectable-pane__content-paragraph">
            <strong class="egl-selectable-pane__title" [innerHTML]="title"></strong>
            <ng-container
                *ngIf="templateType === 'horizontal' || templateType === 'vertical'"
                [ngTemplateOutlet]="standardTemplate"
            >
            </ng-container>
        </p>
        <ng-container *ngIf="templateType === 'complex'" [ngTemplateOutlet]="complexTemplate"></ng-container>
    </div>
    <div>
        <ng-content></ng-content>
    </div>
</div>
<ng-template #standardTemplate>
    <span class="egl-selectable-pane__subtitle">
        <span *ngIf="subtitle" [innerHTML]="subtitle"></span>
        <ng-content select="[subtitle]"></ng-content>
    </span>
    <span class="egl-selectable-pane__subtitle" *ngIf="text" [innerHTML]="text"></span>
    <div class="egl-selectable-pane__infoList-wrapper" *ngIf="infoList">
        <ng-container *ngFor="let info of infoList">
            <p class="egl-selectable-pane__infoList-item">
                <span class="egl-selectable-pane__infoList-item--bold">{{ info.title | autoFormat }}: </span>{{ info.value }}
            </p>
        </ng-container>
    </div>
</ng-template>
<ng-template #complexTemplate>
    <p class="egl-selectable-pane__complex-subtitle">
        <span *ngIf="subtitle" [innerHTML]="subtitle"></span>
        <ng-content select="[subtitle]"></ng-content>
    </p>
    <div class="egl-selectable-pane__complex-subcontainer" *ngIf="text || bulletListPane">
        <p class="egl-selectable-pane__subtitle" *ngIf="text" [innerHTML]="text"></p>
        <ul class="egl-selectable-pane__complex-bullet-list" *ngIf="bulletListPane">
            <li class="egl-selectable-pane__complex-bullet-list-item" *ngFor="let bullet of bulletListPane">
                {{ bullet | translate }}
            </li>
        </ul>
    </div>
</ng-template>