import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    Activation,
    CreditCheckStatus,
    DatiAnagraficiMB,
    Firma,
    GasConsumption,
    OrderEntryState,
    PowerConsumption,
    ProductInfo,
    Termination,
} from '../models/order-entry-state';
import { selectCartSegment, selectChannelCode } from './user.selectors';
import { Indirizzi } from '../../modules/switch-in/order-entry/models/indirizzi';
import {
    containsCommodityWithPartnership,
    containsProductGas,
    containsProductInsurance,
    containsProductMaintenance,
    containsProductPower,
    isDomiciliationStandAlone,
    flowTypeHasNoCart,
    flowTypeUtil,
} from '../../common/functions/verifications.functions';
import { SelectProductTypes } from '../models/select-product-types';
import { SelectSkipPodPdr } from '../models/select-skip -pod-pdr';
import { AptBillType } from '../../common/enums/apttus/apt-bill-type';
import { AptPaymentInstrument } from '../../common/enums/apttus/apt-payment-instrument';
import { SelectFullAddressDatiFornitura } from '../models/select-full-address-dati-fornitura';
import { AptLineStatus } from '../../common/enums/apttus/apt-line-status';
import { D365SignatureType } from '../../common/enums/d365/d365-signature-type';
import { AptCommodityType } from '../../common/enums/apttus/apt-commodity-typeof-sale';
import {
    operationTypeOrFlowTypeToSalesProcess,
    salesProcessOrFlowTypeToOperationType,
} from '../../common/functions/remap.functions';
import { SignatureAndChannel } from '../../common/models/order-entry/signature-channel';
import { FormDatiPagamento } from '../../modules/common/order-entry/models/form-dati-pagamento';
import { OrderEntryFlowSection } from '../actions/order-entry.actions';
import { isActivePackageProduct } from '../../common/functions/misc.functions';
import { FlowType, MacroFlowType } from '../models/flow-type';
import { OrderEntryState_v2 } from '../models/order-entry-state_v2';
import { orderEntryV2ToV1 } from '../../common/functions/transformation-v2-v1.functions';
import { address2Indirizzo, getFullAddressString } from '../../common/functions/address.functions';
import { D365CustomerSegment } from '../../common/enums/d365/d365-customer-segment';
import { SubProductType } from '../../common/enums/shared/sub-product-type';

export const v2OrderEntryState = createFeatureSelector<OrderEntryState_v2>('orderEntry');

export const orderEntryStateV1 = createSelector(v2OrderEntryState, (state) => orderEntryV2ToV1(state));
export const selectOrderEntryState = createSelector(orderEntryStateV1, (state: OrderEntryState) => state);
export const selectFlowType = createSelector(v2OrderEntryState, (state) => state.flowType);
export const selectPod = createSelector(orderEntryStateV1, (state: OrderEntryState) => state.pod);
export const selectPdr = createSelector(orderEntryStateV1, (state: OrderEntryState) => state.pdr);
export const selectTitolaritaImmobile = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.realEstateOwnership
);

export const selectProductsInfo = createSelector(
    orderEntryStateV1,
    ({ infoProdotti, activation, pdr, pod, indirizzi }: OrderEntryState): ProductInfo[] => {
        const supplyAddress = getFullAddressString(
            indirizzi?.indirizzoFornitura?.toponomastica,
            indirizzi?.indirizzoFornitura?.via,
            indirizzi?.indirizzoFornitura?.civico,
            indirizzi?.indirizzoFornitura?.comune,
            indirizzi?.indirizzoFornitura?.prov,
            indirizzi?.indirizzoFornitura?.cap
        );
        const commodityMap = {
            [AptCommodityType.Gas]: {
                podPdr: pdr,
                supplyAddress: getFullAddressString(
                    activation?.pdrActivationInfo?.fornitura?.SiglaTopPdrEsito,
                    activation?.pdrActivationInfo?.fornitura?.IndirPdrEsito,
                    activation?.pdrActivationInfo?.fornitura?.CivicoPdrEsito,
                    activation?.pdrActivationInfo?.fornitura?.ComunePdrEsito,
                    activation?.pdrActivationInfo?.fornitura?.PrPdrEsito,
                    activation?.pdrActivationInfo?.fornitura?.CapPdrEsito
                ),
            },
            [AptCommodityType.Power]: {
                podPdr: pod,
                supplyAddress: getFullAddressString(
                    activation?.podActivationInfo?.fornitura?.toponimo,
                    activation?.podActivationInfo?.fornitura?.via,
                    activation?.podActivationInfo?.fornitura?.civ,
                    activation?.podActivationInfo?.fornitura?.localita,
                    activation?.podActivationInfo?.fornitura?.prov,
                    activation?.podActivationInfo?.fornitura?.cap
                ),
            },
        };
        return (infoProdotti || []).filter(isActivePackageProduct).map((productInfo) => ({
            ...productInfo,
            podPdr: productInfo.podPdr || commodityMap[productInfo.powerOrGas]?.podPdr,
            supplyAddress:
                productInfo.supplyAddress || commodityMap[productInfo.powerOrGas]?.supplyAddress || supplyAddress,
        }));
    }
);
export const selectFirmaState = createSelector(v2OrderEntryState, (state) => state.firma);
export const selectDatiFornitura = createSelector(orderEntryStateV1, (state: OrderEntryState) => state.indirizzi);
export const selectResidenceAddressChanged = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.indirizzi.isResidenceAddressChanged
);
export const selectMp3Info = createSelector(orderEntryStateV1, (state: OrderEntryState) => state.mp3Info);

export const selectPdfPlicoInfo = createSelector(v2OrderEntryState, (state) => state.uploadPdfPlicoInfo);

export const selectFormDatiPagamento = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.datiPagamento
);

export const selectTipoEsecuzione = createSelector(orderEntryStateV1, (state: OrderEntryState) => state.tipoEsecuzione);

export const selectFullAddressDatiFornitura = createSelector(
    selectDatiFornitura,
    (x): SelectFullAddressDatiFornitura => {
        if (!x) {
            return new SelectFullAddressDatiFornitura();
        }
        return {
            indirizzoFornitura: getFullAddressString(x.indirizzoFornitura),
            indirizzoComunicazioni: getFullAddressString(x.indirizzoComunicazioni),
            indirizzoSpedizione: getFullAddressString(x.indirizzoSpedizione),
            indirizzoResidenza: getFullAddressString(x.indirizzoResidenza),
            stessoIndirizzoSpedizione: x.stessoIndirizzoSpedizione,
            stessoIndirizzoResidenza: x.stessoIndirizzoResidenza,
            stessoIndirizoComunicazioni: x.stessoIndirizzoComunicazioni,
            isSedeLegale: x.isSedeLegale,
        };
    }
);

/**
 * Return: Array<string>
 * Restituisce la lista dei ProductType presenti nel carrello
 */
export const selectProductsTypes = createSelector(
    selectProductsInfo,
    v2OrderEntryState,
    (products, v2OrderEntryState): SelectProductTypes => {
        const selectRcDanniProducts = v2OrderEntryState.products.filter(
            (product) => product?.subProductType === SubProductType.RCDanni
        );
        if (!products) {
            return new SelectProductTypes();
        }
        const allProdsTypes = products.map((x: ProductInfo) => x.productType);
        return {
            allTypes: allProdsTypes,
            productsCount: products.length,
            containsPower: containsProductPower(allProdsTypes),
            containsGas: containsProductGas(allProdsTypes),
            containsCommodity: containsProductPower(allProdsTypes) || containsProductGas(allProdsTypes),
            containsInsurance: containsProductInsurance(allProdsTypes),
            containsOnlyInsurance:
                containsProductInsurance(allProdsTypes) &&
                !containsProductGas(allProdsTypes) &&
                !containsProductPower(allProdsTypes) &&
                !containsProductMaintenance(allProdsTypes),
            containsMaintenance: containsProductMaintenance(allProdsTypes),
            containsProductsAtNew: products.filter((p: ProductInfo) => p.isAtNew).length > 0,
            containsHealthProducts:
                products.some((element) => (element?.nome || '').toUpperCase().includes('SALUTE')) &&
                containsProductInsurance(allProdsTypes),
            containsPowerWithPartnership: containsCommodityWithPartnership(products, AptCommodityType.Power),
            containsGasWithPartnership: containsCommodityWithPartnership(products, AptCommodityType.Gas),
            containsRcDanni: selectRcDanniProducts.length > 0,
        };
    }
);

/**
 * @description Restituisce true se non sono presenti prodotti che necessitano l'impostazione dei consensi privacy
 * @returns boolean
 */
export const selectSkipPrivacy = createSelector(selectProductsInfo, selectFlowType, (products, flowType) => {
    const prodsNeedsPrivacy = (products || []).find((x) => x.privacyRequired) !== undefined;
    return !(isDomiciliationStandAlone(flowType) || prodsNeedsPrivacy);
});
/**
 * Return: Array<string>
 * Restituisce la lista dei ProductFamily presenti nel carrello
 */
export const selectProductsFamilies = createSelector(selectProductsInfo, (products) => {
    if (products) {
        return products.map((x: ProductInfo) => x.family);
    }
    return [];
});

/**
 * Return: ProductInfo
 * Restituisce, se esiste, il prodotto POWER presente nel carrello
 */
export const selectPowerProduct = createSelector(selectProductsInfo, (products: ProductInfo[]) => {
    if (products) {
        return products.find((x) => containsProductPower([x.productType]));
    }
    return null;
});

/**
 * @description: Restituisce, se esiste, il prodotto GAS presente nel carrello
 * @return: ProductInfo
 */
export const selectGasProduct = createSelector(selectProductsInfo, (products: ProductInfo[]) => {
    if (products) {
        return products.find((x) => containsProductGas([x.productType]));
    }
    return null;
});

/**
 * @description: Restituisce l'oggetto CartInfo
 * @return: CartInfo
 */
export const selectCartInfo = createSelector(selectOrderEntryState, (state: OrderEntryState) => state.cartInfo);

/*
 * Return: istanza di PowerConsumption
 * Restituisce i consumi selezionati relativi alla luce
 */
export const selectPowerConsumption = createSelector(
    selectOrderEntryState,
    (state: OrderEntryState) => state.powerConsumption || new PowerConsumption()
);

/*
 * Return: valore del dropdown home
 * Restituisce l'opzione selezionata
 */
export const selectPowerHomeDropdownOption = createSelector(
    selectOrderEntryState,
    (state: OrderEntryState) => state.powerHomeDropdownOption
);

/*
 * Return: istanza di PowerConsumption
 * Restituisce i consumi selezionati relativi al gas
 */
export const selectGasConsumption = createSelector(
    selectOrderEntryState,
    (state: OrderEntryState) => state.gasConsumption || new GasConsumption()
);

/*
 * Return: valore del dropdown home
 * Restituisce l'opzione selezionata
 */
export const selectGasHomeDropdownOption = createSelector(
    selectOrderEntryState,
    (state: OrderEntryState) => state.gasHomeDropdownOption
);

/*
 * Return: nome AgencyBranchForMonitoring
 * Restituisce il nome dell'agenzia selezionata
 */
export const selectAgencyBranchForMonitoring = createSelector(
    selectOrderEntryState,
    (state: OrderEntryState) => state.agencyBranchForMonitoring
);

/*
 * Return: codice plico
 */
export const selectPlicoCode = createSelector(v2OrderEntryState, (state) => state.numeroPlico);

export const selectFotoDocumenti = createSelector(v2OrderEntryState, selectPlicoCode, ({ fotoDocumenti }, contract) => {
    return {
        documenti: {
            dataOra: fotoDocumenti?.dataOra,
            nomeFronte: fotoDocumenti?.nomeFronte,
            nomeRetro: fotoDocumenti?.nomeRetro,
            dimensioneFronte: fotoDocumenti?.dimensioneFronte,
            dimensioneRetro: fotoDocumenti?.dimensioneRetro,
            codicePlicoUpload: fotoDocumenti?.codicePlicoUpload,
            tipoDocumento: fotoDocumenti?.tipoDocumento,
            numeroDocumento: fotoDocumenti?.numeroDocumento,
            emessoDa: fotoDocumenti?.emessoDa,
            rilasciatoIl: fotoDocumenti?.rilasciatoIl,
        },
        numeroPlico: contract?.code || null,
    };
});

export const selectIsDigitalCommunications = createSelector(selectProductsInfo, (products) => {
    const prodCP = (products || []).find((p) => p.status === 'Upgraded');
    if (prodCP) {
        return prodCP?.configuration?.billType === AptBillType.Digitale;
    } else {
        return (products || [])[0]?.configuration?.billType === AptBillType.Digitale;
    }
});

export interface ICpDataSelection {
    isChangeProduct: boolean;
    flowType: FlowType;
    assetId: string;
}

export const selectCpData = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState): ICpDataSelection => ({
        isChangeProduct: flowTypeUtil(state.flowType).inMacroFlowTypes(MacroFlowType.CambioProdotto),
        flowType: state.flowType,
        assetId: state.assetIntegrationId,
    })
);
export interface ITipoPagamentoSelection {
    paymentType: AptPaymentInstrument;
    isDigitalCommunications: boolean;
}
export const selectTipoPagamento = createSelector(
    selectProductsInfo,
    selectIsDigitalCommunications,
    selectFormDatiPagamento,
    selectCpData,
    (productInfo, isDigitalCommunications, datiPagamento, cpData): ITipoPagamentoSelection => {
        let payment = AptPaymentInstrument.AddebitoCC;
        if (productInfo && productInfo.length > 0 && productInfo[0].configuration) {
            let product = productInfo[0];
            const upgradedProduct = productInfo.find((p) => p.status === AptLineStatus.Upgraded);
            if (cpData.isChangeProduct && upgradedProduct) {
                product = upgradedProduct;
            }
            payment = product.configuration.paymentInstrument || AptPaymentInstrument.AddebitoCC;
        }
        return {
            paymentType: payment,
            isDigitalCommunications,
        };
    }
);

export const selectProductsAndCommunicationsType = createSelector(
    selectProductsTypes,
    selectIsDigitalCommunications,
    (productTypes: SelectProductTypes, isDigitalComm: boolean) => ({ productTypes, isDigitalComm })
);

export const selectFirmaAndChannel = createSelector(
    selectFirmaState,
    selectChannelCode,
    selectIsDigitalCommunications,
    (firma: Firma, channel: string, comDigit) =>
        ({
            f: firma,
            c: channel,
            isComDigit: comDigit,
        } as SignatureAndChannel)
);

export const selectWinBack = createSelector(orderEntryStateV1, (state: OrderEntryState) => ({
    isWinBack: state?.isWinBack,
    winBackType: state?.winBackType,
}));

export interface IRiepilogoDataSelection {
    datiFornitura: Indirizzi;
    pdr: string;
    pod: string;
    flowType: FlowType;
    datiPagamento: FormDatiPagamento;
    fullAddress: SelectFullAddressDatiFornitura;
    infoProdotti: ProductInfo[];
    isBollettaDigitale: boolean;
    isWinBack: boolean;
}

export const selectRiepilogoData = createSelector(
    selectDatiFornitura,
    selectOrderEntryState,
    selectFormDatiPagamento,
    selectFullAddressDatiFornitura,
    selectProductsInfo,
    selectIsDigitalCommunications,
    (datiFornState, orderEntryState, datiPagStore, fullAddressState, prods, sp): IRiepilogoDataSelection => ({
        datiFornitura: datiFornState,
        pdr: orderEntryState.pdr,
        pod: orderEntryState.pod,
        flowType: orderEntryState.flowType,
        datiPagamento: datiPagStore,
        fullAddress: fullAddressState,
        infoProdotti: prods,
        isBollettaDigitale: sp as boolean,
        isWinBack: !!orderEntryState.isWinBack,
    })
);

export const selectSkipPodPdr = createSelector(orderEntryStateV1, (state: OrderEntryState): SelectSkipPodPdr => {
    return (state.infoProdotti || []).reduce(
        (aggr, product) => ({
            skipPdr: containsProductGas([product.productType]) ? !(product?.podPdr || state.pdr) : aggr.skipPdr,
            skipPod: containsProductPower([product.productType]) ? !(product?.podPdr || state.pod) : aggr.skipPod,
        }),
        { skipPod: false, skipPdr: false }
    );
});

export const selectAnagraficaMB = createSelector(
    v2OrderEntryState,
    selectCartSegment,
    (stateV2, cartSegment) =>
        <DatiAnagraficiMB & { cartSegment: D365CustomerSegment }>{
            ...stateV2.anagraficaMb,
            companyAddress: address2Indirizzo(stateV2.contact?.mainAddress),
            cartSegment,
        }
);
export const selectOcrData = createSelector(orderEntryStateV1, (state: OrderEntryState) => state.ocrData);
export const selectAtecoMB = createSelector(orderEntryStateV1, (state: OrderEntryState) => state.atecoMb);
export const selectPotenzaImpegnataMB = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.potenzaImpegnataMb
);

export const selectFatturazioneElettr = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.fatturazioneElettronica
);
export const selectImpersonatedAgent = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.impersonatedAgent
);

/**
 * Restitusce le tipologie di indirizzi da visualizzare
 */
export const selectDistinctAddressTypes = createSelector(selectProductsInfo, (prods) =>
    (prods || []).map((x) => x.addressType)
);

export const selectResponsabilitaCreditCheck = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.responsabilitaCreditCheckModal
);

export const selectSalesProcess = createSelector(selectFlowType, (flowType) => {
    if (flowTypeHasNoCart(flowType)) return null;
    // Cart/Quote/Order SalesProcess (according to Apttus CPQ)
    return operationTypeOrFlowTypeToSalesProcess(flowType);
});
export const selectOperationType = createSelector(selectFlowType, (flowType) =>
    // Saleability matrix operationType (similar to salesProcess) according to catalog settings
    salesProcessOrFlowTypeToOperationType(flowType)
);

export const selectDomiciliationStandalone = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.domiciliationStandAlone
);

export const selectCreditCheckStatus = createSelector(
    v2OrderEntryState,
    (state) => state.creditCheckStatus || <CreditCheckStatus>{}
);
export const selectCartContainsExpiredProducts = createSelector(selectProductsInfo, (prods: ProductInfo[]) =>
    prods !== undefined ? prods.filter((p: ProductInfo) => p.isExpired).length > 0 : false
);
export const selectDeferredSaleStatus = createSelector(
    v2OrderEntryState,
    selectFirmaState,
    selectCartContainsExpiredProducts,
    (state, sign, cartHasExpiredProds) => {
        if (cartHasExpiredProds) return true;
        return state.deferredSaleEnabled && sign?.d365SignatureType === D365SignatureType.Cartacea;
    }
);

const selectTermination = createSelector(orderEntryStateV1, (state: OrderEntryState) => state.termination || {});
const selectOrderEntryFlowSection = (orderEntryFlowSection: OrderEntryFlowSection) =>
    createSelector(orderEntryStateV1, (state: OrderEntryState) => state[orderEntryFlowSection] || {});

export const selectIsMortisCausa = createSelector(selectTermination, (state: Termination) => state.isMortisCausa);
export const selectTerminationCartId = createSelector(selectTermination, (state: Termination) => {
    return state.cartId;
});
export const selectTerminationIsInvoiceDeliveryAddressUpdated = createSelector(
    selectTermination,
    (state: Termination) => state.isInvoiceDeliveryAddressUpdated
);
export const selectTerminationDate = createSelector(selectTermination, (state: Termination) => {
    return state.termDate;
});
export const selectSelfCertification = createSelector(
    v2OrderEntryState,
    selectPlicoCode,
    ({ selfCertification }, v) => ({
        selfCertification,
        plicoCode: v?.code || null,
    })
);
export const selectTerminationTermContacts = createSelector(
    selectTermination,
    (state: Termination) => state.termContacts
);

export const selectAppointment = (orderEntryFlowSection: OrderEntryFlowSection) =>
    createSelector(
        selectOrderEntryFlowSection(orderEntryFlowSection),
        (state: Termination | Activation) => state[orderEntryFlowSection.substring(0, 4) + 'Appointment']
    );

export const selectTerminationCosts = createSelector(selectTermination, (state: Termination) => state.termCosts);

export const selectIvassAnswers = createSelector(orderEntryStateV1, (state: OrderEntryState) => state.ivassAnswers);
export const selectInsuranceDummyProductId = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.insuranceDummyProductId
);
export const selectFornitureAttive = createSelector(v2OrderEntryState, (state) =>
    (state.fornitureEsistenti || []).filter((f) => f?.stato === 'ATTIVO')
);

export const selectFornitureEsistenti = createSelector(v2OrderEntryState, (state) => state.fornitureEsistenti);

export const selectAdministrativeTechnicalData = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.administrativeTechnicalData
);

export const selectActivation = createSelector(orderEntryStateV1, (state: OrderEntryState) => ({
    ...(state.activation || {}),
    actDate: state.activation?.actDate ? new Date(state.activation?.actDate) : null,
}));
export const selectPodActivationInfo = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.activation?.podActivationInfo
);
export const selectPotencyActivation = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.activation?.podActivationInfo?.nuovaPotSel
);
export const selectPdrActivationInfo = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.activation?.pdrActivationInfo
);
export const selectCosts = createSelector(orderEntryStateV1, (state: OrderEntryState) => state?.costs);
export const selectCombinedSaleQuoteId = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.combinedSaleQuoteId || null
);
export const selectIsCombinedSale = createSelector(orderEntryStateV1, (state: OrderEntryState) => state.combinedSale);
export const selectCommodityCartId = createSelector(
    orderEntryStateV1,
    (state: OrderEntryState) => state.commodityCartId
);
export const selectQuoteId = createSelector(orderEntryStateV1, (state: OrderEntryState) => state.quoteId);
export const selectPropertyType = createSelector(selectActivation, (state: Activation) => state?.propertyTypeSelected);

export const selectDestinationUse = createSelector(
    selectOrderEntryState,
    (state: OrderEntryState) => state.destinationUse
);
