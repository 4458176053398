import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EglState } from '../../../../../../store/reducers';
import { combineLatest, Observable, of } from 'rxjs';
import { BaseProvider } from '../../../../../../common/providers/base-provider';
import { PrivateConfigurationService } from '../../../../../../common/services/shared/private-configuration.service';
import { Product } from '../../../../../../store/models/order-entry-state_v2';
import { StatusResponse } from '../../../../../../common/interfaces/base-api-response';
import {
    v2SelectCommodityProducts,
    v2SelectCurrentProduct,
    v2SelectVisibleProducts,
} from '../../../../../../store/selectors/order-entry-v2.selectors';
import { AptCommodityType } from '../../../../../../common/enums/apttus/apt-commodity-typeof-sale';
import { selectContact } from '../../../../../../store/selectors/user.selectors';
import { Contact } from '../../../../../../common/models/user/contact';
import { TranslateService } from '@ngx-translate/core';
import {
    FraudType,
    RecordMorosity,
    TemplateNameMorosity,
    TipologiaChiusuraTypes,
} from '../../../../../morosity/models/morosity.request';
import { MorosityProvider } from '../../../../../morosity/providers/morosity-provider';
import { EsitiData } from '../../../../../../common/models/app/recupera-dati-salesup.response';
import { selectFlowType } from '../../../../../../store/selectors/order-entry.selectors';
import {
    containsExtraCommodities,
    containsProductMaintenance,
    flowTypeUtil,
} from '../../../../../../common/functions/verifications.functions';
import { MacroFlowType } from '../../../../../../store/models/flow-type';
import { ServiceError } from '../../../../../../common/models/app/service-error';

@Injectable({ providedIn: 'root' })
export class TerminationOrderService extends BaseProvider {
    constructor(
        protected store: Store<EglState>,
        protected configSrv: PrivateConfigurationService,
        protected translateSrv: TranslateService,
        private morosityPrv: MorosityProvider
    ) {
        super(configSrv, translateSrv);
    }

    private TERMINATION_TYPE_MAP: {
        [key in AptCommodityType]: { type: TipologiaChiusuraTypes; priority: number; template: TemplateNameMorosity };
    } = {
        [AptCommodityType.Power]: {
            type: TipologiaChiusuraTypes.CessAmmCAE8RV,
            priority: 50,
            template: TemplateNameMorosity.LetteraCessazioneRifiutoVoltura,
        },
        [AptCommodityType.Gas]: {
            type: TipologiaChiusuraTypes.CessAmmCA4,
            priority: 40,
            template: TemplateNameMorosity.LetteraCessazioneRifiutoVoltura,
        },
    };

    /**
     * @description function che innescherà il sottoprocesso di “Cessazione di Rifiuto Voltura” sul Volturato
     * @param cfOrPiva cf (residenziale) o partita iva (MB)
     * @param lastnameOrName cognome (residenziale) o ragione sociale (MB)
     * @param firstname nome (residenziale)
     */
    public createTermination(cfOrPiva?: string, lastnameOrName?: string, firstname?: string): Observable<void> {
        return combineLatest([
            this.store.select(v2SelectCommodityProducts('ALL')),
            this.store.select(selectContact),
        ]).pipe(
            take(1),
            // preparo i record da mandare all'endpoint
            map(([products, contact]: [Product[], Contact]) =>
                products.map(
                    (product) =>
                        <RecordMorosity>{
                            AccountNum: product?.sourceCustomer?.billingPreferenceCode, // egl_Conto_Cliente || egl_billingPreferenceId
                            FraudType: FraudType.CessazioneAmministrativa,
                            PdF: product?.pdf,
                            TipologiaChiusura: this.TERMINATION_TYPE_MAP[product?.powerOrGas]?.type,
                            OrdineInvio: this.TERMINATION_TYPE_MAP[product?.powerOrGas]?.priority,
                            CodiceFiscaleVolturante: cfOrPiva || contact?.egl_taxcode || contact?.egl_vatcode,
                            CognomeVolturante: lastnameOrName || contact?.lastname || contact?.name,
                            NomeVolturante: firstname || contact?.firstname,
                            NomeTemplate: this.TERMINATION_TYPE_MAP[product?.powerOrGas]?.template,
                        }
                )
            ),
            // creo la request con i record
            map((records) => ({
                Orders: {
                    totalSize: records.length,
                    records,
                },
            })),
            // invoco la chiamata al servizio di termination
            mergeMap((request) => this.morosityPrv.createTerminationOrderDefered(request)),
            // se va tutto bene torno null
            map(() => null)
        );
    }

    /**
     * @desc
     */
    public manageCheckDdlResult<T extends EsitiData>(
        esitiCC: T,
        customerData: { cfPiva: string; lastnameOrName: string; firstname: string }
    ): Observable<T> {
        return combineLatest([
            this.store.select(selectFlowType),
            this.store.select(v2SelectVisibleProducts('ALL')),
        ]).pipe(
            take(1),
            tap(([flowType, products]) => {
                // KO per il controllo del CF da SCIPAFI - senza ripercussioni
                if (esitiCC?.esitoCF?.esito === false) {
                    throw new ServiceError(
                        esitiCC?.esitoCF?.source,
                        `Il Codice Fiscale fornito risulta inesistente o inutilizzabile`,
                        'LOW'
                    );
                }
                if (
                    !flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Administrative, MacroFlowType.Vip) ||
                    flowTypeUtil(flowType).inMacroFlowTypes([MacroFlowType.Voltura, MacroFlowType.Vip])
                ) {
                    // KO Controllo creditizio
                    const koCreditChecks = [
                        esitiCC?.creditCheck?.esito === false && 'CreditCheck',
                        esitiCC?.insolutoNDS?.esito === false && 'InsolutoNDS',
                        esitiCC?.scorecard?.esito === false && 'ScoreCard',
                        //esitiCC?.checkBalance?.esito === false && 'CheckBalance',
                    ].filter(Boolean);
                    if (
                        containsExtraCommodities(products) &&
                        koCreditChecks.some((check) => ['CreditCheck', 'ScoreCard', 'CheckBalance'].includes(check))
                    )
                        throw new ServiceError(
                            'CC_EXTRACOMMODITY',
                            this.translateSrv.instant('GENERIC_DATA.CREDI_CHECK_ERROR'),
                            'LOW'
                        );
                    // se uno dei controlli creditizi e' KO scateniamo ripercussioni in base al processo
                    if (koCreditChecks.length) {
                        throw new ServiceError(
                            koCreditChecks.join(', '),
                            `Le seguenti verifiche sono andate KO: ${koCreditChecks.join(', ')}`,
                            'HIGH'
                        );
                    }
                }
            }),
            // gestisco la casistica specifica di voltura
            catchError((err: ServiceError) =>
                this.store.select(selectFlowType).pipe(
                    take(1),
                    map(
                        (flowType) =>
                            flowTypeUtil(flowType).inMacroFlowTypes(MacroFlowType.Voltura) && err?.level === 'HIGH'
                    ),
                    mergeMap((isCreateTermination) =>
                        isCreateTermination
                            ? this.createTermination(
                                  customerData?.cfPiva,
                                  customerData?.lastnameOrName,
                                  customerData?.firstname
                              ).pipe(
                                  catchError(() => {
                                      throw new ServiceError(
                                          'TERM_VOLTURA',
                                          this.translateSrv.instant('ERROR.GENERIC.MESSAGE'),
                                          'LOW'
                                      );
                                  }),
                                  tap(() => {
                                      throw new ServiceError('TERM_VOLTURA', err?.message, 'HIGH');
                                  })
                              )
                            : of(null)
                    ),
                    tap(() => {
                        throw err;
                    })
                )
            ),
            map(() => esitiCC)
        );
    }
}
