import { Injectable, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { PdfPodPdr } from '../../../modules/back-office/models/get-asset-report-views.request';
import { Regex } from '../../config/regex';
import { Observable, of } from 'rxjs';
import { delay, map, take, tap } from 'rxjs/operators';
import { EglState } from '../../../store/reducers';
import { selectAgentInfo } from '../../../store/selectors/user.selectors';
import { ApiService } from './api.service';
import { LoadingService } from './loading.service';
import { LoggerService } from './logger.service';
import { PrivateConfigurationService } from './private-configuration.service';
import { ServiceError } from '../../models/app/service-error';

type MailToParams = {
    to: string[];
    cc?: string[];
    bcc?: string[];
    subject?: string;
    body?: string;
};

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    constructor(
        private api: ApiService,
        private configSrv: PrivateConfigurationService,
        private logger: LoggerService,
        private store: Store<EglState>
    ) {}

    /**
     * @description: converte una stringa base64 in un ArrayBuffer
     * @param base64string: stringa base64
     * @return: ArrayBuffer
     */
    base64ToArrayBuffer(base64string: string): ArrayBuffer {
        if (base64string) {
            let tmp = base64string;
            const arr = tmp.split('base64,');
            if (arr.length === 2) {
                tmp = arr[1];
            }

            const binaryString = window.atob(tmp);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes.buffer;
        }
        return null;
    }

    /**
     * Trasforma una stringa in base64 o un arrayBuffer in un oggetto di tipo File
     * @param body stringa base64 oppure ArrayBuffer
     * @param nameAndExt Nome file con estenzione
     * @param mime mimetype del file
     */
    arrayBufferToFileObj(body: ArrayBuffer | string, nameAndExt: string, mime: string): File {
        if (typeof body === 'string') {
            body = this.base64ToArrayBuffer(body);
        }

        const content = new Blob([body]);
        const res = new File([content], nameAndExt, {
            type: mime,
        });
        return res;
    }

    /**
     * Verifica che il file non superi la dimensione massima settata nell'enviroment
     * @param file File in input da verificare
     */
    isValidSize(file: File): boolean {
        if (this.configSrv.config) {
            return !(file.size >= this.configSrv.config.maxUploadSize);
        }
        return false;
    }

    /**
     * Trasforma il oggetto di tipo File in un base64
     * @param file input file
     */
    async fileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    }

    async downloadByURL(url: string): Promise<Blob> {
        const blob = await this.api
            .getBlobFile<string>(url)
            .toPromise()
            .catch(() => {});
        return blob;
    }

    toUpper(str: string): string {
        return (str || '').toUpperCase();
    }

    getFileInfoMessage(name: string, size: number, dataOra: Date): string {
        const fileSizeStr = this.formatBytes(size);
        let fileDataOraStr = '';
        if (dataOra) {
            fileDataOraStr = `${moment(dataOra).format('DD/MM/YYYY')} - ${moment(dataOra).format('HH:mm')}`;
        }

        return `
                  Nome: ${name}<br/>
                  Dimensione file: ${fileSizeStr}<br/>
                  Data/ora: ${fileDataOraStr}`.trim();
    }

    /**
     * Rinomina un file
     * @param file File che si vuole rinominare
     * @param newName Nuovo nome CONTENENTE l'estenzione se 'useSameExt' === false
     * @param useSameExt Rinomina il file copiando l'estenzione dal file in input
     */
    renameFile(file: File, newName: string, useSameExt: boolean = true): File {
        if (file) {
            if (useSameExt) {
                // uso la stessa estenzione del file in input
                const fileNameNoExt = newName.replace(/\.[^/.]+$/, ''); // rimuovo il l'esenzione dal nuovoFileName
                const oldExt = `.${file.name.split('.').pop()}`;
                newName = `${fileNameNoExt}${oldExt}`;
            }

            return new File([file], newName, {
                type: file.type,
                lastModified: new Date().getTime(),
            });
        }
        return null;
    }

    convertDateD365ToApt(date: Date): string {
        if (date) {
            let dataString = date.toString().toLowerCase();
            dataString = dataString.replace('/date(', '').replace(')/', '').split('+')[0];
            const epoch = +dataString;
            const newData = moment(new Date(epoch)).format('YYYY-MM-DD');
            return newData;
        }

        return '';
    }

    scrollToFirstInvalidControl(componentRef?: ElementRef): void {
        setTimeout(() => {
            const selectors = 'form .ng-invalid, form .is-invalid, form.ng-invalid, form.is-invalid';
            const firstInvalidControl = componentRef
                ? componentRef.nativeElement.querySelector(selectors)
                : document.querySelector(selectors);

            if (firstInvalidControl) {
                window.scroll({
                    top: this.getTopOffset(firstInvalidControl),
                    left: 0,
                    behavior: 'smooth',
                });
            }
        });
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }

    /**
     * Routine che restituisce  una grandezza di bytes normalizzata con un numerdo di cifre dopo la virgola specifico
     * @param byte il valore da normalizzare espresso in bytes
     * @param decimals il numero di cifre dopo la virgola
     */
    formatBytes(bytes: number, decimals = 2): string {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return '' + parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * Return initils by a full name (e.g. MARIO ROSSI return MR)
     * @param cleanedName full name string
     */
    getInitials(completeName: string): string {
        if (!completeName) return '';

        const cleanedName = completeName.replace(/[^a-zA-Z- ]/g, '');
        const isSingleName = cleanedName.split(' ').length === 1;
        if (isSingleName) {
            if (cleanedName.length < 2) {
                return cleanedName.toUpperCase();
            } else {
                return cleanedName.substring(0, 2).toUpperCase();
            }
        } else {
            let initials = cleanedName.match(/\b\w/g);
            return initials.slice(0, 2).join('').toUpperCase();
        }
    }

    getValueFromHash(valueName: string): string {
        const hash = location.hash;
        if (hash.length !== 0) {
            const url = new URL(`https://${location.host}?${hash.slice(1)}`);
            const value = url.searchParams.get(valueName);
            if (value !== null) {
                return value;
            }
        }
        return '';
    }

    /**
     * Return the distributor code from a POD, it extracts the third, fourth and fifth character from POD eg: 018 from IT018E10006581
     * @param pod string
     * @returns string
     */
    getDistributorCode(pod: string): string {
        if (pod && pod.length > 5) {
            return pod.substring(2, 5);
        }
        return '';
    }

    /**
     * Return blurred pod string, for privacy concern
     * @param pod string
     * @returns blurred Pod according rule
     */
    blurringPod(pod: string): string {
        if (!pod) return '';
        const podArray = pod.split('');
        podArray.splice(3, 2, '*', '*');
        podArray.splice(9, 3, '*', '*', '*');
        return podArray.join('');
    }

    /**
     * Return blurred pdr string, for privacy concern
     * @param pdr string
     * @returns blurred Pdr according rule
     */
    blurringPdr(pdr: string): string {
        if (!pdr) return;
        const pdrArray = pdr.split('');
        pdrArray.splice(4, 3, '*', '*', '*');
        pdrArray.splice(9, 3, '*', '*', '*');
        return pdrArray.join('');
    }

    /**
     * Return blurred iban string, for privacy concern
     * @param iban string
     * @returns blurred Pdr according rule
     */
    blurringIban(iban: string): string {
        if (!iban) return;
        return '***' + iban.slice(-4);
    }

    /**
     * Add a number of working days (Monday-Friday) to input Date
     * @param originalDate date which to add days
     * @param numDaysToAdd number of workdays to add
     * @returns JS Date with days added
     */
    addBusinessDays(originalDate: moment.MomentInput, numDaysToAdd: number): string {
        return this.addMomentBusinessDays(originalDate, numDaysToAdd).format('YYYY-MM-DD');
    }
    addMomentBusinessDays(originalDate: moment.MomentInput, numDaysToAdd: number): moment.Moment {
        const Sunday = 0;
        const Saturday = 6;
        let daysRemaining = numDaysToAdd;

        const targetDate = moment(originalDate);
        while (daysRemaining > 0) {
            targetDate.add(1, 'days');
            if (![Sunday, Saturday].includes(targetDate.day())) {
                daysRemaining--;
            }
        }
        return targetDate;
    }

    convertToCSV(
        objArray: Array<Object>,
        headerList: Array<string>,
        fieldsList: Array<string>,
        delimitator: ',' | ';' = ';'
    ): string {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';
        for (let index in headerList) {
            row += headerList[index] + delimitator;
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in fieldsList) {
                let head = fieldsList[index];
                line += (array[i][head] || '')?.toString().replace(/(\r\n|\n|\r)/gm, ' ') + delimitator;
            }
            str += line.slice(0, -1) + '\r\n';
        }
        return str;
    }

    /**
     * Return the specified search parameter
     * @param searchUrl url to search paramenter
     * @param valueName parameters to search
     * @returns blurred Pdr according rule
     */
    getValueFromUrlParameters(searchUrl: string, valueName: string): string {
        if (searchUrl.length !== 0) {
            const url = new URL(searchUrl);
            const value = url.searchParams.get(valueName);
            if (value !== null) {
                return value;
            }
        }

        return '';
    }

    base64URLEncode(str: string) {
        return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }

    bufferToString(buffer: ArrayBuffer) {
        const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const state = [];
        for (let i = 0; i < buffer.byteLength; i += 1) {
            const index = buffer[i] % CHARSET.length;
            state.push(CHARSET[index]);
        }
        return state.join('');
    }

    isPdfPodOrPdr(pdfPodPdr: string): PdfPodPdr {
        if (pdfPodPdr.match(Regex.POD) !== null) {
            return PdfPodPdr.Pod;
        }

        if (pdfPodPdr.match(Regex.PDR) !== null) {
            return PdfPodPdr.Pdr;
        }

        if (pdfPodPdr.match(Regex.PDF) !== null) {
            return PdfPodPdr.Pdf;
        }

        return null;
    }

    generateMailToLink({ to, ...mail }: MailToParams): string {
        if (!to) {
            this.logger.warn('No TO params setted');
            return;
        }

        const params = Object.entries(mail).map(([key, value]) => {
            const econdedValue = encodeURIComponent(Array.isArray(value) ? value.join(',') : value);
            return `${key}=${econdedValue}`;
        });

        return `mailto:${encodeURIComponent(to.join(','))}?${params.join('&')}`;
    }

    isMaintenanceMode$(): Observable<boolean> {
        return this.store.select(selectAgentInfo).pipe(
            take(1),
            map((agentInfo) => ({
                domainName: agentInfo.DomainName?.toLowerCase(),
                isOffline: this.configSrv.config.offline,
                whitelist: (this.configSrv.config.offlineWhitelist || [])
                    .filter((email) => !!email)
                    .map((email) => email?.toLowerCase()),
            })),
            map(({ domainName, isOffline, whitelist }) => isOffline && !whitelist.includes(domainName)),
            tap(
                (isMaintenanceMode) =>
                    isMaintenanceMode && this.logger.warn(`[MAINTENANCE MODE] User is not in whitelist`)
            )
        );
    }
}
