import { FullState } from '../../../../store/models/full-state';
import { Product } from '../../../../store/models/order-entry-state_v2';
import { AptCommodityType } from '../../../enums/apttus/apt-commodity-typeof-sale';
import { AptProductType } from '../../../enums/apttus/apt-product-type';
import {
    containsExtraCommodities,
    containsProductInsurance,
    containsProductMaintenance,
    containsProductSmartHome,
} from '../../../functions/verifications.functions';
import { KeyValue } from '../../../models/apttus/request-response/cart-to-quote-request';
import { AttributeValues } from './attribute-factory-specializations/base-attribute-values';
import { GasAttributeValues } from './attribute-factory-specializations/gas-attribute-values';
import { InsuranceAttributeValues } from './attribute-factory-specializations/insurance-attribute-values';
import { MaintenanceAttributeValues } from './attribute-factory-specializations/maintenance-attribute-values';
import { PowerAttributeValues } from './attribute-factory-specializations/power-attribute-values';
import { SmartHomeAttributeValues } from './attribute-factory-specializations/smarthome-attribute-values';

export interface ProductAttributeValues {
    keyValue(): KeyValue[];
}

export abstract class AttributeFactory {
    static getAttributes(state: FullState, currentProduct: Product): ProductAttributeValues {
        try {
            if (!containsExtraCommodities([currentProduct])) {
                if (isGas(currentProduct)) {
                    return new GasAttributeValues(state, currentProduct);
                }
                if (isPower(currentProduct)) {
                    return new PowerAttributeValues(state, currentProduct);
                }
            } else {
                if (containsProductInsurance([currentProduct.productType])) {
                    return new InsuranceAttributeValues(state, currentProduct);
                }
                if (containsProductSmartHome([currentProduct.productType])) {
                    return new SmartHomeAttributeValues(state, currentProduct);
                }
                if (containsProductMaintenance([currentProduct.productType])) {
                    return new MaintenanceAttributeValues(state, currentProduct);
                }
            }
            return new AttributeValues(state, currentProduct);
        } catch (error) {
            console.error('AttributeFactory - Error generating attribute keyValue', error);
            throw new Error('Error generating attributes keyValue');
        }
    }
}

const isGas = (currentProduct: Product) =>
    currentProduct?.powerOrGas === AptCommodityType.Gas ||
    [AptProductType.FornituraGas, AptProductType.ScontoStandAloneGas].includes(currentProduct?.productType);

const isPower = (currentProduct: Product) =>
    currentProduct?.powerOrGas === AptCommodityType.Power ||
    [AptProductType.FornituraLuce, AptProductType.ScontoStandAloneLuce].includes(currentProduct?.productType);
