import { AField, AObject } from '@congacommerce/core';
import { AptPartnerships } from '../../../../../modules/common/partnership/enums/apt-partnerships';
import { AptBillType } from '../../../../enums/apttus/apt-bill-type';
import { AptCommodityType } from '../../../../enums/apttus/apt-commodity-typeof-sale';
import { AptPaymentInstrument } from '../../../../enums/apttus/apt-payment-instrument';
import { AptPaymentType } from '../../../../enums/apttus/apt-payment-type';

export class EglBasePav extends AObject {
    constructor() {
        super();
    }

    @AField({ soql: 'egl_typeofusage__c' })
    egl_typeofusage__c: string;

    @AField({ soql: 'egl_power_typesofusage__c' })
    egl_power_typesofusage__c: string;

    @AField({ soql: 'egl_Required_Power__c' })
    egl_Required_Power__c: string;

    @AField({ soql: 'egl_Available_Power__c' })
    egl_Available_Power__c: string;

    @AField({ soql: 'egl_num_of_phases_available__c' })
    egl_num_of_phases_available__c: string;

    @AField({ soql: 'egl_engaged_power_required__c' })
    egl_engaged_power_required__c: string;

    @AField({ soql: 'egl_voltage_required__c' })
    egl_voltage_required__c: string;

    @AField({ soql: 'egl_available_power_required__c' })
    egl_available_power_required__c: string;

    @AField({ soql: 'egl_num_of_phases_required__c' })
    egl_num_of_phases_required__c: string;

    @AField({ soql: 'egl_Commodity_Sector__c' })
    egl_Commodity_Sector__c: string;

    @AField({ soql: 'egl_Customer_ATECO_Code__c' })
    egl_Customer_ATECO_Code__c: string;

    @AField({ soql: 'egl_POD__c' })
    egl_POD__c: string;

    @AField({ soql: 'egl_PDR__c' })
    egl_PDR__c: string;

    @AField({ soql: 'egl_Address__c' })
    egl_Address__c: string;

    @AField({ soql: 'egl_Civic_Number__c' })
    egl_Civic_Number__c: string;

    @AField({ soql: 'egl_City__c' })
    egl_City__c: string;

    @AField({ soql: 'egl_Province__c' })
    egl_Province__c: string;

    @AField({ soql: 'egl_Region__c' })
    egl_Region__c: string;

    @AField({ soql: 'egl_Postal_Code__c' })
    egl_Postal_Code__c: string;

    @AField({ soql: 'egl_gas_consumption__c' })
    egl_gas_consumption__c: number;

    @AField({ soql: 'egl_product_address1_prefix__c' })
    egl_product_address1_prefix__c: string;

    @AField({ soql: 'egl_power_consumption__c' })
    egl_power_consumption__c: number;

    @AField({ soql: 'egl_Invoice_Shipping_Method__c' }) // modalita spedizione
    egl_Invoice_Shipping_Method__c: AptBillType = null;

    @AField({ soql: 'egl_Payment_Instrument__c' })
    egl_Payment_Instrument__c: AptPaymentInstrument = null;

    @AField({ soql: 'egl_Payment_Type__c' })
    egl_Payment_Type__c: AptPaymentType;

    @AField({ soql: 'egl_Hours_Bundle__c' }) // bioraria
    egl_Hours_Bundle__c: string;

    @AField({ soql: 'egl_green_option__c' })
    egl_green_option__c: string;

    @AField({ soql: 'egl_commodity_supplytype__c' })
    egl_commodity_supplytype__c: AptCommodityType;

    @AField({ soql: 'egl_commercial_branch__c' }) // TIPO ABITAZIONE
    egl_commercial_branch__c: string;

    @AField({ soql: 'egl_isresidential__c' }) // residenzial
    egl_isresidential__c: boolean;

    @AField({ soql: 'egl_winback__c' })
    egl_winback__c: 'Sì' | 'No';

    @AField({ soql: 'egl_commodity_distributor__c' }) // Codice distributore
    egl_commodity_distributor__c: string;

    @AField({ soql: 'egl_partnership__c' }) // Partnership
    egl_partnership__c: AptPartnerships;

    @AField({ soql: 'egl_serial_number_corrector__c' }) // matricola correttore
    egl_serial_number_corrector__c?: string;

    @AField({ soql: 'egl_digits_meter__c' }) // matricola misuratore
    egl_digits_meter__c?: number;

    @AField({ soql: 'egl_meter_class__c' }) // classe Misuratore
    egl_meter_class__c?: string;

    @AField({ soql: 'egl_meter_integrated__c' }) // presenza correttore
    egl_meter_integrated__c?: boolean;

    @AField({ soql: 'egl_Engaged_Power__c' }) // potenza
    egl_Engaged_Power__c?: number;

    @AField({ soql: 'egl_voltage__c' }) // livello di tensione
    egl_voltage__c?: string;

    @AField({ soql: 'egl_citygate__c' }) // codice Remi
    egl_citygate__c?: string;

    @AField({ soql: 'egl_pdr_serial_number__c' })
    egl_pdr_serial_number__c?: string;

    @AField({ soql: 'egl_digits_corrective__c' })
    egl_digits_corrective__c?: number;
}
