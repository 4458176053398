import { Action, createReducer, on } from '@ngrx/store';
import { UserState } from '../models/user-state';
import * as ua from '../actions/user.actions';
import { d365AccountContactDataToCustomerContact } from '../../common/functions/transformation.functions';

export const initialState: UserState = {
    contact: null,
    lead: null,
    agentInfo: null,
    customerInfo: null,
    fromCrm: null,
    cartSegment: null,
    customerMastership: null,
};

const reducer = createReducer(
    initialState,
    on(ua.setUserState, (state, { s }) => ({
        ...s,
        agentInfo: state.agentInfo,
    })),
    on(ua.setContact, (state, { c, fcrm }) => ({
        ...state,
        contact: c,
        fromCrm: fcrm ? fcrm : state.fromCrm,
    })),
    on(ua.setContactEmail, (state, { email }) => ({
        ...state,
        contact: {
            ...state.contact,
            emailaddress1: email,
        },
    })),
    on(ua.setLead, (state, { l, fcrm }) => ({
        ...state,
        lead: l,
        fromCrm: fcrm ? fcrm : state.fromCrm,
    })),
    on(ua.setAgentInfo, (state, { a }) => ({
        ...state,
        agentInfo: a,
    })),
    on(ua.setCustomerInfo, (state, { c }) => ({
        ...state,
        customerInfo: c,
    })),
    on(ua.resetUserState, (state) => ({
        ...state,
        contact: null,
        lead: null,
        fromCrm: false,
        customerInfo: null,
        customerMastership: undefined,
    })),

    on(ua.setLastUsedCustomerSegment, (state, { s }) => {
        state.agentInfo.UserConfiguration.LastUsedCustomerSegment = s;
        return { ...state };
    }),
    on(ua.setCartSegment, (state, { payload }) => ({
        ...state,
        cartSegment: payload,
    })),
    on(ua.setVirtualAgentAgencyCurrentBranch, (state, { payload }) => {
        state.agentInfo.VirtualAgents.forEach((va) => {
            if (va.IsCurrentSelected) {
                va.CurrentAgencyBranch = payload;
            }
        });
        return { ...state };
    }),
    on(ua.setCustomerMastership, (state, { customerMastership }) => ({
        ...state,
        customerMastership,
    })),
    on(ua.setCustomerAndContact, (state, { customer }) => ({
        ...state,
        ...d365AccountContactDataToCustomerContact(customer),
    }))
);

export function userReducer(state: UserState | undefined, action: Action): UserState {
    return reducer(state, action);
}
