export class BaseApiResponse<T = void> {
    result: string;
    status: StatusResponse;
    errorManagement?: ErrorManagement;
    response?: T;
}

export enum StatusResponse {
    Success = 'Success',
    Failed = 'Failed',
}

export interface ErrorManagement {
    errorCode: string;
    errorDescription: string;
}

export enum StatusDDLResponse {
    Success = 'OK',
    Failed = 'KO',
}
